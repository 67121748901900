import React from 'react'
import { Route, Routes } from 'react-router-dom'
import uuid4 from 'uuid/dist/v4'
import { PATHS } from '../../const'
import {
  tablet,
  rated,
  purse,
  creditScore,
  documentIcon,
  group,
  connection,
  caseIcon
} from '../../images'
import {
  PrincipalPage,
  PrincipalDocumentsPage,
  PrincipalLimitsPage,
  PrincipalRatingPage,
  PrincipalFinreportingPage,
  PrincipalPortfolioPage,
  PrincipalCoownersPage,
  PrincipalRelationshipPage,
} from '../../pages'

const orderListHead = [
  {
    id: uuid4(),
    cells: [
      {
        id: uuid4(),
        data: 'Номер',
        sortName: 'id',
      },
      {
        id: uuid4(),
        data: 'Принципал',
        sortName: 'principal',
      },
      {
        id: uuid4(),
        data: 'Сумма гарантии',
        sortName: 'sum',
      },
      {
        id: uuid4(),
        data: 'Вид',
        sortName: 'contest__type',
      },
      {
        id: uuid4(),
        data: 'Статус',
        sortName: 'state',
      },
      {
        id: uuid4(),
        data: 'UW',
        sortName: 'underwriter',
      },
      {
        id: uuid4(),
        data: 'Дата',
        sortName: 'start_date',
      },
    ]
  }
]

const asideLinks = [
  {
    id: uuid4(),
    path: PATHS.PRINCIPAL_INFO.link,
    icon: tablet,
    name: 'Общая информация',
  },
  {
    id: uuid4(),
    path: PATHS.PRINCIPAL_COOWNERS.link,
    icon: group,
    name: 'Совладельцы',
  },
  {
    id: uuid4(),
    path: PATHS.PRINCIPAL_FINREPORTING.link,
    icon: purse,
    name: 'Финансы',
  },
  {
    id: uuid4(),
    path: PATHS.PRINCIPAL_RATING.link,
    icon: rated,
    name: 'Рейтинг',
  },
  {
    id: uuid4(),
    path: PATHS.PRINCIPAL_LIMITS.link,
    icon: creditScore,
    name: 'Лимиты',
  },
  {
    id: uuid4(),
    path: PATHS.PRINCIPAL_RELATIONSHIP.link,
    icon: connection,
    name: 'Связи',
  },
  {
    id: uuid4(),
    path: PATHS.PRINCIPAL_DOCUMENTS.link,
    icon: documentIcon,
    name: 'Документы',
  },
  {
    id: uuid4(),
    path: PATHS.PRINCIPAL_PORTFOLIO.link,
    icon: caseIcon,
    name: 'Портфель',
  },
]

const Principal = () => <PrincipalPage asideLinks={asideLinks} paramsName='principalId' />
const PrincipalFinreporting = () => <PrincipalFinreportingPage asideLinks={asideLinks} paramsName='principalId' />
const PrincipalDocuments = () => <PrincipalDocumentsPage asideLinks={asideLinks} paramsName='principalId' />
const PrincipalLimits = () => <PrincipalLimitsPage asideLinks={asideLinks} paramsName='principalId' />
const PrincipalRating = () => <PrincipalRatingPage asideLinks={asideLinks} paramsName='principalId' />
const PrincipalPortfolio = () => <PrincipalPortfolioPage orderListHead={orderListHead} asideLinks={asideLinks} paramsName='principalId' />
const PrincipalCoowners = () => <PrincipalCoownersPage asideLinks={asideLinks} paramsName='principalId' />
const PrincipalRelationship = () => <PrincipalRelationshipPage asideLinks={asideLinks} paramsName='principalId' />

const PrincipalRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.PRINCIPAL_FINREPORTING.pathWithParam} element={<PrincipalFinreporting />} />
      <Route path={PATHS.PRINCIPAL_DOCUMENTS.pathWithParam} element={<PrincipalDocuments />} />
      <Route path={PATHS.PRINCIPAL_LIMITS.pathWithParam} element={<PrincipalLimits />} />
      <Route path={PATHS.PRINCIPAL_RATING.pathWithParam} element={<PrincipalRating />} />
      <Route path={PATHS.PRINCIPAL_PORTFOLIO.pathWithParam} element={<PrincipalPortfolio />} />
      <Route path={PATHS.PRINCIPAL_COOWNERS.pathWithParam} element={<PrincipalCoowners />} />
      <Route path={PATHS.PRINCIPAL_RELATIONSHIP.pathWithParam} element={<PrincipalRelationship />} />
      <Route path={PATHS.PRINCIPAL_INFO.pathWithParam} element={<Principal />} />
    </Routes>
  )
}

export default PrincipalRoutes
