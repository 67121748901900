import { formatNumberToSend, numberWithSpaces } from '../../../utils'

export const serializeUnderwritersList = (data) => {
  return data.map(item => ({
    ...item,
    authority: numberWithSpaces(item.authority, true)
  }))
}

export const formatUnderwriterToSend = (data) => {
  return {
    authority: formatNumberToSend(data.authority).replace(',', '.')
  }
}
