import React from 'react';
import classNames from 'classnames';
import Title from '../Title';
import Progressbar from '../OrderViews/Progressbar';
import style from './styles/orderStatus.module.scss';
import Modal from '../ModalCreator';

const OrderStatus = ({
  data,
  className,
  isOpen,
  ...props
}) => {
  return (
    <Modal isOpen={isOpen}>
      <div className={classNames({
        [style.container]: true,
        [className]: !!className
      })} {...props}>
        <Title
          level='2'
          projectVariant='title_section'
        >
          Заявка №{data.title}
        </Title>
        <Progressbar
          status={data.status}
          isDisplay
        />
      </div>
    </Modal>
  )
}

export default OrderStatus;