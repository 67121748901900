/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import Title from '../../views/Title';
import UnderwritersList from '../../views/UnderwritersList';
import { slidersHorizontal } from '../../images';
import ButtonIcon from '../../views/ButtonIcon';
import { handleRequestError } from '../../utils';
import Preloader from '../../views/Preloader'
import style from './index.module.scss';

const Underwriters = ({ toggleModal }) => {
  const { getUnderwritersList, updateUser } = api.underwritersApi;
  const [underwriterData, setUnderwriterData] = useState([]);
  const [isFetching, setIsFetching] = useState(false)
  const navigate = useNavigate()

  const reloadList = () => {
    setIsFetching(true)
    return getUnderwritersList()
      .then(data => {
        const modifiedData = data.map(item => ({
          ...item,
          inputAttr: {
            name: 'authority',
            handleAccept: (name, value) => handleAccept(item.id, name, value)
          }
        }))
        setUnderwriterData(modifiedData)
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }

  const handleAccept = (id, name, value) => {
    const data = { [name]: value }
    const handleUpdateUser = () => updateUser(id, data)
    return toast.promise(handleUpdateUser, {
      pending: 'Обновление...',
      success: 'Данные андеррайтера успешно обновлены'
    })
      .then(reloadList)
      .catch(err => handleRequestError(err, navigate))
  }

  useEffect(() => {
    reloadList()
  }, [])

  return (
    <section>
      <div className={style.section_header}>
        <Title level='2' projectVariant='title_section'>Андеррайтеры</Title>
        <ButtonIcon
          iconSrc={slidersHorizontal}
          ariaLabel='Открыть модальное окно фильтрации'
          onClick={toggleModal}
        />
      </div>
      {isFetching ?
        <Preloader />
        :
        <UnderwritersList data={underwriterData} />
      }
    </section>
  )
}

export default Underwriters;
