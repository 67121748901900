import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../api';
import Preloader from '../../views/Preloader';
import { handleRequestError } from '../../utils';
import PrincipalViews from '../../views/PrincipalViews';
import Title from '../../views/Title';

const PrincipalRelationship = () => {
  const [principal, setPrincipal] = useState({});
  const [isFetching, setIsFetching] = useState(false)
  const { principalId } = useParams();
  const { getPrincipalById } = api.entityApi;
  const navigate = useNavigate();

  useEffect(() => {
    setIsFetching(true)
    getPrincipalById(principalId)
      .then(dataPrincipal => {
        setPrincipal(dataPrincipal);
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  return (
    <>
      <Title level='1'>Связи</Title>
      <PrincipalViews.Header principal={principal} />
      {isFetching ?
        <Preloader />
        :
        <PrincipalViews.Relationship />
      }
    </>
  )
}

export default PrincipalRelationship;