import React from 'react';
import Layout from '../views';
import PrincipalLimits from '../components/PrincipalLimits';

const PrincipalLimitsPage = (props) => {
  return (
    <Layout {...props}>
      <PrincipalLimits />
    </Layout>
  )
}

export default PrincipalLimitsPage;