// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".orderStatus_container___agAY {\n  width: 100vw;\n  max-width: 1300px;\n  padding: 0 25px;\n}", "",{"version":3,"sources":["webpack://src/views/ModalContent/styles/orderStatus.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,iBAAA;EACA,eAAA;AADF","sourcesContent":["@import \"../../../styles/global/index.scss\";\n\n.container {\n  width: 100vw;\n  max-width: 1300px;\n  padding: 0 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "orderStatus_container___agAY"
};
export default ___CSS_LOADER_EXPORT___;
