import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import Button from '../Button';
import style from './styles/index.module.scss';

// const mock = {
//   listName: 'Образец списка',
//   list: [
//     {
//       link: '/',
//       name: 'Главная'
//     },
//     {
//       link: '/underwriters',
//       name: 'Андерайтеры'
//     },
//     {
//       link: '/banks',
//       name: 'Банки'
//     }
//   ]
// }

const Dropdown = ({
  data,
  className,
  ...props
}) => {
  const [isOpened, setIsOpened] = useState(false);

  const handleToggle = () => {
    setIsOpened(!isOpened);
  }

  return (
    <div
      className={className}
      {...props}
    >
      <Button
        projectVariant='button_wide'
        spoiler
        spoilerState={isOpened}
        full
        onClick={handleToggle}
      >
        {data.listName}
      </Button>
      <ul className={classNames(
        style.listBlock,
        { [style.opened]: isOpened }
      )}>
        {data.list.map(item => (
          <li>
            <NavLink to={item.link}>{item.name}</NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Dropdown;