import React from 'react';
import Title from '../../Title/index';
import OrdersTable from '../../OrdersTable/index';
import ButtonIcon from '../../ButtonIcon';
import { slidersHorizontal } from '../../../images';
import Preloader from '../../Preloader'
import styles from './styles/index.module.scss';

const List = ({
  title = 'Мои заявки',
  tableHead,
  tableBody,
  sortDirection,
  fieldToSort,
  toggleModal,
  isFetching = false,
  children,
}) => {
  return (
    <>
      <div className={styles.hgroup}>
        <Title
          className={styles.hgroup__title}
          level='1'
          projectVariant='title_section'
        >
          {title}
        </Title>
        <ButtonIcon
          className={styles.hgroup__btnFilter}
          iconSrc={slidersHorizontal}
          ariaLabel='Открыть модальное окно фильтрации'
          onClick={toggleModal}
        />
      </div>
      <section className={styles.orderList}>
        {isFetching ?
          <Preloader />
          :
          <OrdersTable
            tableHead={tableHead}
            tableBody={tableBody}
            sortDirection={sortDirection}
            fieldToSort={fieldToSort}
          />
        }
      </section>
      {children}
    </>
  )
}

export default List;
