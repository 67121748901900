import React from 'react';
import Button from '../Button';
import Modal from '../ModalCreator';
import Title from '../Title';
import style from './styles/documentChecking.module.scss';

const DocumentChecking = ({
  isOpen,
  handleDelete,
  handleClose,
}) => {
  return (
    <Modal
      open={isOpen}
      projectVariant='modal_without-cross'
    >
      <div className={style.modal}>
        <Title
          className={style.title}
          level='2'
          projectVariant='title_section'
        >
          Удаление документа
        </Title>
        <span className={style.descr}>Вы уверены, что хотите удалить данный документ?</span>
        <div className={style.controls}>
          <Button
            className={style.button}
            onClick={handleClose}
            full
          >
            Нет
          </Button>
          <Button
            className={style.button}
            onClick={handleDelete}
            full
          >
            Да
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DocumentChecking