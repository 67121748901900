import React from 'react';
import uuid4 from 'uuid/dist/v4';
import classNames from 'classnames';
import Dropdown from '../../Dropdown';
import style from './styles/index.module.scss';

// const mock = [
//   {
//     listName: 'Образец списка',
//     list: [
//       {
//         link: '/',
//         name: 'Главная'
//       },
//       {
//         link: '/underwriters',
//         name: 'Андеррайтеры'
//       },
//       {
//         link: '/banks',
//         name: 'Банки'
//       }
//     ]
//   },
//   {
//     listName: 'Образец списка 2',
//     list: [
//       {
//         link: '/limits',
//         name: 'Лимиты'
//       },
//       {
//         link: '/portfolio',
//         name: 'Портфель'
//       },
//       {
//         link: '/handbooks',
//         name: 'Справочники'
//       }
//     ]
//   }
// ]

const AsideLinks = ({
  data,
  className,
  ...props
}) => {
  return (
    <aside
      className={classNames(
        style.container,
        { [className]: !!className }
      )}
      {...props}
    >
      <ul className={style.list}>
        {data.map(item => (
          <li
            className={style.listBlock}
            key={uuid4()}
          >
            <Dropdown
              className={style.listBlock__dropdown}
              data={item}
            />
          </li>
        ))}
      </ul>
    </aside>
  )
}

export default AsideLinks;