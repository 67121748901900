import AbstractBaseApi from '../AbstractBaseApi';
import { serializeUnderwritersList, formatUnderwriterToSend } from './serializers'

export default class UnderwritersApi extends AbstractBaseApi {
  getUnderwritersList = async () => {
    const res = await this.get('user/user/')
    return serializeUnderwritersList(res.data)
  }

  updateUser = async (id, params) => {
    const formattedData = formatUnderwriterToSend(params)
    const res = await this.patch(`user/user/${id}/`, formattedData)
    return res.data
  }
}
