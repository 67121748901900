// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_title__cgZ-O {\n  margin-bottom: 30px;\n}\n\n.styles_list__WKWeY {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-gap: 20px;\n}\n\n.styles_sf__1wXfK {\n  grid-auto-rows: minmax(140px, auto);\n}\n\n.styles_ps__1uS_Y {\n  grid-auto-rows: minmax(82px, auto);\n}\n\n@media (max-width: 1100px) {\n  .styles_list__WKWeY {\n    grid-template-columns: 1fr;\n    grid-auto-rows: auto;\n  }\n}", "",{"version":3,"sources":["webpack://src/views/HandbookViews/SFPSList/styles/index.module.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF;;AAIA;EACE,aAAA;EACA,qCAAA;EACA,cAAA;AADF;;AAIA;EACE,mCAAA;AADF;;AAIA;EACE,kCAAA;AADF;;AAIA;EACE;IACE,0BAAA;IACA,oBAAA;EADF;AACF","sourcesContent":["@import \"../../../../styles/global/index.scss\";\n\n.title {\n  margin-bottom: 30px;\n}\n\n.list {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-gap: 20px;\n}\n\n.sf {\n  grid-auto-rows: minmax(140px, auto);\n}\n\n.ps {\n  grid-auto-rows: minmax(82px, auto);\n}\n\n@media (max-width: 1100px) {\n  .list {\n    grid-template-columns: 1fr;\n    grid-auto-rows: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "styles_title__cgZ-O",
	"list": "styles_list__WKWeY",
	"sf": "styles_sf__1wXfK",
	"ps": "styles_ps__1uS_Y"
};
export default ___CSS_LOADER_EXPORT___;
