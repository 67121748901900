import Layout from './Layout';
import AsideLinks from './AsideLinks';
import AsideChat from './AsideChat';
import TextSection from './TextSection';
import FullHeightPageWrapper from './FullHeightPageWrapper';
import Chat from './Chat';
import Profile from './Profile';
import RequestStatus from './RequestStatus';

export default {
  Layout,
  AsideLinks,
  AsideChat,
  TextSection,
  FullHeightPageWrapper,
  Chat,
  Profile,
  RequestStatus
}