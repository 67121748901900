import React from 'react'
import classNames from 'classnames';
import SFPSCard from '../SFPSCard';
import Title from '../../Title';
import style from './styles/index.module.scss'

const SFPSList = ({
  data,
  contentType,
  patchRequest,
  setReadOnly = true,
  className,
  ...props
}) => {
  const getContentType = () => {
    switch (contentType) {
      case 'sf':
        return { title: 'Стоп-факторы', list: style.sf };
      case 'ps':
        return { title: 'Предупреждающие сигналы', list: style.ps };
      default:
        return { title: 'Стоп-факторы', list: style.sf };
    }
  }

  return (
    <section
      className={className}
      {...props}
    >
      <Title
        className={style.title}
        level='2'
        projectVariant='title_section'
      >
        {getContentType().title}
      </Title>
      <ul className={classNames({
        [style.list]: true,
        [getContentType().list]: !!getContentType().list
      })}>
        {data?.map((card) => (
          <li key={card.id}>
            <SFPSCard
              cardId={card.id}
              isChecked={card.state}
              patchRequest={patchRequest}
              setReadOnly={setReadOnly}
            >
              {card.descr}
            </SFPSCard>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default SFPSList;
