import uuid4 from 'uuid/dist/v4'

const principalLimitTitles = [
  'Установленный лимит',
  'Действующие БГ',
  'Заявки',
  'Свободный остаток',
]

const principalLimitTitlesMap = {
  'Установленный лимит': 'total_limit',
  'Действующие БГ': 'current_guaranties_sum',
  'Заявки': 'orders_sum',
  'Свободный остаток': 'free_balance',
}

const convertPrincipalLimitToTable = (data) => {
  return principalLimitTitles.map(item => ({
    id: uuid4(),
    cells: [
      {
        id: uuid4(),
        data: item
      },
      {
        id: uuid4(),
        data: data ? data[principalLimitTitlesMap[item]] : '-'
      },
    ]
  }))
}

export default { convertPrincipalLimitToTable }