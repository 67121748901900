import React, { useState } from 'react';
import classNames from 'classnames';
import {
  NavLink,
  useNavigate,
  useParams
} from 'react-router-dom';
import { GxIcon } from '@garpix/garpix-web-components-react';
import Button from '../Button';
import arrowBack from '../../images/icons/arrow_back.svg';
import style from './styles/index.module.scss';

const Aside = ({
  className,
  paramsName = 'orderId',
  links,
  ...props
}) => {
  const [expand, setExpand] = useState(false);
  const [showName, setShowName] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const navigate = useNavigate();
  const params = useParams();
  const goBackHandle = () => navigate(-1);

  const linkClassName = ({ isActive }) => {
    if (isActive) return `${style.asideNavList__link} ${style.asideNavList__link_active}`
    return style.asideNavList__link
  }

  /* eslint-disable jsx-a11y/mouse-events-have-key-events */
  const handleExpandAside = evt => {
    if (evt.type === 'mouseenter') {
      setExpand(true);
      setTimerId(setTimeout(() => setShowName(true), 150));
    } else {
      setExpand(false);
      clearTimeout(timerId);
      setShowName(false);
    }
  }

  return (
    <aside
      className={classNames(
        'aside',
        [style.aside],
        {
          [style.expanded]: expand,
          [className]: !!className
        }
      )}
      onMouseEnter={handleExpandAside}
      onMouseLeave={handleExpandAside}
      {...props}
    >
      {links.length ?
        <div className={style.aside__wrapper}>
          <Button
            className={style.aside__return}
            projectVariant='button_special'
            onClick={goBackHandle}
          >
            <GxIcon
              className={style.aside__returnIcon}
              src={arrowBack}
              slot='icon-left'
            />
            <span className={classNames({ [style.expanded]: showName })}>Назад</span>
          </Button>
          <nav
            className={style.asideNav}
            role='navigation'
            aria-label='Дополнительная навигация'
          >
            <ul className={style.asideNavList}>
              {
                links.map(link => (
                  <li key={link.id}>
                    <NavLink
                      className={linkClassName}
                      to={link.path + (params[paramsName] || '')}
                      end={link.exact}
                    >
                      <GxIcon
                        className={style.asideNavList__icon}
                        src={link.icon}
                      />
                      <span className={classNames({
                        [style.asideNavList__name]: true,
                        [style.expanded]: showName
                      })}>{link.name || null}</span>
                    </NavLink>
                  </li>
                ))
              }
            </ul>
          </nav>
        </div>
        : null
      }
    </aside >
  );
}

export default Aside;
