import React from 'react';
import Layout from '../views';
import BankMatrix from '../components/BankMatrix';

const BankMatrixPage = ({ ...props }) => {
  return (
    <Layout {...props}>
      <BankMatrix />
    </Layout>
  )
}

export default BankMatrixPage;