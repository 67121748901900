import React, { useState } from 'react';
import Icon from '../Icon';
import InputAccept from '../InputAccept';
import style from './styles/UnderwritersCard.module.scss';

const UnderwritersCard = ({
  data,
  className,
  ...props
}) => {
  const [openTable, setOpenTable] = useState(false);

  const toggleOpenTable = () => {
    setOpenTable(!openTable)
  }
  const clearTel = (tel) => {
    return tel.replace(/[^+\d]/, '');
  }

  return (
    <div
      className={className}
      key={data.id}
      {...props}
    >
      <div
        className={style.upper}
        role='button'
        aria-label='toggle-collapse'
        aria-expanded='false'
        tabIndex={0}
        onKeyDown={toggleOpenTable}
        onClick={toggleOpenTable}
      >
        <img className={style.avatar} src={data.logo} alt='UnderwriterImage' />
        <p className={style.name}>
          {`${data.last_name} ${data.first_name} ${data.patronymic}`}
        </p>
        <div className={style.email}>
          <span className={style.key}>e-mail</span>
          <a href={`mailto:${data.email || null}`} className={style.value}>{data.email || '-'}</a>
        </div>
        <div className={style.number}>
          <span className={style.key}>телефон</span>
          <a href={`tel:${clearTel(data.phone) || null}`} className={style.value}>{data.phone || '-'}</a>
        </div>
        <Icon variant={openTable ? 'rotate' : null} icon='arrow_down' />
      </div>
      {openTable ?
        <div className={style.add}>
          <div />
          <div />
          <div>
            <div className={style.authority}>
              <span className={style.key}>Полномочия</span>
              <InputAccept defaultValue={data.authority} {...data.inputAttr} />
            </div>
          </div>
        </div>
        : null}
    </div>
  )
}

export default UnderwritersCard;