import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useStoreon } from 'storeon/react';

const useModal = () => {
  const { dispatch } = useStoreon();
  const location = useLocation()
  const mounted = useRef(null)

  const openModal = (type, props = {}) => {
    dispatch('modal/toggle', { currentModal: type, props });
  };

  const closeModal = () => {
    dispatch('modal/toggle', { currentModal: null });
  };

  const updateProps = (props) => {
    dispatch('modal/update', { props });
  }

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      return
    }
    closeModal()
  }, [location.pathname])

  return { openModal, closeModal, updateProps };
};

export default useModal;
