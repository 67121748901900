import React from 'react';
import { GxInput } from '@garpix/garpix-web-components-react';
import classNames from 'classnames';
import style from './styles/index.module.scss';


const Input = ({
  projectVariant = 'input_default',
  textAlign = 'left',
  className,
  children,
  helpText,
  ...props
}) => {
  return (
    <GxInput
      projectVariant={projectVariant}
      className={classNames(
        style[projectVariant],
        style[textAlign],
        { [className]: !!className }
      )}
      {...props}
    >
      {helpText}
      {children}
    </GxInput>
  );
}

export default Input;
