import React from 'react';
import classNames from 'classnames';
import style from './styles/index.module.scss';

const FullHeightPageWrapper = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        style.container,
        { [className]: !!className }
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export default FullHeightPageWrapper;