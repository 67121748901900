import React from 'react';
import Layout from '../views';
import HandbookStopFactor from '../components/HandbookStopFactor';

const HandbookStopFactorsPage = (props) => {
  return (
    <Layout {...props}>
      <HandbookStopFactor />
    </Layout>
  )
}

export default HandbookStopFactorsPage;