import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../api';
import OrderViews from '../../views/OrderViews';
import Title from '../../views/Title';
import IndicatingCard from '../../views/IndicatingCard';
import style from './index.module.scss';
import { FACTORS_TYPES } from '../../const';
import { handleRequestError } from '../../utils';
import Preloader from '../../views/Preloader';

const OrderStopFactors = () => {
  const [order, setOrder] = useState({});
  const [stops, setStops] = useState([])
  const [forced, setForced] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  const { orderId } = useParams();
  const { getOrderById, getOrderFactors } = api.ordersApi
  const navigate = useNavigate()

  const showAllStops = () => {
    return getOrderFactors({ factors__catalog: FACTORS_TYPES.STOP, order: orderId, page_size: 100 })
      .then(data => setStops(data.results))
      .catch(err => handleRequestError(err, navigate))
  }
  const showAllForced = () => {
    return getOrderFactors({ factors__catalog: FACTORS_TYPES.FORCED, order: orderId, page_size: 100 })
      .then(data => setForced(data.results))
      .catch(err => handleRequestError(err, navigate))
  }

  useEffect(() => {
    setIsFetching(true)
    Promise.all([
      getOrderById(orderId),
      getOrderFactors({ factors__catalog: FACTORS_TYPES.STOP, order: orderId }),
      getOrderFactors({ factors__catalog: FACTORS_TYPES.FORCED, order: orderId }),
    ])
      .then(([orderData, stopsData, forcedData]) => {
        setOrder(orderData)
        setStops(stopsData.results)
        setForced(forcedData.results)
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  return (
    <>
      <OrderViews.Header
        orderId={order.id}
        sum={order.sum}
        principal={order.principal}
        fz={order.contest?.fz?.fz}
        status={order.state}
      />
      <Title level='1' projectVariant='title_page'>Страница стоп-факторов</Title>
      {isFetching ?
        <Preloader />
        :
        <>
          {!!stops.length && <section className={style.section}>
            <header className={style.header}>
              <Title level='2' projectVariant='title_section'>Стоп-факторы</Title>
              <button className={style.more} type='button' onClick={showAllStops}>Показать все</button>
            </header>
            <ul className={style.list}>
              {stops.map(stop => (
                <li>
                  <IndicatingCard
                    dataInputKey={stop.factors.title}
                    dataInputValue={stop.value ? 'Да' : 'Нет'}
                    color={stop.value ? 'red' : 'green'}
                    projectVariant='large'
                  />
                </li>
              ))}
            </ul>
          </section>}
          {!!forced.length && <section className={style.section}>
            <header className={style.header}>
              <Title level='2' projectVariant='title_section'>Предупреждающие сигналы</Title>
              <button className={style.more} type='button' onClick={showAllForced}>Показать все</button>
            </header>
            <ul className={style.list}>
              {forced.map(force => (
                <li>
                  <IndicatingCard
                    dataInputKey={force.factors.title}
                    dataInputValue={force.value ? 'Да' : 'Нет'}
                    color={force.value ? 'red' : 'green'}
                    projectVariant='large'
                  />
                </li>
              ))}
            </ul>
          </section>}
        </>
      }
    </>
  )
}

export default OrderStopFactors;