import React, { useEffect } from 'react'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useStoreon } from 'storeon/react'
import api from '../../api'
import LoginView from '../../views/Login'
import { loginSchema } from '../../utils/SchemasFormik'
import { PATHS } from '../../const'
import { handleRequestError } from '../../utils'

const Login = () => {
  const navigate = useNavigate()
  const { dispatch, currentUser } = useStoreon('currentUser')
  const { login, getUserById, getAuthToken } = api.usersApi
  const errorsMessenge = {
    shortPass: 'Минимальное количество символов - 8',
    longPass: 'Максимальное количество символов - 128',
    requiredField: 'Это поле обязательно',
    notValidEmail: 'Некорректно введен Email',
    'Невозможно войти с предоставленными учетными данными.': 'Неправильный логин или пароль',
  };

  const onSubmit = (data, { setFieldError }) => {
    return login(data)
      .then(({ id }) => getUserById(id))
      .then(userData => {
        dispatch('user/save', userData)
        navigate(PATHS.HOME.link)
      })
      .catch(err => {
        handleRequestError(err, navigate)
        if (err.response) {
          setFieldError('password', errorsMessenge[err.response.data.message] || 'Что-то пошло не так, попробуйте чуть позже.');
        }
      });
  }

  useEffect(() => {
    const token = getAuthToken()

    if (currentUser && token) {
      navigate(PATHS.HOME.link)
      return
    }
    dispatch('user/save', null)

  }, [currentUser])

  return (
    <Formik
      validationSchema={loginSchema(errorsMessenge)}
      initialValues={{ email: '', password: '' }}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur
    >
      {
        ({ handleSubmit, errors, handleBlur, handleChange, values, touched, isSubmitting }) => <LoginView
          handleSubmit={handleSubmit}
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
          isSubmitting={isSubmitting}
        />
      }
    </Formik>
  )
}

export default Login
