import React from 'react';
import classNames from 'classnames';
import style from './styles/index.module.scss';

const TextSection = ({
  fullHeight = false,
  className,
  ...props
}) => {
  return (
    <section
      className={classNames(
        style.container,
        {
          [className]: !!className,
          [style.fullHeight]: fullHeight
        }
      )}
      {...props}
    >
      <h1>Равным образом, повышение уровня гражданского сознания?</h1>
      <p>
        Вот вам яркий пример современных тенденций - понимание сути ресурсосберегающих технологий обеспечивает широкому кругу (специалистов) участие в формировании приоритизации разума над эмоциями. Есть над чем задуматься: действия представителей оппозиции могут быть смешаны с не уникальными данными до степени совершенной неузнаваемости, из-за чего возрастает их статус бесполезности.
      </p>
    </section>
  )
}

export default TextSection;