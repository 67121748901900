import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../api';
import { handleRequestError } from '../../utils';
import Title from '../../views/Title';
import PrincipalViews from '../../views/PrincipalViews';
import Preloader from '../../views/Preloader'
import {
  principalContactsSerializer,
  principalRequisitesSerializer
} from './utils';

const Principal = () => {
  const [principal, setPrincipal] = useState({});
  const [isInBlacklist, setIsInBlacklist] = useState('-');
  const [isFetching, setIsFetching] = useState(false)
  const [limit, setLimit] = useState({})
  const { principalId } = useParams();
  const { getPrincipalById } = api.entityApi;
  const { getHandbookBlackList } = api.handbooksApi;
  const { getRating } = api.limitsApi;

  const navigate = useNavigate();

  const rating = {
    letter_rating: limit?.letter,
    ball_rating: limit?.calculationRating,
  }

  useEffect(() => {
    setIsFetching(true)
    Promise.all([
      getPrincipalById(principalId),
      getRating({ principal: principalId })
    ])
      .then(([respPrincipal, limitData]) => {
        setPrincipal(respPrincipal);
        setLimit(limitData)

        return getHandbookBlackList({ inn: respPrincipal?.legal_entity?.inn })
      })
      .then(respHandbooks => {
        if (respHandbooks.length > 0) {
          setIsInBlacklist(true);
        } else {
          setIsInBlacklist(false);
        }
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  return (
    <>
      <Title
        level='1'
        projectVariant='title_page'
      >
        Главная страница Принципала
      </Title>
      <PrincipalViews.Header
        principal={principal}
      />
      {isFetching ?
        <Preloader />
        :
        <>
          <PrincipalViews.Indicators
            blacklist={isInBlacklist}
            rating={rating}
          />
          <PrincipalViews.Summery
            dataContacts={principalContactsSerializer(principal)}
            dataRequisites={principalRequisitesSerializer(principal)}
          />
          <PrincipalViews.ValidationControls />
        </>
      }
    </>
  )
}

export default Principal;