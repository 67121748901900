import { Formik } from 'formik';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import api from '../../api';
import { MS_BEFORE_REDIRECT, PATHS } from '../../const';
import { handleRequestError } from '../../utils';
import { restorePassSchema } from '../../utils/SchemasFormik';
import RecoverPasswordView from '../../views/RecoverPassword'

const RecoverPassword = () => {
  const [message, setMessage] = useState('')
  const { currentUser } = useStoreon('currentUser')
  const navigate = useNavigate()

  const errorsMessenge = {
    'no user': 'Некорректная ссылка для сброса пароля.',
  };

  const getResetLink = () => {
    /* eslint-disable-next-line no-undef */
    const { href, pathname, search } = window.location
    const link = process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_STAGE_URL + pathname + search
      : href

    return link
  }

  const initialValues = { password: '' }

  const onSubmit = (data, { setFieldError }) => {
    return api.usersApi.recoverPassword({ ...data, reset_link: getResetLink() })
      .then(res => {
        if (res.status) setMessage('Пароль успешно изменен. Вы будете перенаправлены на страницу входа')
        setTimeout(() => navigate(PATHS.AUTHORIZATION.path), MS_BEFORE_REDIRECT)
      })
      .catch((err) => {
        handleRequestError(err, navigate)
        if (err.response) {
          setFieldError('serverError', errorsMessenge[err.response.data.error]);
        }
      });
  }

  if (currentUser) navigate(PATHS.HOME.link)
  return (
    <Formik
      validationSchema={restorePassSchema()}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur
    >
      {
        ({ handleSubmit, errors, handleBlur, handleChange, values, touched, isSubmitting }) => <RecoverPasswordView
          handleSubmit={handleSubmit}
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
          message={message}
          isSubmitting={isSubmitting}
        />
      }
    </Formik>
  )

}

export default RecoverPassword
