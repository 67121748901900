import React from 'react';
import classNames from 'classnames';
import { GxIcon } from '@garpix/garpix-web-components-react';
import Title from '../../Title';
// import Progressbar from '../Progressbar';
import { getStatusColor, convertStatusName, numberWithSpaces } from '../../../utils';
import Button from '../../Button';
import { principalAlert } from '../../../images';
import style from './styles/index.module.scss';

const Header = ({
  orderId,
  sum,
  principal,
  fz,
  status,
  handleIntegrationCheck = null,
  bankGuaranteeNumber = null,
  className,
  ...props
}) => {
  // const [display, setDisplay] = useState(false);

  // const openHeader = (evt) => {
  //   if (evt.type === 'click' || (evt.type === 'keyup' && evt.keyCode === 13)) {
  //     if (display) {
  //       setDisplay(false);
  //     } else {
  //       setDisplay(true);
  //     }
  //   }
  // }
  const state = convertStatusName(status)

  return (
    <section
      className={classNames({
        [style.orderHeader]: true,
        [className]: !!className
      })}
      // role='button'
      // tabIndex='0'
      // onClick={openHeader}
      // onKeyUp={openHeader}
      {...props}
    >
      <div className={style.orderHeader__main}>
        <Title
          className={style.orderHeader__title}
          level='2'
          projectVariant='title_section'
        >
          <div className={style.orderHeader__titleInner}>
            <span>Заявка № {orderId}</span>
            {handleIntegrationCheck &&
              <Button
                className={style.orderHeader__integrationCheckBtn}
                onClick={handleIntegrationCheck}
                projectVariant='button_transparent'
              >
                <GxIcon
                  className={style.orderHeader__integrationCheckIcon}
                  src={principalAlert}
                  slot='icon-left'
                />
              </Button>
            }
          </div>
          {bankGuaranteeNumber &&
            <span>{bankGuaranteeNumber}</span>
          }
        </Title>
        <div className={style.orderHeaderColumn}>
          <span className={style.orderHeaderColumn__key}>Принципал</span>
          <span className={style.orderHeaderColumn__value}>{principal?.title || '-'}</span>
        </div>
        <div className={style.orderHeaderColumn}>
          <span className={style.orderHeaderColumn__key}>Сумма БГ</span>
          <span className={style.orderHeaderColumn__value}>{numberWithSpaces(sum, true) || '-'}</span>
        </div>
        <div className={style.orderHeaderColumn}>
          <span className={style.orderHeaderColumn__key}>Тип</span>
          <span className={style.orderHeaderColumn__value}>{fz || '-'}</span>
        </div>
        <span className={classNames([
          style.orderHeader__status,
          style[getStatusColor(state)]
        ])}>
          {state}
        </span>
      </div>
      {/* <Progressbar
        status={state}
        isDisplay={display}
      /> */}
    </section>
  )
}

export default Header;
