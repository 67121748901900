// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BankLimits_table__2sxpk th,\n.BankLimits_table__2sxpk td {\n  width: 30%;\n}\n.BankLimits_table__2sxpk td:first-child {\n  width: 40%;\n}", "",{"version":3,"sources":["webpack://src/components/BankLimits/index.module.scss"],"names":[],"mappings":"AAGE;;EAEE,UAAA;AAFJ;AAKE;EACE,UAAA;AAHJ","sourcesContent":["@import \"../../styles/global/index.scss\";\n\n.table {\n  th,\n  td {\n    width: 30%;\n  }\n\n  td:first-child {\n    width: 40%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "BankLimits_table__2sxpk"
};
export default ___CSS_LOADER_EXPORT___;
