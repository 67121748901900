/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import { useStoreon } from 'storeon/react'
import { toast } from 'react-toastify'
import api from './api'
import RootRoutes from './routes'
import { PATHS } from './const'
import { handleRequestError } from './utils'
import '@garpix/garpix-web-components/dist/garpix-web-components/garpix-web-components.css'
import './styles/index.scss'
import 'react-toastify/dist/ReactToastify.css';
import Modals from './views/ModalContent'
import Preloader from './views/Preloader'

toast.configure({
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: 2000,
})

function App() {
  const [isFetchingToken, setIsFetchingToken] = useState(true)
  const { dispatch, currentUser } = useStoreon('currentUser')
  const { getAuthToken, usersApi, getCurrentUserId } = api
  const navigate = useNavigate()
  const location = useLocation()

  const authPaths = [PATHS.AUTHORIZATION.path, PATHS.RECOVER_PASSWORD.path, PATHS.FORGOT_PASSWORD.path, PATHS.TERMS_OF_SERVICE.path]
  useEffect(() => {
    const token = getAuthToken()
    const userId = getCurrentUserId()

    if (!currentUser && token && userId) {
      usersApi.getUserById(userId)
        .then(data => dispatch('user/save', data))
        .catch(err => handleRequestError(err, navigate))
        .finally(() => setIsFetchingToken(false))
      return
    }
    if (!token && !authPaths.includes(location.pathname)) {
      setIsFetchingToken(false)
      dispatch('user/save', null)
      navigate(PATHS.AUTHORIZATION.path)
      return
    }

    setIsFetchingToken(false)
  }, [currentUser])

  if (isFetchingToken) return <Preloader />
  return (
    <div className='App'>
      <Routes>
        <Route path={PATHS.HOME.path} element={<RootRoutes />} />
      </Routes>
      <Modals />
    </div>
  )
}

export default App