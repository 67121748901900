import { formatNumberToSend } from '../../../utils'

export const formatTradeCalculation = (data) => {
  return {
    bank_rate: data?.bank_rate ? data.bank_rate : undefined,
    insurance_premium_rate: data?.insurance_premium_rate ? formatNumberToSend(data.insurance_premium_rate) : undefined,
    master_agent_commission: data?.master_agent_commission ? data.master_agent_commission : undefined,
    agent_commission: data?.agent_commission ? data.agent_commission : undefined,
  }
}

export const formatQuotatuon = () => { }