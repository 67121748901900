import React from 'react';
import Layout from '../views';
import OrderList from '../components/OrderList';

const OrderListPage = ({ orderListHead }) => {
  return (
    <Layout>
      <OrderList orderListHead={orderListHead} />
    </Layout>
  )
}

export default OrderListPage;
