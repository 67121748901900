import React from 'react';
import Title from '../../views/Title';
import MiscViews from '../../views/MiscViews';

const mock = [
  {
    listName: 'Образец списка',
    list: [
      {
        link: '/',
        name: 'Главная'
      },
      {
        link: '/underwriters',
        name: 'Андеррайтеры'
      },
      {
        link: '/banks',
        name: 'Банки'
      }
    ]
  },
  {
    listName: 'Образец списка 2',
    list: [
      {
        link: '/limits',
        name: 'Лимиты'
      },
      {
        link: '/portfolio',
        name: 'Портфель'
      },
      {
        link: '/handbooks',
        name: 'Справочники'
      }
    ]
  }
]

const FAQ = () => {
  return (
    <MiscViews.FullHeightPageWrapper>
      <Title
        level='1'
        projectVariant='title_section'
      >
        F.A.Q.
      </Title>
      <MiscViews.Layout>
        <MiscViews.AsideLinks data={mock} />
        <MiscViews.TextSection fullHeight />
      </MiscViews.Layout>
    </MiscViews.FullHeightPageWrapper>
  )
}

export default FAQ;