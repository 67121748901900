import React from 'react';
import classNames from 'classnames';
import { GxModal } from '@garpix/garpix-web-components-react';
import { useModal } from '../../hooks'
import style from './styles/index.module.scss';

const Modal = ({
  className,
  projectVariant,
  onHide,
  children,
  isOpen,
  ...props
}) => {
  const { closeModal } = useModal()
  return (
    <GxModal
      onGx-after-hide={() => { closeModal(); if (onHide) onHide(); }}
      className={classNames(
        style.container,
        style[projectVariant],
        { [className]: !!className }
      )}
      open={isOpen}
      {...props}
    >
      {children}
    </GxModal>
  )
}

export default Modal;
