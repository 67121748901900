import React from 'react';
import classNames from 'classnames';
import Title from '../../Title';
import { principalAlert } from '../../../images';
import style from './styles/index.module.scss';

const Header = ({
  principal,
  className,
  ...props
}) => {
  return (
    <section
      className={classNames({
        [style.principalHeader]: true,
        [className]: !!className
      })}
      {...props}
    >
      <div className={style.principalHeader__main}>
        <Title
          className={style.principalHeader__title}
          level='1'
          projectVariant='title_section'
        >
          <span>{principal?.title}</span>
          <img
            className={style.principalHeader__icon}
            src={principalAlert}
            alt=''
          />
        </Title>
        <span className={style.principalHeader__num}>{principal?.id}</span>
      </div>
    </section>
  )
}

export default Header;
