/* eslint-disable camelcase */

const orderDataForCrypto = (order) => {
  const { last_name, first_name, patronymic } = order?.underwriter || {}
  return {
    principal: {
      title: order?.principal?.title,
      inn: order?.principal?.legal_entity?.inn,
    },
    beneficiary: {
      title: order?.beneficiary?.title,
      inn: order?.beneficiary?.legal_entity?.inn,
    },
    purchase_number: order?.contest?.contract?.purchase?.number,
    fz: order?.contest?.fz?.fz,
    guaranty_type: order?.guarantee_type,
    guaranty_sum: order?.guaranty?.sum,
    start_date: order?.guaranty?.start_date,
    end_date: order?.guaranty?.end_date,
    provision: order?.guaranty?.provision_sum,
    fullName: `${last_name || ''} ${first_name || ''} ${patronymic || ''}`,
    date: new Date().toLocaleDateString(),
  }
}

export default orderDataForCrypto