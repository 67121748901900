// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cryptoPro_container__6JrLt::part(header) {\n  padding: 0 40px;\n}\n\n.cryptoPro_title__2nZ0A {\n  margin-bottom: 20px;\n}\n\n.cryptoPro_modal-body__169nb {\n  padding: 0 25px;\n}\n\n.cryptoPro_select__1h0wc {\n  margin-bottom: 10px;\n}", "",{"version":3,"sources":["webpack://src/views/ModalContent/styles/cryptoPro.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;;AAIA;EACE,mBAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,mBAAA;AADF","sourcesContent":[".container {\n  &::part(header) {\n    padding: 0 40px;\n  }\n}\n\n.title {\n  margin-bottom: 20px;\n}\n\n.modal-body {\n  padding: 0 25px;\n}\n\n.select {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "cryptoPro_container__6JrLt",
	"title": "cryptoPro_title__2nZ0A",
	"modal-body": "cryptoPro_modal-body__169nb",
	"select": "cryptoPro_select__1h0wc"
};
export default ___CSS_LOADER_EXPORT___;
