import AbstractBaseApi from '../AbstractBaseApi';

export default class StatusChangeApi extends AbstractBaseApi {
  postStatusUWNewApplication = async (params) => {
    const res = await this.post('order/order/to_state_underwriting_a_new_application/', params)
    return res.data
  }

  postStatusUWInProgress = async (params) => {
    const res = await this.post('order/order/to_state_underwriting_in_progress/', params)
    return res.data
  }

  postStatusUWRefusal = async (params) => {
    const res = await this.post('order/order/to_state_underwriting_refusal/', params)
    return res.data
  }

  postStatusUWRequery = async (params) => {
    const res = await this.post('order/order/to_state_underwriting_requery/', params)
    return res.data
  }

  postStatusQuoteAuto = async (params) => {
    const res = await this.post('order/order/to_state_quote_auto/', params)
    return res.data
  }

  postStatusQuoteTrade = async (params) => {
    const res = await this.post('order/order/to_state_quote_auction/', params)
    return res.data
  }

  postStatusQuoteIndivid = async (params) => {
    const res = await this.post('order/order/to_state_quote_individual/', params)
    return res.data
  }

  postStatusQuoteSent = async (params) => {
    const res = await this.post('order/order/to_state_quote_sent/', params)
    return res.data
  }

  postStatusGuarantyIssueRequested = async (params) => {
    const res = await this.post('order/order/to_state_guarantee_issue_requested/', params)
    return res.data
  }

  postStatusDocumentsSignature = async (params) => {
    const res = await this.post('order/order/to_state_documents_signature/', params)
    return res.data
  }

  postStatusQuoteRedefining = async (params) => {
    const res = await this.post('order/order/to_state_quote_redefined/', params)
    return res.data
  }

  updateState = async (params) => {
    const res = await this.post('order/order/update_state/', params)
    return res.data
  }
}