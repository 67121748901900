import React from 'react';
import { GxCheckbox } from '@garpix/garpix-web-components-react';

const Checkbox = ({ className, ...props }) => {
  return (
    <GxCheckbox
      className={className}
      {...props}
    />
  )
}

export default Checkbox;