import React from 'react';
import Layout from '../views';
import Principal from '../components/Principal';

const PrincipalPage = ({ ...props }) => {
  return (
    <Layout {...props}>
      <Principal />
    </Layout>
  )
}

export default PrincipalPage;