import React, { useState } from 'react';
import classNames from 'classnames';
import Button from '../../Button';
import Textarea from '../../Textarea';
import style from './styles/index.module.scss';

const ValidationControls = ({
  className,
  ...props
}) => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const toggleComment = () => {
    setIsDisplayed(!isDisplayed);
  }

  return (
    <section>
      <ul
        className={classNames({
          [style.list]: true,
          [className]: !!className
        })}
        {...props}
      >
        <li>
          <Button full>Валидировать</Button>
        </li>
        <li>
          <Button
            onClick={toggleComment}
            projectVariant='button-trasparent'
            spoiler
            spoilerState={isDisplayed}
            full
          >
            Уточнить
          </Button>
        </li>
      </ul>
      <div className={classNames({
        [style.comment_block]: true,
        [style.display]: isDisplayed
      })}>
        <Textarea
          placeholder='Комментарий'
          rows={1}
        />
        <Button full>Отправить</Button>
      </div>
    </section>
  )
}

export default ValidationControls;