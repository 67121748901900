export const cart = (store) => {
  store.on('@init', () => ({}))
}

export const user = (store) => {
  store.on('@init', () => ({ currentUser: null }))
  store.on('user/save', ({ currentUser }, userData) => {
    if (!userData) return { currentUser: null }
    return {
      currentUser: { ...currentUser, ...userData },
    }
  });
}

const modal = (store) => {
  store.on('@init', () => ({ showModal: null, props: {} }));
  store.on('modal/toggle', (_, { currentModal, ...params }) => ({
    showModal: currentModal,
    ...params,
  }));
  store.on('modal/update', (prevState, { props }) => ({
    ...prevState,
    props: { ...prevState.props, ...props }
  }))
  store.on('modal/close', () => ({ showModal: null, props: {} }));
};

export const storeonParams = [user, modal]