import React from 'react';
import classNames from 'classnames';
import Title from '../../Title';
import Checkbox from '../../Checkbox';
import style from './styles/index.module.scss';

const ProfileSettings = ({
  className,
  ...props
}) => {
  return (
    <section
      className={classNames(
        style.section,
        { [className]: !!className }
      )}
      {...props}
    >
      <fieldset className={classNames(
        [style.fieldset],
        [style.fieldset_sysSettings]
      )}>
        <Title
          className={style.fieldset__title}
          projectVariant='title_section'
          level='2'
        >
          Настройки системы
        </Title>
        <div className={style.fieldset__wrapper}>
          <Checkbox className={style.fieldset__checkbox}>
            Двухэтапная аутентификация
          </Checkbox>
        </div>
      </fieldset>
    </section>
  )
}

export default ProfileSettings;