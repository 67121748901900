import React from 'react';
import classNames from 'classnames';
import ProfileCommon from './ProfileCommon';
import ProfileNotifications from './ProfileNotifications';
import ProfileSettings from './ProfileSettings';
import ProfileButtons from './ProfileButtons';
import style from './styles/index.module.scss';

const Profile = ({
  handleAccQuit,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        [style.container],
        { [className]: !!className }
      )}
      {...props}
    >
      <ProfileCommon />
      <ProfileNotifications />
      <ProfileSettings />
      <ProfileButtons handleAccQuit={handleAccQuit} />
    </div>
  )
}

export default Profile;