/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import api from '../../api';
import { MODALS, ORDER_COMMENTS_PAGES, QUOTE_STATUSES_PULL, STATUSES_STATE } from '../../const';
import { handleRequestError } from '../../utils';
import { individQuoteSchema } from '../../utils/SchemasFormik';
import OrderViews from '../../views/OrderViews';
import { serializeTradeCards } from './utils'
import Preloader from '../../views/Preloader';
import { useModal } from '../../hooks';

const OrderQuotation = () => {
  const [order, setOrder] = useState({})
  const [quotes, setQuotes] = useState([])
  const [tradeCards, setTradeCards] = useState([])
  const [tradeComment, setTradeComment] = useState('')
  const [selectedQuotesId, setSelectedQuotesId] = useState([])
  const [tradeInputValues, setTradeInputValues] = useState({})
  const [calculatedQuote, setCalculatedQuote] = useState({})
  const [isTradeBlockOpen, setIsTradeBlockOpen] = useState(false);
  const [isCheckingNotDirectedQuotes, setIsChechingNotDirectedQuotes] = useState(false)
  const [banks, setBanks] = useState([])
  const [banksToSelect, setBanksToSelect] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const mounted = useRef()
  const navigate = useNavigate()
  const { closeModal, openModal } = useModal()

  const {
    getOrderById,
    getOrdersQuote,
    updateQuote,
    getComment,
    postOrderComment,
    calculateQuoteTrade,
    updateCalculatedQuoteTrade,
    createIndividualQuote,
  } = api.ordersApi
  const {
    postStatusQuoteAuto,
    postStatusQuoteRedefining,
  } = api.statusChangeApi
  const { getBankList } = api.bankApi
  const { orderId } = useParams()

  const initialIndividQuoteValues = {
    status: QUOTE_STATUSES_PULL.CREATED,
    order_id: orderId,
    individual_guarantee_rate: '',
  }

  const errorsMessenge = {
    incorrectValues: 'Некорректно введены данные'
  }

  // TODO Give better name to this function
  const isOrderStateIsNotInProgress = () => {
    const { UNDERWRITING_IN_PROGRESS, QUOTE_REDEFINED } = STATUSES_STATE
    const states = [UNDERWRITING_IN_PROGRESS, QUOTE_REDEFINED]
    return !states.includes(order.state)
  }

  const shouldRedefineBtnBeShow = () => {
    const { QUOTE_SENT } = STATUSES_STATE
    const redefineEnableStatuses = [QUOTE_SENT]
    return redefineEnableStatuses.includes(order.state)
  }

  const getCheckedQuote = () => quotes.find(({ quote }) => quote && quote?.id === selectedQuotesId[0])

  const handleChangeTradeComment = (e) => setTradeComment(e.target.value)
  const handleToggleTradeBlock = () => setIsTradeBlockOpen(state => !state)

  const isAutoBtnDisabled = () => isOrderStateIsNotInProgress() || !selectedQuotesId.length || isCheckingNotDirectedQuotes
  const isIndividBtnDisabled = () => isOrderStateIsNotInProgress() || !banksToSelect.length

  const isTradeBtnDisabled = () => {
    const checkedQuote = getCheckedQuote()
    if (checkedQuote?.quote) {
      return isAutoBtnDisabled()
        || checkedQuote.quote.status === QUOTE_STATUSES_PULL.NOT_DIRECTED
        || selectedQuotesId.length > 1
        || !Object.keys(tradeInputValues).length
        || !tradeComment
    }
    return true
  }

  const isCheckboxesDisabled = () => {
    return order.state === STATUSES_STATE.QUOTE_AUTO
  }

  const handleToggleQuoteSelect = (id) => {
    if (selectedQuotesId.includes(id)) {
      setSelectedQuotesId(state => state.filter(item => item !== id))
    } else {
      setSelectedQuotesId(state => ([...state, id]))
    }

    const { QUOTE_SENT, QUOTE_AGREED, QUOTE_REFUSAL, QUOTE_REDEFINED } = STATUSES_STATE
    const states = [QUOTE_SENT, QUOTE_AGREED, QUOTE_REFUSAL]

    const selectedQuote = quotes.find(({ quote }) => quote.id === id)
    // Todo Optimize Array Iterations
    if (states.includes(order.state)) {
      setSelectedQuotesId(state => {
        if (state.length) return [state[state.length - 1]]
        return []
      })
      setIsChechingNotDirectedQuotes(true)
      return
    }

    if (order.state === QUOTE_REDEFINED) {
      if (selectedQuote?.quote.status === QUOTE_STATUSES_PULL.NOT_DIRECTED) {
        setSelectedQuotesId(state => {
          if (state.length) return [state[state.length - 1]]
          return []
        })
        setIsChechingNotDirectedQuotes(true)
      } else {
        const notDirectedQuoteIds = quotes
          .filter(({ quote }) => quote.status !== QUOTE_STATUSES_PULL.NOT_DIRECTED)
          .map(({ quote }) => quote.id)
        setSelectedQuotesId(state => state.filter(item => notDirectedQuoteIds.includes(item)))
        setIsChechingNotDirectedQuotes(false)
      }
    }
  }

  const updateQuotesList = () => {
    return getOrdersQuote({ order: orderId })
      .then(data => setQuotes(data))
      .catch(err => handleRequestError(err, navigate))
  }

  const onSubmitAutoQuote = () => {
    const promises = []
    quotes.forEach(({ quote }) => {
      const isQuoteSelected = selectedQuotesId.includes(quote.id)
      promises.push(updateQuote(quote.id, { is_edited: isQuoteSelected }))
    })

    return toast.promise(Promise.all(promises), {
      pending: 'Направление...',
      success: 'Котировки успешно направлены!',
    })
      .then(updateQuotesList)
      .then(() => postStatusQuoteAuto({ order: orderId }))
      .then((data) => {
        if (data?.status) setOrder(data.data)
        setSelectedQuotesId([])
      })
      .catch(err => handleRequestError(err, navigate))
  }

  const onSubmitIndividQuote = (data, { resetForm, setErrors }) => {
    const handleCreateIndividQuote = () => createIndividualQuote({
      ...data,
      order_id: orderId,
      individual_guarantee_rate: data.individual_guarantee_rate.replace(',', '.'),
    })
    return toast.promise(handleCreateIndividQuote, {
      pending: 'Создание индивидуальной котировки...',
      success: 'Котировка успешно создана!',
    })
      .then(updateQuotesList)
      .then(() => {
        closeModal()
        resetForm()
      })
      .catch(err => {
        handleRequestError(err, navigate)
        if (err.response) {
          setErrors(err.response.data)
        }
      })
  }

  const onSubmitTradeQuote = () => {
    const selectedQuote = quotes.find(({ quote }) => quote.id === selectedQuotesId[0])
    const handleUpdateCalculatedQuoteTrade = () => updateCalculatedQuoteTrade(selectedQuote.quote.id, tradeInputValues)

    return toast.promise(handleUpdateCalculatedQuoteTrade, {
      pending: 'Сохранение торга...',
      success: 'Торг успешно сохранен!',
    })
      .then(() => {
        const commentParams = {
          order: orderId,
          content: tradeComment,
          page: ORDER_COMMENTS_PAGES.QUOTE_AUCTION,
        }
        return postOrderComment(commentParams)
      })
      .then(() => {
        if (selectedQuote.quote.status === QUOTE_STATUSES_PULL.DIRECTED) {
          const quoteParams = { status: QUOTE_STATUSES_PULL.UPDATED }
          return updateQuote(selectedQuote.quote.id, quoteParams)
        }
        return Promise.resolve()
      })
      .then(updateQuotesList)
      .catch(err => handleRequestError(err, navigate))
  }

  const onClickRedefineQuoteBtn = () => {
    return postStatusQuoteRedefining({ order: orderId })
      .then((data) => {
        if (data?.status) setOrder(data.data)
        setSelectedQuotesId([])
      })
      .catch(err => handleRequestError(err, navigate))
  }

  const updateCommissions = (data) => {
    return calculateQuoteTrade(selectedQuotesId[0], data)
      .then(res => {
        setCalculatedQuote(res)
        const updatedQuotes = quotes.map(item => item.quote.id === res.id ? { ...item, quote: res } : item)
        setQuotes(updatedQuotes)
      })
      .catch(err => handleRequestError(err, navigate))
  }

  const handleSubmitToGetComputedSum = (name, value) => {
    setTradeInputValues(state => {
      return {
        ...state,
        [name]: value
      }
    })
  }

  const renderCheckedQuoteTrade = () => {
    const checkedQuote = getCheckedQuote()
    if (checkedQuote) {
      const serializedTrades = serializeTradeCards(checkedQuote?.quote, calculatedQuote)

      const modifiedSerializedTrades = serializedTrades.map(trade => {
        return trade.map(item => {
          if (!item.inputAttr) return item
          const isQuoteNotDirected = checkedQuote.quote.status !== QUOTE_STATUSES_PULL.NOT_DIRECTED
          return {
            ...item,
            inputAttr: !isOrderStateIsNotInProgress() && isQuoteNotDirected
              ? {
                ...item.inputAttr,
                handleAccept: handleSubmitToGetComputedSum,
              }
              : undefined
          }
        })
      })

      setTradeCards(modifiedSerializedTrades)
    }
  }

  const formik = useFormik({
    onSubmit: onSubmitIndividQuote,
    initialValues: initialIndividQuoteValues,
    validationSchema: individQuoteSchema(errorsMessenge),
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true
  })

  const handleOpenIndividCreateModal = () => openModal(MODALS.individualQuoteModal, {
    formik,
    banks: banksToSelect,
    handleClose: closeModal,
  })

  useEffect(() => {
    setIsFetching(true)
    Promise.all([
      getOrderById(orderId),
      getOrdersQuote({ order: orderId }),
      getComment({ order: orderId }),
      getBankList(),
    ])
      .then(([orderData, quoteData, commentData, banksData]) => {
        if (commentData.length) {
          const comment = commentData.find(item => item.page === ORDER_COMMENTS_PAGES.QUOTE_AUCTION)
          setTradeComment(comment?.content || '')
        }
        setOrder(orderData)
        setQuotes(quoteData)
        setBanks(banksData)
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  useEffect(() => {
    const quoteBanksId = quotes.map(item => item.quote.bank.id)

    // Todo Optimize Array Iterations
    const filteredBanksToIndividQuote = banks.filter(item => {
      const orderQuote = quotes.find(({ quote }) => quote.bank.id === item.id)
      if (orderQuote?.quote?.status === QUOTE_STATUSES_PULL.NOT_DIRECTED) return true
      return !quoteBanksId.includes(item.id)
    })

    const bankItemsToSelect = filteredBanksToIndividQuote.map(item => ({
      id: item.id,
      value: item.id,
      name: item.title
    }))

    setBanksToSelect(bankItemsToSelect)
  }, [quotes, banks])

  useEffect(() => {
    renderCheckedQuoteTrade()
    if (!selectedQuotesId.length || selectedQuotesId.length > 1) {
      setIsTradeBlockOpen(false)
    }
  }, [selectedQuotesId, calculatedQuote, quotes])

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      return
    }
    updateCommissions(tradeInputValues)
  }, [tradeInputValues])

  return (
    <>
      <OrderViews.Header
        orderId={order.id}
        sum={order.sum}
        principal={order.principal}
        fz={order.contest?.fz?.fz}
        status={order.state}
      />
      {isFetching ?
        <Preloader />
        :
        <OrderViews.QuotationBankList
          quotes={quotes}
          isAutoBtnDisabled={isAutoBtnDisabled}
          isIndividBtnDisabled={isIndividBtnDisabled()}
          isTradeBtnDisabled={isTradeBtnDisabled}
          selectedQuotesId={selectedQuotesId}
          handleToggleQuoteSelect={handleToggleQuoteSelect}
          tradeCards={tradeCards}
          isTradeBlockOpen={isTradeBlockOpen}
          handleToggleTradeBlock={handleToggleTradeBlock}
          onSubmitAutoQuote={onSubmitAutoQuote}
          onSubmitTradeQuote={onSubmitTradeQuote}
          handleOpenIndividCreateModal={handleOpenIndividCreateModal}
          tradeComment={tradeComment}
          handleChangeTradeComment={handleChangeTradeComment}
          isCheckboxesDisabled={isCheckboxesDisabled}
          shouldRedefineBtnBeShow={shouldRedefineBtnBeShow}
          onClickRedefineQuoteBtn={onClickRedefineQuoteBtn}
        />
      }
    </>
  )
}

export default OrderQuotation;