import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import Title from '../../Title';
import Checkbox from '../../Checkbox';
import ButtonIcon from '../../ButtonIcon';
import Icon from '../../Icon';
import { del, plus } from '../../../images';
import style from './styles/index.module.scss';
import { MODALS } from '../../../const';
import { useModal } from '../../../hooks';

const DocumentListChecking = ({
  title,
  documents,
  onDelete,
  checked = [],
  toggleCheck,
  handleUpload,
  isOther = false,
  isAction = false,
  className,
  ...props
}) => {
  const [type, setType] = useState({});

  const inputFile = useRef(null);
  const { closeModal, openModal } = useModal()

  const handleChangeFile = (e) => {
    handleUpload(e?.target?.files[0], type);
    inputFile.current.value = null;
  };

  const handleChangeFileNew = (rawType) => {
    setType(rawType);
    inputFile.current.click();
  };

  const downloadName = (doc) => {
    const name = doc?.document_title;
    const path = doc?.document.split('.');
    const ext = path && path[path.length - 1];
    return `${name}.${ext}`;
  };

  const handleClose = () => {
    closeModal();
  };

  const handleOpen = (doc) => {
    openModal(MODALS.documentCheckingModal, {
      handleDelete: () => {
        onDelete(doc?.id)
        closeModal()
      },
      handleClose
    })
  }

  return (
    <section
      className={className}
      {...props}
    >
      <input
        className={style.input}
        accept='text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        id='add-document'
        multiple={false}
        type='file'
        ref={inputFile}
        onChange={handleChangeFile}
      />
      <div className={style.row}>
        {title &&
          <Title
            className={style.title}
            level='2'
            projectVariant='title_section'
          >
            {title}
          </Title>}
        {isOther && isAction && <ButtonIcon
          onClick={() => handleChangeFileNew(documents[0])}
          iconSrc={plus}
        />}
      </div>
      {!isOther &&
        <ul className={style.list}>
          {documents.map(doc => {
            const { document } = doc;
            const itemClassName = classNames(
              style.listItem,
              { [style.listItemDisabled]: !document }
            );

            return (
              <li className={itemClassName} key={doc?.id}>
                <Checkbox
                  name={document?.id}
                  checked={checked.includes(document?.id)}
                  onGx-change={toggleCheck}
                  disabled={!document || !isAction}
                />
                <div className={style.nameWrapper}>
                  <span className={style.name}>
                    {document ? document?.document_title : doc.title}
                  </span>
                  {document && document.is_valid &&
                    <Icon icon='check_circle' alt='Подтверждено' />
                  }
                  {document && !document.is_valid &&
                    <Icon icon='cancel' alt='Отказано' />
                  }
                </div>
                <span className={style.date}>
                  {document ?
                    new Date(document.update_at).toLocaleDateString() :
                    '-'
                  }
                </span>
                <div className={style.controls}>
                  {document && isAction
                    && <ButtonIcon
                      onClick={() => handleOpen(document)}
                      iconSrc={del}
                    />
                  }
                  {document &&
                    <a
                      href={document.document}
                      download={downloadName(document)}
                    >
                      <Icon icon='download' alt='Скачать' />
                    </a>
                  }
                  {!document && isAction
                    && <ButtonIcon
                      onClick={() => handleChangeFileNew(doc)}
                      iconSrc={plus}
                    />
                  }
                  {!document && <Icon className={style.icon} icon='clip' />}
                </div>
              </li>
            )
          })}
        </ul>
      }
      {isOther &&
        <ul className={style.list}>
          {documents[0].documents.map(document => {
            const itemClassName = classNames(style.listItem);

            return (
              <li className={itemClassName} key={document?.id}>
                <Checkbox
                  name={document?.id}
                  checked={checked.includes(document?.id)}
                  onGx-change={toggleCheck}
                  disabled={!isAction}
                />
                <div className={style.nameWrapper}>
                  <span className={style.name}>
                    {document?.document_title}
                  </span>
                  {document?.is_valid &&
                    <Icon icon='check_circle' alt='Подтверждено' />
                  }
                  {!document?.is_valid &&
                    <Icon icon='cancel' alt='Отказано' />
                  }
                </div>
                <span className={style.date}>
                  {new Date(document.update_at).toLocaleDateString()}
                </span>
                <div className={style.controls}>
                  {isAction &&
                    <ButtonIcon
                      onClick={() => handleOpen(document)}
                      iconSrc={del}
                    />
                  }
                  <a
                    href={document.document}
                    download={downloadName(document)}
                  >
                    <Icon icon='download' alt='Скачать' />
                  </a>
                </div>
              </li>
            )
          })}
        </ul>
      }
    </section >
  )
}

export default DocumentListChecking;