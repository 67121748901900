import React from 'react';
import classNames from 'classnames';
import Checkbox from '../../Checkbox';
import Button from '../../Button';
import ParamCard from '../../ParamCard';
import { arrowDown } from '../../../images';
import Textarea from '../../Textarea';
import { formatPercentages, numberWithSpaces } from '../../../utils'
import { QUOTE_STATUSES_PULL } from '../../../const'
import style from './styles/index.module.scss';

const QuotationBankList = ({
  quotes,
  tradeCards,
  tradeComment,
  selectedQuotesId,
  touched,
  handleOpenIndividCreateModal,
  isTradeBlockOpen,
  isAutoBtnDisabled,
  isIndividBtnDisabled,
  isTradeBtnDisabled,
  isCheckboxesDisabled,
  handleToggleQuoteSelect,
  handleToggleTradeBlock,
  handleCloseModal,
  handleChangeTradeComment,
  onSubmitAutoQuote,
  onSubmitTradeQuote,
  shouldRedefineBtnBeShow,
  onClickRedefineQuoteBtn,
  className,
  ...props
}) => {
  const isChecked = (quote) => isCheckboxesDisabled() ? quote.is_edited : selectedQuotesId.includes(quote?.id)
  const quoteItemClassName = (quote) => classNames(style.bankListItem, {
    [style.bankListItem_notDirected]: quote.status === QUOTE_STATUSES_PULL.NOT_DIRECTED
  })
  return (
    <div>
      <section
        className={className}
        {...props}
      >
        <ul className={style.bankList}>
          {quotes?.map(({ quote }) => quote && (
            <li key={quote.id} className={quoteItemClassName(quote)} >
              <Checkbox
                name='quote'
                value={quote?.id}
                onGx-change={() => handleToggleQuoteSelect(quote?.id)}
                checked={isChecked(quote)}
                disabled={isCheckboxesDisabled(quote)}
              />

              <img className={style.bankListLogo} alt='Логотип банка' src={quote?.bank?.logo} />
              <span className={style.bankListName}>{quote?.bank?.title || '-'}</span>
              <div className={style.bankListBlock}>
                <span className={style.bankListKey}>Сумма комиссии</span>
                <span className={style.bankListValue}>{numberWithSpaces(quote?.total_commission, true) ?? '-'}</span>
              </div>
              <div className={style.bankListBlock}>
                <span className={style.bankListKey}>Котировка</span>
                <span className={style.bankListValue}>{quote?.guarantee_rate ? `${formatPercentages(quote?.guarantee_rate, true)}%` : '-'}</span>
              </div>
              <div className={style.bankListBlock}>
                <span className={style.bankListKey}>Тип</span>
                <span className={style.bankListValue}>{quote?.type || '-'}</span>
              </div>
              <div className={style.bankListBlock}>
                <span className={style.bankListKey}>Статус</span>
                <span className={style.bankListValue}>{quote?.status || '-'}</span>
              </div>
              <div className={style.bankListBlock}>
                <span className={style.bankListKey}>Дата</span>
                <span className={style.bankListValue}>{quote?.expiry_date ? new Date(quote?.expiry_date).toLocaleDateString() : '-'}</span>
              </div>
            </li>
          ))}
        </ul>
      </section>
      <div className={style.controls}>
        {shouldRedefineBtnBeShow()
          ? <Button
            className={style.btn}
            type='button'
            onClick={onClickRedefineQuoteBtn}
          >
            Обновить котировку
          </Button>
          : <Button
            className={style.btn}
            type='button'
            disabled={isAutoBtnDisabled()}
            onClick={onSubmitAutoQuote}
          >
            Направить котировку
          </Button>
        }
        <Button
          className={style.btn}
          projectVariant='button_transparent'
          type='button'
          onClick={handleOpenIndividCreateModal}
          disabled={isIndividBtnDisabled}
        >
          Индивидуальная котировка
        </Button>
        <Button
          className={classNames([style.btn, style.btnAuction])}
          projectVariant='button_transparent'
          type='button'
          onClick={handleToggleTradeBlock}
          disabled={!selectedQuotesId.length || selectedQuotesId.length > 1}
        >
          <span>Торг</span>
          <img className={classNames({ [style.iconTurned]: isTradeBlockOpen })} src={arrowDown} slot='icon-right' alt='' />
        </Button>
        <Button
          className={style.btn}
          type='submit'
          onClick={onSubmitTradeQuote}
          disabled={isTradeBtnDisabled()}
        >
          Сохранить
        </Button>
      </div>

      {isTradeBlockOpen && (
        <div className={style.auctionWrapper}>

          {tradeCards?.map(card => <ParamCard className={style.auctionCard} dataInput={card} />)}

          <div className={style.textareaWrapper}>
            <Textarea
              rows='1'
              value={tradeComment}
              onGx-input={handleChangeTradeComment}
              name='content'
              placeholder='Комментарий'
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default QuotationBankList;