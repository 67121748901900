import React from 'react';
import { NavLink } from 'react-router-dom';
import { GxIcon } from '@garpix/garpix-web-components-react';
import Button from '../Button';
import {
  chat,
  chatIndicator,
  bell,
  bellIndicator,
  question,
  user,
  search
} from '../../images';
import { PATHS } from '../../const';
import styles from './styles/index.module.scss';

const Masthead = ({
  isChatActive,
  isBellActive,
  onToggleVisible,
  // onBellClick,
}) => {
  return (
    <div className={styles.masthead}>
      <Button
        className={styles.masthead__btn_search}
        onClick={onToggleVisible}
        projectVariant='button_special'
      >
        <GxIcon
          className={styles.masthead__icon}
          src={search}
        />
      </Button>
      <NavLink
        className={styles.masthead__btn}
        to={PATHS.CHAT.path}
      >
        <GxIcon
          className={styles.masthead__icon}
          src={isChatActive ? chatIndicator : chat}
        />
      </NavLink>
      <NavLink
        className={styles.masthead__btn}
        to={PATHS.NOTIFICATIONS.path}
      >
        <GxIcon
          className={styles.masthead__icon}
          src={isBellActive ? bellIndicator : bell}
        />
      </NavLink>
      {/* <Button
        className={styles.masthead__btn}
        slot='trigger'
        onClick={onBellClick}
        projectVariant='button_special'
      >
        <GxIcon
          className={styles.masthead__icon}
          src={user}
        />
      </Button> */}
      <NavLink
        className={styles.masthead__btn}
        to={PATHS.PROFILE.path}
      >
        <GxIcon
          className={styles.masthead__icon}
          src={user}
        />
      </NavLink>
      <NavLink
        className={styles.masthead__btn}
        to={PATHS.FAQ.path}
      >
        <GxIcon
          className={styles.masthead__icon}
          src={question}
        />
      </NavLink>
    </div>
  )
}

export default Masthead
