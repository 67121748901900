import * as Yup from 'yup'

const validEmailRegexp = /^[A-Z0-9._+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i
const decimalNumsRegexp = /^(0|[1-9]\d*)((\.|,)\d+)?$/

const standartErrorMessages = {
  requiredField: 'Это поле обязательно',
  notValidEmail: 'Некорректно введен Email',
  incorrectValues: 'Некорректно введены данные',
  shortValue: 'Минимальное количество символов - 8',
  longValue: 'Максимальное количество символов - 128',

}

export const loginSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email(standartErrorMessages.notValidEmail)
      .matches(validEmailRegexp, standartErrorMessages.notValidEmail)
      .max(128, standartErrorMessages.longValue)
      .required(standartErrorMessages.requiredField),
    password: Yup.string()
      .min(8, standartErrorMessages.shortValue)
      .max(128, standartErrorMessages.longValue)
      .required(standartErrorMessages.requiredField),
    serverError: Yup.string(),
  });
};

export const restorePassSchema = () => {
  return Yup.object().shape({
    password: Yup.string()
      .min(8, standartErrorMessages.shortValue)
      .max(128, standartErrorMessages.longValue)
      .required(standartErrorMessages.requiredField),
    serverError: Yup.string(),
  });
}

export const forgotPassSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email(standartErrorMessages.notValidEmail)
      .matches(validEmailRegexp, standartErrorMessages.notValidEmail)
      .max(128, standartErrorMessages.longValue)
      .required(standartErrorMessages.requiredField),
    serverError: Yup.string(),
  });
}

export const filterFormSchema = (errorsMessenge) => {
  return Yup.object().shape({
    yearMin: Yup.number()
      .integer(errorsMessenge.incorrectValues)
      .min(2000, errorsMessenge.outOfRange)
      .max(new Date().getFullYear(), errorsMessenge.outOfRange),
    yearMax: Yup.number()
      .integer(errorsMessenge.incorrectValues)
      .min(2000, errorsMessenge.outOfRange)
      .max(new Date().getFullYear(), errorsMessenge.outOfRange),
    contest__type: Yup.string(),
    guarantySumMin: Yup.number(),
    guarantySumMax: Yup.number(),
    status: Yup.string()
  })
}

export const individQuoteSchema = (errorsMessenge) => {
  return Yup.object().shape({
    individual_guarantee_rate: Yup.string()
      .test(
        'is-decimal',
        errorsMessenge.incorrectValues,
        value => String(value).match(decimalNumsRegexp),
      )
      .required(standartErrorMessages.requiredField),
    bank: Yup.string()
      .required(standartErrorMessages.requiredField),
  })
}

export const updateLimitSchema = () => {
  return Yup.object().shape({
    total_limit: Yup.number()
      .typeError(standartErrorMessages.incorrectValues)
      .required(standartErrorMessages.requiredField),
    content: Yup.string()
      .required(standartErrorMessages.requiredField),
  })
}

export const blackListSchema = () => {
  return Yup.object().shape({
    inn: Yup.number()
      .integer(standartErrorMessages.incorrectValues)
      .required(standartErrorMessages.requiredField),
    title: Yup.string()
      .required(standartErrorMessages.requiredField),
    comment: Yup.string()
      .max(128, standartErrorMessages.longValue)
      .required(standartErrorMessages.requiredField)
  })
}