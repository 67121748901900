import React from 'react';
import MiscViews from '../views/MiscViews'

const Error404 = () => {
  return (
    <MiscViews.RequestStatus requestStatus={404} />
  )
}

export default Error404;
