import React from 'react';
import classNames from 'classnames';
import style from './styles/index.module.scss';

const ErrorField = ({ className, message, ...props }) => {
  return (
    <div
      className={classNames({
        [style.error]: true,
        [className]: !!className
      })}
      {...props}
    >
      {message}
    </div>
  )
}

export default ErrorField;