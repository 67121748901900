// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_list__2Vu4N {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-gap: 20px;\n}\n\n@media (max-width: 1100px) {\n  .styles_list__2Vu4N {\n    grid-template-columns: 1fr;\n  }\n}", "",{"version":3,"sources":["webpack://src/views/PrincipalViews/Summery/styles/index.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,qCAAA;EACA,cAAA;AADF;;AAIA;EACE;IACE,0BAAA;EADF;AACF","sourcesContent":["@import \"../../../../styles/global/index.scss\";\n\n.list {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-gap: 20px;\n}\n\n@media (max-width: 1100px) {\n  .list {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "styles_list__2Vu4N"
};
export default ___CSS_LOADER_EXPORT___;
