import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { GxForm } from '@garpix/garpix-web-components-react';
import Title from '../Title/index';
import Input from '../Input/index';
import Button from '../Button/index';
import ErrorField from '../ErrorField';
import { logoLg } from '../../images'
import { PATHS } from '../../const';
import style from './styles/index.module.scss';

const Login = ({
  handleSubmit,
  handleBlur,
  handleChange,
  errors,
  values,
  touched,
  isSubmitting,
  className,
  ...props
}) => {
  return (
    <div className={classNames({
      [style.container]: true,
      [className]: !!className
    })}
      {...props}
    >
      <div className={style.logoBlock}>
        <img src={logoLg} alt='logo' />
      </div>
      <GxForm
        className={style.form}
        noValidate
        onGx-submit={handleSubmit}
      >
        <Title
          className={style.title}
          level='2'
          projectVariant='title_section'
        >
          Войти в систему
        </Title>
        <Input
          onGx-blur={handleBlur}
          onGx-input={handleChange}
          value={values.email}
          className={style.email}
          type='email'
          label='E-mail'
          name='email'
          helpText={<ErrorField
            slot='help-text'
            message={touched.email && errors.email}
          />}
        />
        <Input
          onGx-blur={handleBlur}
          onGx-input={handleChange}
          value={values.password}
          className={style.password}
          type='password'
          label='Пароль'
          name='password'
          helpText={<ErrorField
            slot='help-text'
            message={touched.password && errors.password}
          />}
        />
        <Button
          className={style.submit}
          type='submit'
          full
        >
          {isSubmitting ? 'Вход...' : 'Войти'}
        </Button>
        <NavLink
          className={style.link}
          to={PATHS.FORGOT_PASSWORD.path}
        >
          Забыли пароль?
        </NavLink>
      </GxForm>
      <p className={style.termsOfService}>
        <NavLink
          target='_blank'
          to={PATHS.TERMS_OF_SERVICE.path}
        >
          Политика конфиденциальности и обработки персональных данных
        </NavLink>
      </p>
    </div>
  );
}

export default Login;
