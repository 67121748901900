import React from 'react';
import { formatPercentages } from '../../../utils';
import Title from '../../Title';
import style from './styles/index.module.scss';

const ScoringOW = ({
  scoring,
  className,
  ...props
}) => {
  return (
    <section
      className={className}
      {...props}
    >
      <Title className={style.st} level='2' projectVariant='title_section'>Скоринг Banksly</Title>
      <div className={style.container}>
        <span className={style.key}>модели вероятности требований по банковским гарантиям по 44-ФЗ и 223-ФЗ</span>
        <span className={style.value}>{scoring || scoring === 0 ? `${formatPercentages(scoring)}%` : '-'}</span>
      </div>
    </section>
  )
}

export default ScoringOW;