import React from 'react'
import OrderRating from '../components/OrderRating'
import Layout from '../views'

const OrderFinreportingPage = (props) => {
  return (
    <Layout {...props}>
      <OrderRating />
    </Layout>
  )
}

export default OrderFinreportingPage
