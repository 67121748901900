import React from 'react'
import Layout from '../views'
import OrderUnderwriting from '../components/OrderUnderwriting'

const OrderUnderwritingPage = (props) => {
  return (
    <Layout {...props}>
      <OrderUnderwriting />
    </Layout>
  )
}

export default OrderUnderwritingPage
