import React from 'react';
import style from './styles/index.module.scss';

const Diagram = ({
  scoring,
  className,
  ...props
}) => {
  const data = [
    {
      id: 1,
      value: scoring?.courts_module,
    },
    {
      id: 2,
      value: scoring?.executory_module,
    },
    {
      id: 3,
      value: scoring?.finance_module,
    },
    {
      id: 4,
      value: scoring?.qualitative_module,
    },
    {
      id: 5,
      value: scoring?.signals_module,
    },
    {
      id: 6,
      value: scoring?.tenders_module,
    },
  ];

  return (
    <section
      className={className}
      {...props}
    >
      <header>
        <ul className={style.headerList}>
          <li>Арбитражные суды</li>
          <li>Исполнительные производства</li>
          <li>Финансовый модуль</li>
          <li>Качественный модуль</li>
          <li>Предсигналы</li>
          <li>Тендеры</li>
        </ul>
      </header>
      <div className={style.container}>
        <ul className={style.availableValues}>
          <li>100</li>
          <li>80</li>
          <li>60</li>
          <li>40</li>
          <li>20</li>
          <li>0</li>
          <li>-20</li>
          <li>-40</li>
          <li>-60</li>
          <li>-80</li>
          <li>-100</li>
        </ul>
        <ul className={style.positiveValues}>
          {data.map(element => {
            const height = +element.value > 0 ? +element.value : 0;

            return (
              <li key={element.id}>
                <div style={{ height: `${height}%` }} />
              </li>
            )
          })}
        </ul>
        <ul className={style.negativeValues}>
          {data.map(element => {
            const height = +element.value < 0 ? Math.abs(element.value) : 0;

            return (
              <li key={element.id}>
                <div style={{ height: `${height}%` }} />
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default Diagram;