import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik'
import limitUtils from './utils'
import api from '../../api';
import OrderViews from '../../views/OrderViews';
import EstimatedLimit from '../../views/OrderViews/EstimatedLimit';
import SimpleTable from '../../views/SimpleTable';
import { handleRequestError } from '../../utils';
import { ORDER_COMMENTS_PAGES } from '../../const';
import { updateLimitSchema } from '../../utils/SchemasFormik';
import style from './index.module.scss';
import Preloader from '../../views/Preloader';

const OrderLimits = () => {
  const [bank, setBank] = useState([])
  const [fz, setFz] = useState([])
  const [principal, setPrincipal] = useState([])
  const [order, setOrder] = useState([])
  const [calculatedLimit, setCalculatedLimit] = useState(0)
  const [principalLimit, setPrincipalLimit] = useState({})
  const [comment, setComment] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const { getOrderById, postOrderComment, getComment } = api.ordersApi
  const {
    getFzLimits,
    getBankLimits,
    getPrincipalList,
    updatePrincipalLimit,
  } = api.limitsApi
  const { orderId } = useParams()
  const navigate = useNavigate()

  const onSubmit = (data) => {
    const { content, total_limit } = data;

    // eslint-disable-next-line camelcase
    if (total_limit) {
      const handleUpdatePrincipalLimit = () => updatePrincipalLimit(principalLimit?.id, { total_limit })
      toast.promise(handleUpdatePrincipalLimit, {
        pending: 'Обновление лимита...',
        success: 'Лимит успешно обновлен'
      })
        .then(res => {
          const convertedPrincipal = limitUtils.convertPrincipalLimitToTable(res)
          setPrincipal(convertedPrincipal)
        })
        .catch(err => handleRequestError(err, navigate))
    }

    const commentParams = { content, order: orderId, page: ORDER_COMMENTS_PAGES.LIMIT }
    postOrderComment(commentParams)
  }

  const initialValues = {
    content: comment,
    limit: null,
  }

  const isInputReadOnly = () => comment !== ''

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema: updateLimitSchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
  })

  useEffect(() => {
    setIsFetching(true)
    getOrderById(orderId)
      .then(data => {
        setOrder(data);
        return Promise.all([
          getBankLimits(),
          getFzLimits(),
          getPrincipalList({ principal: data?.principal?.id }),
          getComment({ order: orderId, page: ORDER_COMMENTS_PAGES.LIMIT })
        ])
      })
      .then(([bankData, fzData, principalLimitData, commentData]) => {
        const convertedPrincipal = limitUtils.convertPrincipalLimitToTable(principalLimitData[0])
        setPrincipal(convertedPrincipal)
        setCalculatedLimit(+principalLimitData[0]?.total_limit)
        setPrincipalLimit(principalLimitData[0])
        setBank(bankData)
        setFz(fzData)

        if (commentData.length) setComment(commentData[0].content)
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  return (
    <>
      <OrderViews.Header
        orderId={order.id}
        sum={order.sum}
        principal={order.principal}
        fz={order.contest?.fz?.fz}
        status={order.state}
      />
      {isFetching ?
        <Preloader />
        :
        <>
          <EstimatedLimit
            formik={formik}
            calculatedLimit={calculatedLimit}
            isInputReadOnly={isInputReadOnly}
          />
          {principal ?
            <section>
              <SimpleTable className={style.table} tableHead={limitUtils.getLimitsPrincipalListHead()} tableBody={principal} />
            </section>
            :
            <p>Нет данных</p>
          }
          {bank ?
            <section>
              <SimpleTable className={style.table} tableHead={limitUtils.getLimitsListHead('Банк')} tableBody={bank} />
            </section>
            :
            <p>Нет данных</p>
          }
          {fz ?
            <section>
              <SimpleTable className={style.table} tableHead={limitUtils.getLimitsListHead('Вид')} tableBody={fz} />
            </section>
            :
            <p>Нет данных</p>
          }
        </>
      }
    </>
  )
}

export default OrderLimits