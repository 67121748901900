import React from 'react';
import { applyPolyfills, defineCustomElements } from '@garpix/garpix-web-components/loader';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { StoreContext } from 'storeon/react';
import { createStoreon } from 'storeon';
import { storeonParams } from './store';
import App from './App';

const store = createStoreon(storeonParams);
ReactDOM.render(
  <React.StrictMode>
    <StoreContext.Provider value={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StoreContext.Provider>
  </React.StrictMode>,
  // eslint-disable-next-line no-undef
  document.getElementById('root')
);


applyPolyfills().then(() => {
  defineCustomElements();
});
