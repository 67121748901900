// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__1QNgI {\n  display: grid;\n  grid-template-columns: minmax(250px, 4fr) 10fr;\n  grid-gap: 20px;\n}", "",{"version":3,"sources":["webpack://src/views/MiscViews/Layout/styles/index.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8CAAA;EACA,cAAA;AADF","sourcesContent":["@import \"../../../../styles/global/mixins.scss\";\n\n.container {\n  display: grid;\n  grid-template-columns: minmax(250px, 4fr) 10fr;\n  grid-gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__1QNgI"
};
export default ___CSS_LOADER_EXPORT___;
