import React, { useState } from 'react'
import classNames from 'classnames'
import Icon from '../Icon'
import style from './index.module.scss'

const Table = ({ row = [], tableData = [], colsKeys = [], tableHeader }) => {
  const [openTable, setOpenTable] = useState(false)

  const toggleOpenTable = (e) => {
    e.preventDefault();
    if (row.length > 1) {
      setOpenTable(!openTable)
    }
  }
  const tableClasses = classNames({
    [style.table]: true,
    [style.table_open]: openTable
  })

  const collapseBlock = (col) => classNames({
    [style.block_open]: true,
    [style.block_closed]: !openTable,
    [style.colChild]: !!col.isChild
  })

  return (
    <div className={tableClasses}>
      {
        (!openTable && !!tableHeader?.length) && tableHeader.map((headerItem, itemIdx) => {
          const keyHead = `head-${itemIdx}`
          const tableId = `table-${tableData.key}`

          return <div key={keyHead} className={style['table-header']}>
            <a
              tabIndex={0}
              href={`#${tableId}`}
              role='button'
              aria-label='toggle-collapse'
              aria-expanded='false'
              onClick={toggleOpenTable}
            >
              <div className={style['table-row']}>
                {colsKeys.map((colName, colIdx) => {
                  const colKey = `head-col-${colIdx}`
                  return (
                    <div key={colKey} className={style['table-col']}>{headerItem[colName] || ''}</div>
                  )
                })}
                {row.length > 1 &&
                  <div className={style.iconCell}>
                    <Icon variant={openTable ? 'rotate' : null} icon='arrow_down' />
                  </div>
                }
              </div>
            </a>
          </div>
        })
      }

      {(!tableHeader || openTable) && row.map((item, itemIdx) => {
        if (!item) return undefined

        const keyHead = `head-${itemIdx}`
        const keyTable = `table-${itemIdx}`
        const tableId = `table-${tableData.key}`

        const tableClassName = classNames(style['table-body'], {
          [style['table-footer']]: item.title === 'Итого',
        })


        if ((openTable || !tableHeader) && itemIdx === 0) {
          return (
            <div key={keyHead} className={style['table-header']}>
              <a
                tabIndex={0}
                href={`#${tableId}`}
                role='button'
                aria-label='toggle-collapse'
                aria-expanded='false'
                onClick={toggleOpenTable}
              >
                <div className={style['table-row']}>
                  {colsKeys.map((colName, colIdx) => {
                    const colKey = `head-col-${colIdx}`
                    return (
                      <div key={colKey} className={style['table-col']}>{item[colName] || ''}</div>
                    )
                  })}
                  {row.length > 1 &&
                    <div className={style.iconCell}>
                      <Icon variant={openTable ? 'rotate' : null} icon='arrow_down' />
                    </div>
                  }
                </div>
              </a>
            </div>
          )
        }

        return (
          <div key={keyTable} className={collapseBlock(item)} id={tableId}>
            <div className={tableClassName}>
              <div className={style['table-row']}>
                {colsKeys.map((colName, colIdx) => {
                  const colKey = `body-col-${colIdx}`
                  return (
                    <div key={colKey} className={style['table-col']}>{item[colName] || '-'}</div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Table
