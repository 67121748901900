import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { useStoreon } from 'storeon/react'
import uuid from 'uuid/dist/v4'
import OrderViews from '../../views/OrderViews'
import api from '../../api'
import { DIRECTIONS, STATUSES_STATE, STATUSES_ID, MODALS } from '../../const'
import { filterFormSchema } from '../../utils/SchemasFormik'
import { handleRequestError, isRoleJuniorUW } from '../../utils'
import { useModal } from '../../hooks'

const OrderList = ({ orderListHead }) => {
  const [statusesList, setStatusesList] = useState([])
  const [contestTypesList, setContestTypesList] = useState([])
  const [orderListBody, setOrdersList] = useState([])
  const [sortDirection, setSortDirection] = useState(DIRECTIONS.asc)
  const [fieldToSort, setFieldToSort] = useState('')
  const [filterParams, setFilterParams] = useState({})
  const [isFetching, setIsFetching] = useState(false)
  const { currentUser } = useStoreon('currentUser')
  const navigate = useNavigate()
  const mounted = useRef()
  const { closeModal, openModal } = useModal()

  const {
    getOrdersList,
    getAllStatuses,
    getOrderContestTypesList,
  } = api.ordersApi

  const maxValue = useMemo(() => {
    const ID_OF_SUM_OBJ = 3
    const numbers = orderListBody.map(orderItem => {
      const orderQ = orderItem.cells.find(orderCell => orderCell.id === ID_OF_SUM_OBJ)
      return Number(+orderQ.data.replace(/\s/g, '')) || 0
    })
    return numbers.length > 0 ? Math.max(...numbers) : 0
  }, [orderListBody])

  const filtersErrorMesenge = {
    outOfRange: 'Вне диапазона (от 2000 до текущего года)',
    incorrectValues: 'Некорректно введены данные'
  }

  const initialFormValues = {
    state: '',
    contest__type: '',
    guarantySumMin: 0,
    guarantySumMax: maxValue,
    yearMin: '',
    yearMax: '',
  }

  const isGuarantySumDefaultValues = (guarantySumMin, guarantySumMax) => {
    return (guarantySumMin === initialFormValues.guarantySumMin) && (guarantySumMax === initialFormValues.guarantySumMax)
  }

  const convertToSelectItems = (data, type) => {
    switch (type) {
      case 'status':
        return data.states.map(item => ({
          id: uuid(),
          value: item,
          name: STATUSES_ID[item]
        }));
      case 'contest':
        return data.map(({ id, fz }) => ({
          id,
          value: id,
          name: fz
        }));
      default:
        return null;
    }
  }

  const requestAndConvertOrders = (params = {}) => {
    setIsFetching(true)
    return getOrdersList(params)
      .then(data => setOrdersList(data))
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }

  const sortOrdersList = (field) => {
    setFieldToSort(field)
    setSortDirection(sortDirection === DIRECTIONS.asc ? DIRECTIONS.desc : DIRECTIONS.asc)
  }

  const modifiedOrderListHead = orderListHead.map(item => {
    return {
      ...item,
      cells: item.cells.map(cell => {
        return {
          ...cell,
          onClick: () => sortOrdersList(cell.sortName)
        }
      })
    }
  })

  const filterOrdersList = ({ yearMin, yearMax, guarantySumMin, guarantySumMax, state, ...data }) => {
    setFilterParams({
      ...data,
      state: state || STATUSES_STATE.UNDERWRITING_A_NEW_APPLICATION,
      year: (!yearMin && !yearMax) ?
        undefined
        :
        `${yearMin || 1},${yearMax || new Date().getFullYear()}`,
      sum: isGuarantySumDefaultValues(guarantySumMin, guarantySumMax) ?
        undefined
        :
        `${guarantySumMin},${guarantySumMax}`,
    })
    closeModal()
  }

  const formik = useFormik({
    validationSchema: filterFormSchema(filtersErrorMesenge),
    initialValues: initialFormValues,
    onSubmit: filterOrdersList,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
  })

  const handleOpenModal = () => openModal(MODALS.orderListFilterModal, {
    formik,
    statusesList,
    contestTypesList,
    resetForm: () => {
      formik.resetForm()
      setFilterParams({})
    },
    minValueBetween: 0,
    maxValue,
    isRendered: true,
  })

  useEffect(() => {
    const filterNewOrder = { state: STATUSES_STATE.UNDERWRITING_A_NEW_APPLICATION }
    const params = isRoleJuniorUW(currentUser) ?
      { ...filterNewOrder, underwriter: currentUser.id }
      :
      filterNewOrder
    Promise.all([getAllStatuses(), getOrderContestTypesList(), requestAndConvertOrders(params)])
      .then(([statusesData, contestTypesData]) => {
        setStatusesList(convertToSelectItems(statusesData, 'status'))
        setContestTypesList(convertToSelectItems(contestTypesData, 'contest'))
      })
      .catch(err => handleRequestError(err, navigate))
  }, [])

  useEffect(() => {
    const filterNewOrder = { state: STATUSES_STATE.UNDERWRITING_A_NEW_APPLICATION }
    const params = isRoleJuniorUW(currentUser) ?
      { ...filterNewOrder, underwriter: currentUser.id }
      :
      filterNewOrder
    if (!mounted.current) {
      mounted.current = true
    } else {
      requestAndConvertOrders({
        underwriter: isRoleJuniorUW(currentUser) ? currentUser.id : undefined,
        ordering: sortDirection === DIRECTIONS.asc ?
          fieldToSort
          :
          `-${fieldToSort}`,
        ...params,
        ...filterParams,
      })
    }
  }, [sortDirection, fieldToSort, filterParams])

  return (
    <OrderViews.List
      tableHead={modifiedOrderListHead}
      tableBody={orderListBody}
      sortDirection={sortDirection}
      toggleModal={handleOpenModal}
      fieldToSort={fieldToSort}
      isFetching={isFetching}
    />
  )
}

export default OrderList
