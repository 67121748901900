import React from 'react';
import classNames from 'classnames';
import Title from '../Title';
import InputAccept from '../InputAccept';
import style from './styles/index.module.scss';

// WIP.
// Структура принимаемых данных.
// [ // Массив табличных строк.
//   { // Объект отдельной строки.
//     id // Id строки. (num)
//     inputAttr { // Может ли быть отредактированной. Принимает массив атрибутов или "false". (arr, bool).
//       name: value
//       итд.
//     }
//     hasTooltip // Имеет подсказку. Принимает строку с подсказкой или "false". (str, bool).
//     data { // Данные.
//       key // Ключ данных. (str).        
//       value // Значение данных. Если "isEditable" не является "false", то становится "value" для инпута. (str).
//     }
//   }
//   итд.
// ]

const ParamCard = ({
  dataInput = [], // Входные данные.
  title, // Заголовок карточки (необяз.).
  titleLevel = '3', // Уровень заголовка карточки (необяз.).
  className,
  ...props
}) => {
  return (
    <div className={classNames({
      [style.container]: true,
      [className]: !!className
    })} {...props}>
      {title &&
        <Title
          className={style.title}
          level={titleLevel}
          projectVariant='title_section'
        >
          {title}
        </Title>
      }
      <ul className={style.list}>
        {dataInput.map(row => {
          return (
            <li
              className={style.list__item}
              key={row.id}
            >
              <span className={style.list__key}>{row.data.key}</span>
              <span className={style.list__value}>
                {row.inputAttr ?
                  <InputAccept
                    {...row.inputAttr}
                    defaultValue={row.data.value}
                  />
                  :
                  (<span>{row.data.value}</span>)
                }
              </span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ParamCard;