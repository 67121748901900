import React from 'react';
import Layout from '../views';
import OrderScoring from '../components/OrderScoring';

const OrderScoringPage = (props) => {
  return (
    <Layout {...props}>
      <OrderScoring />
    </Layout>
  )
}

export default OrderScoringPage;