import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStoreon } from 'storeon/react'
import api from '../../api'
import { PATHS } from '../../const'
import { handleRequestError } from '../../utils'
import HeaderView from '../../views/Header'

const Header = ({
  links = []
}) => {
  const { dispatch } = useStoreon()
  const [searchValue, setSearchValue] = useState('')
  const [isChatActive, setIsChatActive] = useState(false)
  const [isBellActive, setIsBellActive] = useState(false)
  const navigate = useNavigate()
  const { currentUser } = useStoreon('currentUser')

  const onSearchValueChange = () => {
    setSearchValue()
  }

  const onBellClick = () => {
    api.usersApi.logout({ email: currentUser?.email })
      .then(() => {
        dispatch('user/save', null)
        navigate(PATHS.AUTHORIZATION.path)
      })
      .catch(err => handleRequestError(err, navigate))
  }

  useEffect(() => {
    // Чтобы линт не ругался на неиспользование оставлю пока тут
    setIsChatActive(false)
    setIsBellActive(false)
  }, [])

  return <HeaderView
    isChatActive={isChatActive}
    isBellActive={isBellActive}
    logoLabel='Банковские гарантии'
    searchPlaceholder='Поиск'
    searchValue={searchValue}
    onSearchValueChange={onSearchValueChange}
    onBellClick={onBellClick}
    links={links}
  />
}

export default Header