import uuid4 from 'uuid/dist/v4'
import { formatPercentages, numberWithSpaces } from '../../../utils'

export const serializeTradeCards = (data, customValues) => {
  return [
    [
      {
        id: uuid4(),
        data: {
          key: 'Ставка комиссии банка, % годовых',
          value: formatPercentages(customValues?.bank_rate ?? data?.bank_rate, true)
        },
        inputAttr: {
          name: 'bank_rate'
        },
      },
      {
        id: uuid4(),
        data: {
          key: 'Сумма комиссии банка, руб',
          value: numberWithSpaces(customValues?.bank_sum ?? data?.bank_sum, true)
        }
      },
      {
        id: uuid4(),
        data: {
          key: 'Доля в комиссии по гарантии, %',
          value: formatPercentages(customValues?.bank_commission ?? data?.bank_commission, true)
        }
      }
    ],
    [
      {
        id: uuid4(),
        data: {
          key: 'Ставка страховой премии',
          value: formatPercentages(customValues?.insurance_premium_rate ?? data?.insurance_premium_rate, true)
        },
        inputAttr: {
          name: 'insurance_premium_rate'
        },
      },
      {
        id: uuid4(),
        data: {
          key: 'Сумма страховой премии, руб',
          value: numberWithSpaces(customValues?.insurance_premium_sum ?? data?.insurance_premium_sum, true)
        }
      },
      {
        id: uuid4(),
        data: {
          key: 'Доля в комиссии по гарантии, %',
          value: formatPercentages(customValues?.insurance_premium_commission ?? data?.insurance_premium_commission, true)
        }
      }
    ],
    [
      {
        id: uuid4(),
        data: {
          key: 'Ставка комиссии мастер-агента, % годовых',
          value: formatPercentages(customValues?.master_agent_rate ?? data?.master_agent_rate, true)
        },
      },
      {
        id: uuid4(),
        data: {
          key: 'Сумма комиссии мастер-агента, руб',
          value: numberWithSpaces(customValues?.master_agent_sum ?? data?.master_agent_sum, true)
        }
      },
      {
        id: uuid4(),
        data: {
          key: 'Доля в комиссии по гарантии, %',
          value: formatPercentages(customValues?.master_agent_commission ?? data?.master_agent_commission, true)
        },
        inputAttr: {
          name: 'master_agent_commission'
        },
      }
    ],
    [
      {
        id: uuid4(),
        data: {
          key: 'Ставка комиссии агента, % годовых',
          value: formatPercentages(customValues?.agent_rate ?? data?.agent_rate, true)
        },
      },
      {
        id: uuid4(),
        data: {
          key: 'Сумма комиссии агента, руб',
          value: numberWithSpaces(customValues?.agent_sum ?? data?.agent_sum, true)
        }
      },
      {
        id: uuid4(),
        data: {
          key: 'Доля в комиссии по гарантии, %',
          value: formatPercentages(customValues?.agent_commission ?? data?.agent_commission, true)
        },
        inputAttr: {
          name: 'agent_commission'
        },
      }
    ]
  ]
}

export default serializeTradeCards