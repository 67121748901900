import React from 'react'
import { Route, Routes } from 'react-router-dom'
import uuid4 from 'uuid/dist/v4'
import { PortfolioPage } from '../../pages'
import { PATHS } from '../../const'
import { caseIcon, documentIcon2, documentCheck } from '../../images'


const orderListHead = [
  {
    id: uuid4(),
    cells: [
      {
        id: uuid4(),
        data: 'Номер',
        sortName: 'id',
      },
      {
        id: uuid4(),
        data: 'Принципал',
        sortName: 'principal',
      },
      {
        id: uuid4(),
        data: 'Сумма гарантии',
        sortName: 'sum',
      },
      {
        id: uuid4(),
        data: 'Вид',
        sortName: 'contest__type',
      },
      {
        id: uuid4(),
        data: 'Статус',
        sortName: 'state',
      },
      {
        id: uuid4(),
        data: 'UW',
        sortName: 'underwriter',
      },
      {
        id: uuid4(),
        data: 'Дата',
        sortName: 'start_date',
      },
    ]
  }
]
const asideLinks = [
  {
    id: uuid4(),
    path: PATHS.PORTFOLIO.link,
    icon: caseIcon,
    exact: true,
    name: 'Общий портфель',
  },
  {
    id: uuid4(),
    path: PATHS.PORTFOLIO_ORDERS.link,
    icon: documentIcon2,
    name: 'Заявки',
  },
  {
    id: uuid4(),
    path: PATHS.PORTFOLIO_GUARANTIES.link,
    icon: documentCheck,
    name: 'Гарантии',
  },
]

const PortfolioAll = () => <PortfolioPage pageTitle='Общий портфель' orderListHead={orderListHead} asideLinks={asideLinks} />
const PortfolioOrders = () => <PortfolioPage pageTitle='Заявки' orderListHead={orderListHead} asideLinks={asideLinks} portfolioSection='orders' />
const PortfolioGuaranties = () => <PortfolioPage pageTitle='Гарантии' orderListHead={orderListHead} asideLinks={asideLinks} portfolioSection='guaranties' />

const PortfolioRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.PORTFOLIO_ORDERS.path} element={<PortfolioOrders />} />
      <Route path={PATHS.PORTFOLIO_GUARANTIES.path} element={<PortfolioGuaranties />} />
      <Route index element={<PortfolioAll />} />
    </Routes>
  )
}

export default PortfolioRoutes
