import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../api';
import { handleRequestError } from '../../utils';
import BankMatrixSerializer from './utils';
import BankViews from '../../views/BankViews';
import Title from '../../views/Title';
import SimpleTable from '../../views/SimpleTable';
import Preloader from '../../views/Preloader'
import style from './styles/index.module.scss';

const BankMatrix = () => {
  const [bank, setBank] = useState({});
  const [matrix, setMatrix] = useState({});
  const { bankId } = useParams();
  const [isFetching, setIsFetching] = useState(false)

  const { getBankById, getBankMatrix } = api.bankApi;
  const navigate = useNavigate()

  useEffect(() => {
    setIsFetching(true)
    Promise.all([
      getBankById(bankId),
      getBankMatrix({ bank: bankId }),
    ])
      .then(([dataBank, dataMatrix]) => {
        setBank(dataBank);
        setMatrix(BankMatrixSerializer(dataMatrix));
      })
      .catch((err) => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  return (
    <>
      <BankViews.Header bank={bank} />
      <Title
        level='1'
        projectVariant='title_section'
      >
        Матрица тарифов
      </Title>
      {isFetching ?
        <Preloader />
        :
        <SimpleTable
          className={style.table}
          tableHead={matrix.tableHead}
          tableBody={matrix.tableBody}
        />
      }
    </>
  )
}

export default BankMatrix;