import Header from './Header';
import List from './List';
import Progressbar from './Progressbar';
import PurchaseInfo from './PurchaseInfo';
import PurchaseShortInfo from './PurchaseShortInfo';
import QueryIndicators from './QueryIndicators';
import QuotationForm from './QuotationForm';
import Summery from './Summery';
import Rating from './Rating';
import ScoringOW from './ScoringOW';
import Diagram from './Diagram';
import DocumentListChecking from './DocumentListChecking';
import QuotationBankList from './QuotationBankList';
import History from './History';
import CurrentQuotation from './CurrentQuotation';

export default {
  Header,
  List,
  Progressbar,
  PurchaseInfo,
  PurchaseShortInfo,
  QueryIndicators,
  QuotationForm,
  Summery,
  Rating,
  ScoringOW,
  Diagram,
  DocumentListChecking,
  QuotationBankList,
  History,
  CurrentQuotation,
}