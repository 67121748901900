import React from 'react';
import classNames from 'classnames';
import Title from '../../Title';
import ButtonIcon from '../../ButtonIcon';
import { userNoun } from '../../../images';
import { formatPercentages, numberWithSpaces } from '../../../utils'
import style from './styles/index.module.scss';

const CurrentQuotation = ({
  handleUserNounClick,
  isChangeUWBtnDisabled,
  quoteData,
  className,
  ...props
}) => {
  return (
    <section
      className={classNames({
        [style.container]: true,
        [className]: !!className
      })}
      {...props}
    >
      <Title
        className={style.title}
        level='2'
        projectVariant='title_section'
      >
        Котировка
      </Title>
      <div className={style.quote}>
        <div className={style.quote__imgWrap}>
          <img
            className={style.quote__img}
            src={quoteData?.quote?.bank.logo}
            alt={quoteData?.quote?.bank.title}
          />
        </div>
        <span className={style.quote__bankName}>{quoteData?.quote?.bank.title}</span>
        <div className={style.quoteBlock}>
          <span className={style.quoteBlock__key}>Котировка</span>
          <span className={style.quoteBlock__value}>
            {quoteData?.quote?.guarantee_rate ? `${formatPercentages(quoteData?.quote?.guarantee_rate, true)}%` : '-'}
          </span>
        </div>
        <div className={style.quoteBlock}>
          <span className={style.quoteBlock__key}>Сумма комиссии банка, руб.</span>
          <span className={style.quoteBlock__value}>{numberWithSpaces(quoteData?.quote?.bank_commission, true)}</span>
        </div>
        <div className={style.quoteBlock}>
          <span className={style.quoteBlock__key}>Реестровый номер</span>
          <span className={style.quoteBlock__value}>{quoteData?.quote?.registration_number || '-'}</span>
        </div>
      </div>
      <ButtonIcon
        className={style.setUW}
        iconSrc={userNoun}
        ariaLabel='Назначить андерайтера'
        onClick={handleUserNounClick}
        disabled={isChangeUWBtnDisabled()}
      />
    </section>
  )
}

export default CurrentQuotation;