// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_table__1DAr9 {\n  width: max-content;\n  min-width: 100%;\n}\n.styles_table__1DAr9 th, .styles_table__1DAr9 td {\n  min-width: max-content;\n}", "",{"version":3,"sources":["webpack://src/components/BankMatrix/styles/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;AACF;AAAE;EAEE,sBAAA;AACJ","sourcesContent":[".table {\n  width: max-content;\n  min-width: 100%;\n  & th,\n  & td {\n    min-width: max-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "styles_table__1DAr9"
};
export default ___CSS_LOADER_EXPORT___;
