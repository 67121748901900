import React from 'react'
import PrincipalRating from '../components/PrincipalRating'
import Layout from '../views'

const PrincipalRatingPage = (props) => {
  return (
    <Layout {...props}>
      <PrincipalRating />
    </Layout>
  )
}

export default PrincipalRatingPage
