import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Formik } from 'formik';
import { GxForm } from '@garpix/garpix-web-components-react';
import Input from '../../Input';
import Textarea from '../../Textarea';
import Button from '../../Button';
import Title from '../../Title';
import Select from '../../Select';
import { arrowDown } from '../../../images';
import ErrorField from '../../ErrorField';
import { blackListSchema } from '../../../utils/SchemasFormik'
import style from './styles/index.module.scss';

const Blacklist = ({
  blackList,
  handleCreate,
  handleDelete,
  className,
  ...props
}) => {
  const [isDisplay, setIsDisplay] = useState(false);
  const [statuses, setStatuses] = useState([]);

  // useEffect(() => {
  //   const newStatuses = blackList.map(item => ({
  //     id: item.id,
  //     value: 'in-list',
  //   }));

  //   setStatuses(newStatuses);
  // }, [blackList])

  const initialValues = {
    title: '',
    inn: '',
    comment: '',
  }
  const statusesList = [
    { value: 'in-list', name: 'Включен в ЧС' },
    { value: 'out-list', name: 'Исключен из ЧС' },
  ];
  const handleChangeStatus = (e, id) => {
    setStatuses(prev => prev.map(st => st.id === id ? { id, value: e.target.value } : st));
    if (e.target.value === 'out-list') {
      setTimeout(() => handleDelete(id), 500)
    }
  };

  useEffect(() => {
    const newStatuses = blackList.map(item => ({
      id: item.id,
      value: 'in-list',
    }));
    setStatuses(newStatuses);
  }, [blackList])

  return (
    <section
      className={classNames({
        [style.container]: true,
        [className]: !!className
      })}
      {...props}
    >
      <header className={style.heading}>
        <Title
          level='2'
          projectVariant='title_section'
        >
          Черный список
        </Title>
        <Button
          className={style.openAddToBlacklist}
          projectVariant='button_transparent'
          type='button'
          onClick={() => { setIsDisplay(!isDisplay); }}
        >
          <span>Добавить в ЧС</span>
          <img
            className={classNames(style.openAddToBlacklistIcon, { [style.opened]: isDisplay })}
            slot='icon-right'
            src={arrowDown}
            alt='arrow-icon'
          />
        </Button>
      </header>
      <Formik
        onSubmit={handleCreate}
        initialValues={initialValues}
        validationSchema={blackListSchema()}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
        }) => (
          <section className={classNames(
            style.section,
            { [style.opened]: isDisplay }
          )}>
            <GxForm
              className={style.form}
              onGx-submit={handleSubmit}
            >
              <Input
                type='text'
                onGx-input={handleChange}
                value={values.inn}
                name='inn'
                placeholder='Введине ИНН'
                helpText={<ErrorField
                  slot='help-text'
                  message={touched.inn && errors.inn}
                />}
              />
              <Input
                className={style.name}
                onGx-input={handleChange}
                value={values.title}
                name='title'
                type='text'
                placeholder='Название'
                helpText={<ErrorField
                  slot='help-text'
                  message={touched.title && errors.title}
                />}
              />
              <Textarea
                className={style.comment}
                value={values.comment}
                name='comment'
                onGx-input={handleChange}
                rows='1'
                placeholder='Комментарий'
                helpText={<ErrorField
                  slot='help-text'
                  message={touched.comment && errors.comment}
                />}
              />
              <Button
                className={style.submit}
                type='submit'
              >
                Добавить
              </Button>
            </GxForm>
          </section>
        )}
      </Formik>
      <div className={style.table}>
        <ul className={style.tableHeading}>
          <li />
          <li>ИНН</li>
          <li>Причина блокировки</li>
          <li>Статус</li>
        </ul>
        <ul className={style.tableBody}>
          {blackList.map((item) => {
            const status = statuses.find(st => st.id === item.id);
            return (
              <li key={item.id}>
                <span>{item.title}</span>
                <span>{item.inn}</span>
                <span>{item.comment}</span>
                <Select
                  value={status?.value}
                  onGx-change={(e) => handleChangeStatus(e, item.id)}
                  className={style.status}
                  list={statusesList}
                  placeholder='Не выбрано'
                />
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default Blacklist;