import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { GxIcon } from '@garpix/garpix-web-components-react';
import { PATHS, DIRECTIONS, MODALS } from '../../const';
import { getStatusColor } from '../../utils';
import Button from '../Button';
import { arrowDown, arrowUp } from '../../images'
import { useModal } from '../../hooks'
import style from './styles/index.module.scss';

const directionIcons = {
  [DIRECTIONS.asc]: arrowDown,
  [DIRECTIONS.desc]: arrowUp,
}

const OrdersTable = ({
  className,
  tableHead = [],
  tableBody = [],
  sortDirection,
  fieldToSort,
  ...props
}) => {
  const { openModal } = useModal()

  const openStatusModal = (title, status) => {
    openModal(MODALS.orderStatusModal, { data: { title, status } })
  }

  const [insight, setInsight] = useState(false);
  let timer = false;
  const wrapperRef = useRef();
  const tableRef = useRef();
  const main = document.querySelector('main');
  const coordX = window.innerWidth - window.innerWidth / 3;
  const coordY = window.innerHeight / 2;
  const checkConditions = () => {
    if (document.elementsFromPoint(coordX, coordY).includes(wrapperRef.current)) {
      if (
        tableRef.current.scrollWidth > tableRef.current.clientWidth
        &&
        tableRef.current.scrollHeight > window.innerHeight / 2
      ) {
        setInsight(true);
      } else {
        setInsight(false);
      }
    } else {
      setInsight(false);
    }
  }
  const checkConditionsWithDelay = () => {
    if (!timer) {
      timer = true;
      setTimeout(() => { checkConditions(); timer = false; }, 200)
    }
  }
  const scrollLeft = () => {
    tableRef.current.scrollLeft -= 100;
  }
  const scrollRight = () => {
    tableRef.current.scrollLeft += 100;
  }

  window.addEventListener('resize', checkConditionsWithDelay);

  useEffect(() => {
    checkConditions();
  }, [insight])

  useEffect(() => {
    if (main) {
      main.addEventListener('scroll', checkConditionsWithDelay);
    }
  }, [main])

  return (
    <div
      className={style.containerWrapper}
      ref={wrapperRef}
    >
      <div
        className={style.container}
        ref={tableRef}
      >
        <table
          className={classNames({
            [style.table]: true,
            [className]: !!className,
          })}
          {...props}
        >
          {tableHead.length ?
            <thead>
              {tableHead.map((tr) => {
                return (
                  <tr key={tr.id}>
                    {tr.cells.map((th) => (
                      <th key={th.id}>
                        <p className={style.tableHead}>
                          {th.data}
                          {th.sortName &&
                            <button
                              onClick={th.onClick}
                              type='button'
                            >
                              <img
                                src={fieldToSort === th.sortName ? directionIcons[sortDirection] : arrowDown}
                                alt='sort'
                              />
                            </button>
                          }
                        </p>
                      </th>
                    ))}
                  </tr>
                )
              })}
            </thead>
            : null
          }
          {tableBody ?
            <tbody>
              {tableBody.map((tr) => {
                return (
                  <tr key={tr.id}>
                    {tr.cells.map((td) => {
                      if (td.isLink) return (
                        <td key={td.id}>
                          <NavLink to={PATHS.ORDER_UNDERWRITING.link + tr.id}>
                            {td.data}
                          </NavLink>
                        </td>
                      )
                      if (td.isStatus) return (
                        <td key={td.id}>
                          <button
                            className={classNames([
                              style.btnStatus,
                              style[getStatusColor(td.data)]
                            ])}
                            type='button'
                            onClick={() => { openStatusModal(tr.cells[0].data, td.data) }}
                          >
                            {td.data}
                          </button>
                        </td>
                      )
                      return (
                        <td key={td.id}>{td.data}</td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
            : null
          }
        </table>
      </div>
      <div className={classNames(
        style.controls,
        { [style.insight]: insight }
      )}>
        <Button
          className={style.btnLeft}
          onClick={scrollLeft}
        >
          <GxIcon src={arrowDown} slot='icon-left' />
        </Button>
        <Button
          className={style.btnRight}
          onClick={scrollRight}
        >
          <GxIcon src={arrowDown} slot='icon-left' />
        </Button>
      </div>
    </div>
  )
}

export default OrdersTable;
