import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import uuid4 from 'uuid/dist/v4';
import { PATHS } from '../../const';
import {
  BlacklistPage,
  HandbookStopFactorsPage,
  HandbookPresignalsPage
} from '../../pages';
import { userNotAllowed, forbidden, alert } from '../../images';

const asideLinks = [
  {
    id: uuid4(),
    path: PATHS.HANDBOOK_BLACKLIST.link,
    icon: userNotAllowed,
    name: 'Черный список',
  },
  {
    id: uuid4(),
    path: PATHS.HANDBOOK_STOPFACTORS.link,
    icon: forbidden,
    name: 'Справочник стоп-факторов',
  },
  {
    id: uuid4(),
    path: PATHS.HANDBOOK_PRESIGNALS.link,
    icon: alert,
    name: 'Справочник предсигналов',
  }
];

const Blacklist = () => <BlacklistPage asideLinks={asideLinks} />
const HandbookStopFactors = () => <HandbookStopFactorsPage asideLinks={asideLinks} />
const HandbookPresignals = () => <HandbookPresignalsPage asideLinks={asideLinks} />

const HandbooksRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.HANDBOOK_BLACKLIST.path} element={<Blacklist />} />
      <Route path={PATHS.HANDBOOK_STOPFACTORS.path} element={<HandbookStopFactors />} />
      <Route path={PATHS.HANDBOOK_PRESIGNALS.path} element={<HandbookPresignals />} />
      <Route index element={<Navigate to={PATHS.HANDBOOK_BLACKLIST.link} />} />
    </Routes>
  )
}

export default HandbooksRoutes;
