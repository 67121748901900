import React from 'react';
import { GxIcon } from '@garpix/garpix-web-components-react';
import { NavLink } from 'react-router-dom';
import { logo } from '../../images';
import { PATHS } from '../../const';
import styles from './styles/index.module.scss';

const Logo = ({
  label,
  className,
  ...props
}) => {
  return (
    <NavLink
      className={className}
      to={PATHS.HOME.link}
      {...props}
    >
      <GxIcon
        className={styles.logo}
        src={logo}
        label={label}
      />
    </NavLink>
  )
}

export default Logo;