import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { handleRequestError } from '../../utils';
import api from '../../api';
import Preloader from '../../views/Preloader'
import BankViews from '../../views/BankViews';

const BankDocuments = () => {
  const [bank, setBank] = useState({});
  const [sections, setSections] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const {
    getBankById,
    getBankSections,
  } = api.bankApi;
  const { bankId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setIsFetching(true)
    Promise.all([getBankById(bankId), getBankSections()])
      .then(([dataBank, dataSections]) => {
        setBank(dataBank)
        setSections(dataSections)
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  return (
    <>
      <BankViews.Header bank={bank} />
      {isFetching ?
        <Preloader />
        :
        sections.map(section => (
          <>
            {section.document.length > 0 &&
              <BankViews.DocumentsList
                title={section.title}
                documents={section.document}
              />
            }
          </>
        ))
      }
    </>
  )
}

export default BankDocuments;