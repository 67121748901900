import React from 'react';
import classNames from 'classnames';
import style from './styles/Company.module.scss';
import InputAccept from '../../InputAccept';

const Company = ({
  className,
  ...props
}) => {
  return (
    <div
      className={classNames({
        [style.container]: true,
        [className]: !!className
      })}
      {...props}
    >
      <div className={style.info}>
        <span className={style.companyName}>ФКУ УПРДОР &#34;Кавказ&#34;</span>
        <div className={style.block}>
          <span className={style.key}>ИНН</span>
          <InputAccept
            value='263204647'
          />
        </div>
      </div>
      <hr className={style.separator} />
      <div className={style.block}>
        <span className={style.key}>Комментарий</span>
        <span className={style.value}>
          Приятно, граждане, наблюдать, как диаграммы
          связей объективно рассмотрены соответствующими
          инстанциями. Сложно сказать, почему стремящиеся.
        </span>
      </div>
    </div>
  )
}

export default Company;