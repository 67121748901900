import React from 'react';
import classNames from 'classnames';
import ParamCard from '../../ParamCard';
import style from './styles/index.module.scss';

const PurchaseInfo = ({
  purchaseOrder,
  className,
  ...props
}) => {
  return (
    <section
      className={classNames({
        [style.section]: true,
        [className]: !!className
      })}
      {...props}
    >
      <ul className={style.list}>
        {
          purchaseOrder?.firstColumn?.map(li => (
            <li>
              <ParamCard dataInput={li?.columnList} title={li?.title} />
            </li>
          ))
        }
      </ul>
      <ul className={style.list}>
        {
          purchaseOrder?.secondColumn?.map(li => (
            <li>
              <ParamCard dataInput={li?.columnList} title={li?.title} />
            </li>
          ))
        }
      </ul>
    </section>
  )
}

export default PurchaseInfo;