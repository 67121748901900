import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styles from './styles/index.module.scss'

const HeaderNav = ({ links = [] }) => {
  const location = useLocation()

  const linkActiveClassName = (navLink) => {
    const currentParentPath = location.pathname.split('/')[1]
    const isParentLinkActive = currentParentPath === navLink
    const isOrderPageAcitve = currentParentPath === 'order' && navLink === ''

    if (isParentLinkActive || isOrderPageAcitve) return `${styles.navList__link} ${styles.navList__link_active}`
    return styles.navList__link
  }

  return (
    <nav className={styles.nav}>
      <ul className={styles.navList}>
        {
          links.map(link => {
            const headerLink = link.path.split('/')[1]
            return (
              <li className={styles.navList__item} key={link.id}>
                <NavLink
                  className={linkActiveClassName(headerLink)}
                  to={link.path}
                  end={!!link.exact}
                >
                  {link.name}
                </NavLink>
              </li>
            )
          })
        }
      </ul>
    </nav>
  )
}

export default HeaderNav
