import React from 'react';
import MiscViews from '../../views/MiscViews';
import Title from '../../views/Title';

const Chat = () => {
  return (
    <MiscViews.FullHeightPageWrapper>
      <Title
        level='1'
        projectVariant='title_section'
      >
        Чат
      </Title>
      <MiscViews.Layout>
        <MiscViews.AsideChat />
        <MiscViews.Chat />
      </MiscViews.Layout>
    </MiscViews.FullHeightPageWrapper>
  )
}

export default Chat;