import React from 'react';
import { useNavigate } from 'react-router-dom';
import Title from '../../Title';
import Coowner from './Coowner';

import { handleRequestError } from '../../../utils';
import api from '../../../api';
import Button from '../../Button';

import style from './styles/index.module.scss';

const Coowners = ({
  className,
  ...props
}) => {
  // TODO: Удалить ErrorApi

  const navigate = useNavigate();
  const { getError } = api.errorApi;

  const handleError = () => {
    getError().then(() => {
      console.log('Не может такого быть')
    },
      err => {
        console.log(err);
        handleRequestError(err, navigate);
      })
  }

  return (
    <section
      className={className}
      {...props}
    >
      <Title
        className={style.title}
        level='2'
        projectVariant='title_section'
      >
        Совладельцы
      </Title>
      <ul className={style.list}>
        <li className={style.list__item}>
          <Coowner />
        </li>
        <li className={style.list__item}>
          <Coowner />
        </li>
      </ul>

      <Button onClick={handleError}>Тест ошибки</Button>
    </section>
  )
}

export default Coowners;