import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useModal } from '../../hooks'
import { getUserCertificates, getSystemInfo, createDetachedSignature, createHash } from './cryptopro';
import Button from '../../views/Button';
import { MODALS } from '../../const'

const cryptoProState = {
  notAsked: 'notAsked',
  loaded: 'loaded',
  failed: 'failed'
}

const CryptoProButton = ({
  formData = {},
  buttonProps = {},
  signatureCallback,
  children,
  handleChangeStatus,
}) => {
  const [state, setState] = useState(cryptoProState.notAsked);
  const [certificates, setCertificates] = useState([]);
  const [currentCertificate, setCurrentCertificate] = useState(null);
  const { closeModal, openModal, updateProps } = useModal()

  const handleSubmit = async () => {
    if (currentCertificate) {
      const message = JSON.stringify(formData);
      try {
        const messageHash = await createHash(message);
        try {
          const signature = await createDetachedSignature(currentCertificate, messageHash);
          signatureCallback({
            signature,
            certificate: certificates.find((item) => item.id === currentCertificate).data,
          });
          closeModal()
        } catch (signatureError) {
          console.log({ signatureError }, 'signatureError');
          toast.error(signatureError.message)
        }
      } catch (hashError) {
        console.log(hashError, 'hashError');
        toast.error(hashError.message)
      }
    }
  }

  const handleChangeCurrentCertificate = (e) => setCurrentCertificate(e.target.value)

  const handleClick = () => {
    handleChangeStatus()
    if (state === cryptoProState.loaded) {
      (async () => {
        try {
          const data = await getUserCertificates();
          const options = data.map(item => ({
            id: item.thumbprint,
            value: item.thumbprint,
            name: item.name,
            data: item,
          }))

          setCertificates(options);
          openModal(MODALS.cryptoProModal, {
            handleChangeCurrentCertificate,
            handleSubmit,
            currentCertificate,
            certificates: options,
          })
        } catch (error) {
          console.log(error, 'error')
        }
      })();
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const info = await getSystemInfo();
        console.log(info);
        setState(cryptoProState.loaded);
      } catch (error) {
        setState(cryptoProState.failed);
      }
    })();
  }, []);

  useEffect(() => {
    updateProps({ certificates, currentCertificate, handleSubmit })
  }, [certificates, currentCertificate])

  return (
    <div>
      <Button {...buttonProps} full disabled={state !== cryptoProState.loaded || buttonProps.disabled} onClick={handleClick}>
        {children}
      </Button>
      {state === cryptoProState.failed ? (
        <div>Плагин <a href='http://cpdn.cryptopro.ru/default.asp?url=content/cades/plugin-installation-windows.html' target='_blank' rel='noreferrer'>КриптоПро ЭЦП Browser plug-in</a> недоступен</div>
      ) : null}
    </div>
  )
}

export default CryptoProButton;