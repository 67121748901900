import React, { useState } from 'react';
import classNames from 'classnames';
import Input from '../Input';
import ButtonIcon from '../ButtonIcon';
import {
  pen,
  submit,
  cancel
} from '../../images';
import style from './styles/index.module.scss';

const InputAccept = ({
  position = 'left',
  defaultValue,
  handleAccept,
  textAlign = 'right',
  className,
  ...props
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [possibleValue, setPossibleValue] = useState(defaultValue);

  const getInputStyle = (state) => {
    switch (state) {
      case true:
        return 'input_default';
      case false:
        return 'input_transparent';
      default:
        return 'input_transparent';
    }
  }
  const getButtonIcon = (state) => {
    switch (state) {
      case true:
        return submit;
      case false:
        return pen;
      default:
        return pen;
    }
  }
  const handleInput = evt => {
    setPossibleValue(evt.target.value);
    setIsChanged(value !== evt.target.value);
  }
  const handleFocus = () => {
    setIsEdit(true);
  }
  const handleClick = () => {
    if (isEdit) {
      setValue(possibleValue);
      handleAccept(props.name, possibleValue);
    } else {
      setIsEdit(true);
    }
  }

  const handleReset = () => {
    setPossibleValue(value);
    setIsChanged(false);
    setIsEdit(false);
  }

  return (
    <div
      className={classNames(
        style.container,
        style[position],
        {
          [style.edit]: isEdit,
          [className]: !!className
        }
      )}
    >
      <Input
        className={classNames(
          style.input,
          { [style.changed]: isChanged }
        )}
        projectVariant={getInputStyle(isEdit)}
        onGx-focus={handleFocus}
        value={possibleValue}
        onGx-input={handleInput}
        textAlign={textAlign}
        {...props}
      />
      <ButtonIcon
        className={style.submit}
        iconSrc={getButtonIcon(isEdit)}
        onClick={handleClick}
      />
      {isEdit &&
        <ButtonIcon
          className={style.reset}
          iconSrc={cancel}
          onClick={handleReset}
        />
      }
    </div>
  )
}

export default InputAccept;