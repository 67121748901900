// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_logo__2W_2l {\n  --icon-width: 64px;\n  --icon-height: 21px;\n}", "",{"version":3,"sources":["webpack://src/views/Logo/styles/index.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,mBAAA;AADF","sourcesContent":["@import \"../../../styles/global/index.scss\";\n\n.logo {\n  --icon-width: 64px;\n  --icon-height: 21px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "styles_logo__2W_2l"
};
export default ___CSS_LOADER_EXPORT___;
