import uuid4 from 'uuid/dist/v4'
import { numberWithSpaces } from '../../../utils'

const limitsFzListSerializer = (res) => {
  return res.map(item => ({
    id: uuid4(),
    cells: [
      {
        id: uuid4(),
        data: item?.fz?.fz
      },
      {
        id: uuid4(),
        data: numberWithSpaces(item?.total_limit, true) || '-'
      },
      {
        id: uuid4(),
        data: numberWithSpaces(item?.free_balance, true) || '-'
      }
    ]
  }))
}

export default limitsFzListSerializer