import React from 'react';
import classNames from 'classnames';
import { GxIcon } from '@garpix/garpix-web-components-react';
import {
  arrowDown,
  checkCircle,
  cancel,
  clip,
  plus,
  download
} from '../../images';
import style from './styles/index.module.scss';

const Icon = ({
  icon = null,
  children = null,
  slot = '',
  variant = null,
  className = '',
  ...props
}) => {
  const ICON_SRCS = {
    arrow_down: arrowDown,
    cancel,
    check_circle: checkCircle,
    clip,
    plus,
    download
  }

  const classes = classNames(className, {
    [style.icon]: true,
    [style[`icon-${variant}`]]: variant
  })

  if (ICON_SRCS[icon]) {
    return (
      <GxIcon
        className={classes}
        src={ICON_SRCS[icon]}
        slot={slot}
        {...props}
      >
        {children}
      </GxIcon>
    )
  }
  return null
}

export default Icon