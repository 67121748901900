import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { GxIcon } from '@garpix/garpix-web-components-react';
import Button from '../Button';
import { arrowDown } from '../../images';
import style from './styles/index.module.scss';

// [ // Данные раздела (tableHead/tableBody). (arr).
//   { // Объект табличной строки. (obj).
//     id, // Id табличной строки. (num).
//     className // Класс для строки. (str).
//     attributes: { // Атрибуты. (arr).
//       name: value // Название и значение атрибута. (str).
//     }
//     cells: [ // Массив ячеек. (arr).
//       { // Объект ячейки. (obj).
//         id, // Id ячейки. (num).
//         className // Класс для строки. (str).
//         attributes: { // Атрибуты. (arr).
//           name: value // Название и значение атрибута. (str).
//         }
//         data // Контент ячейки. (str).
//       }
//     ]
//   }
// ]

const SimpleTable = ({
  tableHead = [],
  tableBody = [],
  className,
  ...props
}) => {
  const [insight, setInsight] = useState(false);
  let timer = false;
  const wrapperRef = useRef();
  const tableRef = useRef();
  const main = document.querySelector('main');
  const coordX = window.innerWidth - window.innerWidth / 3;
  const coordY = window.innerHeight / 2;
  const checkConditions = () => {
    if (document.elementsFromPoint(coordX, coordY).includes(wrapperRef.current)) {
      if (
        tableRef.current.scrollWidth > tableRef.current.clientWidth
        &&
        tableRef.current.scrollHeight > window.innerHeight / 2
      ) {
        setInsight(true);
      } else {
        setInsight(false);
      }
    } else {
      setInsight(false);
    }
  }
  const checkConditionsWithDelay = () => {
    if (!timer) {
      timer = true;
      setTimeout(() => { checkConditions(); timer = false; }, 200)
    }
  }
  const scrollLeft = () => {
    tableRef.current.scrollLeft -= 100;
  }
  const scrollRight = () => {
    tableRef.current.scrollLeft += 100;
  }

  window.addEventListener('resize', checkConditionsWithDelay);

  useEffect(() => {
    checkConditions();
  }, [insight])

  useEffect(() => {
    if (main) {
      main.addEventListener('scroll', checkConditionsWithDelay);
    }
  }, [main])

  return (
    <div
      className={style.containerWrapper}
      ref={wrapperRef}
    >
      <div
        className={style.container}
        ref={tableRef}
        {...props}
      >
        <table className={classNames({
          [style.table]: true,
          [className]: !!className
        })}>
          <thead>
            {tableHead?.map(row => {
              return (
                <tr
                  className={row.className}
                  key={row.id}
                  {...row.attributes}
                >
                  {row.cells.map(cell => {
                    return (
                      <th
                        className={classNames([style.headCell, cell.className])}
                        key={cell.id}
                        {...cell.attributes}
                      >
                        {cell.data}
                      </th>
                    )
                  })}
                </tr>
              )
            })}
          </thead>
          <tbody>
            {tableBody?.map(row => {
              return (
                <tr
                  className={row.className}
                  key={row.id}
                  {...row.attributes}
                >
                  {row.cells.map(cell => {
                    return (
                      <td
                        className={classNames([style.cell, cell.className])}
                        key={cell.id}
                        {...cell.attributes}
                      >
                        {cell.data}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className={classNames(
        style.controls,
        { [style.insight]: insight }
      )}>
        <Button
          className={style.btnLeft}
          onClick={scrollLeft}
        >
          <GxIcon src={arrowDown} slot='icon-left' />
        </Button>
        <Button
          className={style.btnRight}
          onClick={scrollRight}
        >
          <GxIcon src={arrowDown} slot='icon-left' />
        </Button>
      </div>
    </div>
  )
}

export default SimpleTable;