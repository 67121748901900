import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import OrderViews from '../../views/OrderViews';
import api from '../../api';
import { handleRequestError } from '../../utils';
import Preloader from '../../views/Preloader';

const OrderHistory = () => {
  const [order, setOrder] = useState({});
  const [logs, setLogs] = useState([]);
  const [isFetching, setIsFetching] = useState(false)
  const { getOrderById } = api.ordersApi;
  const { getChangelogs } = api.changelogApi;
  const { orderId } = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    setIsFetching(true)
    Promise.all([getOrderById(orderId), getChangelogs({ object_id: orderId })])
      .then(([orderData, logsdata]) => {
        setOrder(orderData)
        setLogs(logsdata)
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, []);

  return (
    <>
      <OrderViews.Header
        orderId={order.id}
        sum={order.sum}
        principal={order.principal}
        fz={order.contest?.fz?.fz}
        status={order.state}
      />
      {isFetching ?
        <Preloader />
        :
        <OrderViews.History logs={logs} />
      }
    </>
  )
}

export default OrderHistory;