import React, { useState } from 'react';
import classNames from 'classnames';
import Checkbox from '../../Checkbox';
import { cancel, checkCircle } from '../../../images';
import style from './styles/index.module.scss';

const SFPSCard = ({
  projectVariant,
  cardId,
  isChecked,
  patchRequest,
  setReadOnly = false,
  children,
  className,
  ...props
}) => {
  const [state, setState] = useState(isChecked);

  const patchState = () => {
    setState(!state);
    patchRequest({ value: !state }, cardId);
  }

  // eslint-disable-next-line consistent-return
  const getIcon = () => {
    // eslint-disable-next-line default-case
    switch (state) {
      case true: return checkCircle;
      case false: return cancel;
    }
  }

  return (
    <div
      className={classNames({
        [style.card]: true,
        [style[projectVariant]]: !!projectVariant,
        [className]: !!className
      })}
      {...props}
    >
      <p>{children}</p>
      {setReadOnly ?
        <img src={getIcon()} alt='' />
        :
        <Checkbox
          checked={state}
          onGx-change={patchState}
        />
      }
    </div>
  )
}

export default SFPSCard;