import React from 'react'
import classNames from 'classnames'
import Range from './Range'
import Input from '../Input'
import style from './styles/index.module.scss'

const DoubleRange = ({
  min = 0,
  max = 100,
  minValueBetween = 0,
  inputMin = min,
  inputMax = max,
  onInputMin,
  onInputMax,
  inputMinName,
  inputMaxName,
  setFieldValue,
  isRendered,
  className,
  ...props
}) => {
  const maxForMin = () => inputMax - minValueBetween
  const minForMax = () => inputMin + minValueBetween

  return (
    <div
      className={classNames({
        [style.container]: true,
        [className]: !!className
      })}
      {...props}
    >
      <div className={style.controls}>
        {isRendered &&
          <Range
            inputMin={inputMin}
            inputMax={inputMax}
            min={min}
            max={max}
            inputMinName={inputMinName}
            inputMaxName={inputMaxName}
            setFieldValue={setFieldValue}
          />
        }
      </div>
      <div className={style.indicators}>
        <Input
          className={classNames([
            style.input,
            style.inputMin
          ])}
          type='number'
          inputMode='numeric'
          onGx-input={onInputMin}
          value={inputMin}
          min={min}
          max={maxForMin()}
          name={inputMinName}
        />
        <Input
          className={classNames([
            style.input,
            style.inputMax
          ])}
          type='number'
          inputMode='numeric'
          onGx-input={onInputMax}
          value={inputMax}
          min={minForMax()}
          max={max}
          name={inputMaxName}
        />
      </div>
    </div>
  )
}

export default DoubleRange;