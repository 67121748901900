import React from 'react';
import classNames from 'classnames';
import style from './styles/Coowner.module.scss';

const Coowner = ({
  className,
  ...props
}) => {
  return (
    <div
      className={classNames({
        [style.container]: true,
        [className]: !!className
      })}
      {...props}
    >
      <span className={style.percent}>50%</span>
      <span className={style.name}>Иванов Иван Иванович</span>
      <div className={style.block}>
        <span className={style.block__key}>ИНН</span>
        <span className={style.block__value}>9876543210</span>
      </div>
    </div>
  )
}

export default Coowner;