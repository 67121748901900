import uuid4 from 'uuid/dist/v4';

const principalContactsSerializer = (resp) => {
  const data = [
    {
      id: uuid4(),
      data: {
        key: 'Полное наименование на русском языке',
        value: resp?.legal_entity?.full_name || '-'
      },
    },
    {
      id: uuid4(),
      data: {
        key: 'Адрес регистрации',
        value: resp?.legal_entity?.registration_address || '-'
      }
    },
    {
      id: uuid4(),
      data: {
        key: 'ЕИО',
        value: `${resp?.legal_entity?.fl?.last_name || ''} ${resp?.legal_entity?.fl?.first_name || ''} ${resp?.legal_entity?.fl?.patronymic || ''}`.trim() || '-'
      }
    },
    {
      id: uuid4(),
      data: {
        key: 'Электронная почта',
        value: resp?.legal_entity?.email || '-'
      }
    },
  ]

  return data;
}

const principalRequisitesSerializer = (resp) => {
  const data = [
    {
      id: uuid4(),
      data: {
        key: 'ИНН',
        value: resp?.legal_entity?.inn || '-'
      }
    },
    {
      id: uuid4(),
      data: {
        key: 'КПП',
        value: resp?.legal_entity?.kpp || '-'
      }
    },
    {
      id: uuid4(),
      data: {
        key: 'Дата регистрации',
        value: resp?.legal_entity?.registration_date ? new Date(resp?.legal_entity?.registration_date).toLocaleDateString() : '-'
      }
    },
    {
      id: uuid4(),
      data: {
        key: 'ОКВЭД (основные)',
        value: resp?.legal_entity?.okved || '-'
      }
    },
  ]

  return data;
}

export {
  principalContactsSerializer,
  principalRequisitesSerializer
}