import React from 'react';
import classNames from 'classnames';
import Textarea from '../../Textarea';
import Button from '../../Button';
import Message from './Message';
import style from './styles/index.module.scss';

const mock = [
  {
    dir: 'fromUs',
    text: 'Российская Федерация - Россия есть демократическое федеративное правовое государство с республиканской формой правления.'
  },
  {
    dir: 'toUs',
    text: 'Наименования Российская Федерация и Россия равнозначны.'
  },
  {
    dir: 'fromUs',
    text: 'Человек, его права и свободы являются высшей ценностью. Признание, соблюдение и защита прав и свобод человека и гражданина - обязанность государства.'
  },
  {
    dir: 'toUs',
    text: 'Носителем суверенитета и единственным источником власти в Российской Федерации является ее многонациональный народ.'
  },
  {
    dir: 'fromUs',
    text: 'Народ осуществляет свою власть непосредственно, а также через органы государственной власти и органы местного самоуправления.'
  },
  {
    dir: 'toUs',
    text: 'Высшим непосредственным выражением власти народа являются референдум и свободные выборы.'
  },
  {
    dir: 'fromUs',
    text: 'Никто не может присваивать власть в Российской Федерации. Захват власти или присвоение властных полномочий преследуются по федеральному закону.'
  },
  {
    dir: 'toUs',
    text: 'Суверенитет Российской Федерации распространяется на всю ее территорию.'
  },
  {
    dir: 'fromUs',
    text: 'Конституция Российской Федерации и федеральные законы имеют верховенство на всей территории Российской Федерации.'
  },
  {
    dir: 'toUs',
    text: 'Российская Федерация обеспечивает целостность и неприкосновенность своей территории.'
  },
]

const Chat = ({
  data = mock,
  className,
  ...props
}) => {
  return (
    <section
      className={classNames(
        [style.container],
        { [className]: !!className }
      )}
      {...props}
    >
      <div className={style.scrolling}>
        <ul className={style.messageList}>
          {data.map(message => (
            <li
              className={classNames(
                [style.messageList__item],
                [style[message.dir]]
              )}>
              <Message data={message.text} />
            </li>
          ))}
        </ul>
      </div>
      <footer className={style.footer}>
        <Textarea placeholder='Введите текст' />
        <Button
          type='submit'
          full
        >Отправить</Button>
      </footer>
    </section>
  )
}

export default Chat;