import Header from './Header';
import Indicators from './Indicators';
import Summery from './Summery';
import ValidationControls from './ValidationControls';
import Coowners from './Coowners';
import Relationship from './Relationship';

export default {
    Header,
    Indicators,
    Summery,
    ValidationControls,
    Coowners,
    Relationship,
}