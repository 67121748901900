import AbstractBaseApi from '../AbstractBaseApi';
import { stopFactorsSerializer, presignalsSerializer } from './serializers';

export default class HandbooksApi extends AbstractBaseApi {
  getStopFactorsList = async (params) => {
    const res = await this.get('order/factors/', params);
    return stopFactorsSerializer(res.data);
  }

  patchStopFactorsList = async (params, cardId) => {
    const res = await this.patch(`order/factors/${cardId}/`, params);
    return res.data;
  }

  getPresignalsList = async (params) => {
    const res = await this.get('order/factors/', params);
    return presignalsSerializer(res.data);
  }

  patchPresignalsList = async (params, cardId) => {
    const res = await this.patch(`order/factors/${cardId}/`, params);
    return res.data;
  }

  getHandbookBlackList = async (params = {}) => {
    const res = await this.get('handbook/black_list_item/', params);
    return res.data;
  }

  createBlackItem = async (params = {}) => {
    const res = await this.post('handbook/black_list_item/', params);
    return res.data;
  }

  updateBlackItem = async (id, params = {}) => {
    const res = await this.patch(`handbook/black_list_item/${id}/`, params);
    return res.data;
  }

  deleteBlackItem = async (id) => {
    const res = await this.delete(`handbook/black_list_item/${id}/`);
    return res.data;
  }
}