import React from 'react';
import Layout from '../views';
import PrincipalDocuments from '../components/PrincipalDocuments';

const PrincipalDocumentsPage = (props) => {
  return (
    <Layout {...props}>
      <PrincipalDocuments />
    </Layout>
  )
}

export default PrincipalDocumentsPage;