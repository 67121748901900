import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { useParams, useNavigate } from 'react-router-dom'
import PrincipalViews from '../../views/PrincipalViews'
import OrderViews from '../../views/OrderViews'
import CollapseTable from '../../views/CollapseTable'
import api from '../../api'
import { handleRequestError } from '../../utils'
import { ORDER_COMMENTS_PAGES } from '../../const'
import Preloader from '../../views/Preloader'

const PrincipalRating = () => {
  const [principal, setPrincipal] = useState({})
  const [rating, setRating] = useState({})
  const [comment, setComment] = useState('')
  const [checkedRating, setCheckedRating] = useState(0)
  const [isFetching, setIsFetching] = useState(false)

  const { postOrderComment, getComment } = api.ordersApi
  const { getLimits } = api.limitsApi
  const { getPrincipalById } = api.entityApi

  const { principalId } = useParams()
  const navigate = useNavigate()
  const orderId = localStorage.getItem('orderId');

  const initialValues = {
    content: comment,
  }

  const onSubmit = ({ content }) => {
    return postOrderComment({
      content,
      order: orderId,
      page: ORDER_COMMENTS_PAGES.PRINCIPAL_RATING
    })
  }

  useEffect(() => {
    setIsFetching(true)
    Promise.all([
      getPrincipalById(principalId),
      getLimits({ principal: principalId }),
      getComment({ order: orderId, page: ORDER_COMMENTS_PAGES.PRINCIPAL_RATING })
    ])
      .then(([principalData, [limitData], commentData]) => {
        setPrincipal(principalData)
        if (commentData.length) setComment(commentData[0].content)

        if (limitData) {
          setRating({
            totalRating: limitData.ball_rating_total,
            letter: limitData.letter_rating,
            financialIndicators: limitData.financial_indicators,
            workExp: limitData.work_exp,
            scoreBigContracts: limitData.score_big_contracts,
            scoreAllContracts: limitData.score_all_contracts,
            scoreYear: limitData.score_year,
            ownershipStructure: limitData.ownership_structure,
          })
        }
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, []);

  const testData = {
    key: 'Rating',
    cols: [
      { label: 'Показатель', key: 'score' },
      { label: 'Веc', key: 'weight' },
      { label: 'Балл', key: 'rating' },
    ],
    rows: [
      [
        { score: 'Финансовые показатели', weight: '50', rating: rating?.financialIndicators, },
      ],
      [
        { score: 'Опыт работы', weight: '40', rating: rating.workExp, },
        { score: 'Количество исполненных за последние 3 года контрактов стоимостью более, либо равной контракту, по которому требуется гарантия', weight: '25', rating: rating?.scoreBigContracts, },
        { score: 'Количество исполненных контрактов за последние 3 года', weight: '25', rating: rating?.scoreAllContracts, },
        { score: 'Срок работы Принципала в основном направлении деятельности (лет)', weight: '50', rating: rating?.scoreYear, },
      ],
      [
        { score: 'Структура владения', weight: '10', rating: rating?.ownershipStructure, },
      ],
      [
        { score: 'Дополнительные (корректирующие) факторы' },
      ],
    ]
  }

  const isInputReadOnly = () => comment !== '' && rating.ball_rating_update
  return (
    <>
      <PrincipalViews.Header principal={principal} />
      {isFetching ?
        <Preloader />
        :
        <>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
          >
            {({ handleSubmit, handleChange, values, }) => <OrderViews.Rating
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              values={values}
              checkedRating={checkedRating}
              isInputReadOnly={isInputReadOnly}
              setCheckedRating={setCheckedRating}
              {...rating}
            />}
          </Formik>
          <CollapseTable tableData={testData} />
        </>
      }
    </>
  )
}

export default PrincipalRating