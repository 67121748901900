import React from 'react'
import Button from '../Button'
import Modal from '../ModalCreator'
import Title from '../Title'
import Select from '../Select'
import style from './styles/cryptoPro.module.scss'

const CryptoProModal = ({
  handleChangeCurrentCertificate,
  handleSubmit,
  currentCertificate,
  certificates,
  isOpen,
}) => {
  return (
    <Modal
      className={style.container}
      projectVariant='modal_without-cross'
      isOpen={isOpen}
    >
      <div className={style['modal-body']}>
        <Title
          className={style.title}
          level='2'
          projectVariant='title_section'
        >
          Выбор сертификата
        </Title>
        <Select
          className={style.select}
          hoist
          list={certificates}
          onGx-change={handleChangeCurrentCertificate}
        />
        <Button
          disabled={currentCertificate === null}
          full
          onClick={handleSubmit}
        >
          Отправить
        </Button>
      </div>
    </Modal>
  )
}

export default CryptoProModal