import React from 'react'
import { Route, Routes } from 'react-router-dom'
import LimitsRoutes from './LimitsRoutes'
import OtherRoutes from './OtherRoutes/OtherRoutes'
import OrdersRoutes from './OrdersRoutes'
import HandbooksRoutes from './HandbooksRoutes'
import PrincipalRoutes from './PrincipalRoutes'
import BanksRoutes from './BanksRoutes'
import PortfolioRoutes from './PortfolioRoutes'

import { PATHS } from '../const'

const RootRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.ORDERS.path} element={<OrdersRoutes />} />
      <Route path={PATHS.LIMITS.path} element={<LimitsRoutes />} />
      <Route path={PATHS.HANDBOOKS.path} element={<HandbooksRoutes />} />
      <Route path={PATHS.PRINCIPAL.path} element={<PrincipalRoutes />} />
      <Route path={PATHS.BANKS.path} element={<BanksRoutes />} />
      <Route path={PATHS.PORTFOLIO.path} element={<PortfolioRoutes />} />
      <Route path={PATHS.HOME.path} element={<OtherRoutes />} />
    </Routes>
  )
}

export default RootRoutes
