import React from 'react';
import Layout from '../views';
import BankLimits from '../components/BankLimits';

const BankLimitsPage = (props) => {
  return (
    <Layout {...props}>
      <BankLimits />
    </Layout>
  )
}

export default BankLimitsPage;