import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { handleRequestError } from '../../utils';
import Title from '../../views/Title';
import BlacklistSection from '../../views/HandbookViews/BlacklistSection';
import Preloader from '../../views/Preloader'

const Blacklist = () => {
  const [list, setList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const {
    getHandbookBlackList,
    createBlackItem,
    deleteBlackItem,
  } = api.handbooksApi;
  const navigate = useNavigate()

  const handleCreate = (params, { setErrors }) => {
    return createBlackItem(params)
      .then(data => {
        if (data) setList([...list, data])
        toast.success('Успешно добавлено в черный список')
      })
      .catch(err => {
        handleRequestError(err, navigate)
        if (err.response) {
          setErrors(err?.response.data)
        }
      })
  };

  const handleDelete = (id) => {
    const handleDeleteBlackItem = () => deleteBlackItem(id)
    return toast.promise(handleDeleteBlackItem, {
      pending: 'Удаление...',
      success: 'Успешно удален из черного списка'
    })
      .then(() => {
        const filteredList = list.filter((item) => item.id !== id)
        return setList(filteredList)
      })
      .catch(err => handleRequestError(err, navigate))
  };

  useEffect(() => {
    setIsFetching(true)
    getHandbookBlackList()
      .then(data => setList(data))
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  return (
    <>
      <Title level='1' projectVariant='title_page'>Черный список</Title>
      {isFetching ?
        <Preloader />
        :
        <BlacklistSection
          blackList={list}
          handleCreate={handleCreate}
          handleDelete={handleDelete}
        />
      }
    </>
  )
}

export default Blacklist;
