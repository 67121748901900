import React from 'react';
import Layout from '../views';
import OrderDocuments from '../components/OrderDocuments';

const OrderDocumentsPage = (props) => {
  return (
    <Layout {...props}>
      <OrderDocuments />
    </Layout>
  )
}

export default OrderDocumentsPage;