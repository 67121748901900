import * as cryptopro from 'crypto-pro';

const isDev = process.env.REACT_APP_CRYPTO_PRO === 'DEV';

const fake = {
  getUserCertificates: async () => ([{ name: 'GARPIX', thumbprint: 'GARPIX' }]),
  getSystemInfo: async () => ({}),
  createDetachedSignature: async () => 'U2lnbmF0dXJl',
  createHash: async () => '',
}

const getUserCertificates = isDev ? fake.getUserCertificates : cryptopro.getUserCertificates;
const getSystemInfo = isDev ? fake.getSystemInfo : cryptopro.getSystemInfo;
const createDetachedSignature = isDev ? fake.createDetachedSignature : cryptopro.createDetachedSignature;
const createHash = isDev ? fake.createHash : cryptopro.createHash;

export {
  getUserCertificates,
  getSystemInfo,
  createDetachedSignature,
  createHash
}