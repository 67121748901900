import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import uuid4 from 'uuid/dist/v4'
import {
  LimitsBanksPage,
  LimitsFzPage,
  LimitsPrincipalPage
} from '../../pages'
import { bank, book, businessman } from '../../images'
import { PATHS } from '../../const'

const asideLinks = [
  {
    id: uuid4(),
    path: PATHS.LIMITS_BANKS.link,
    icon: bank,
    name: 'Банки',
  },
  {
    id: uuid4(),
    path: PATHS.LIMITS_FZ.link,
    icon: book,
    name: 'Виды',
  },
  {
    id: uuid4(),
    path: PATHS.LIMITS_PRINCIPALS.link,
    icon: businessman,
    name: 'Принципалы',
  },
]

const LimitsFz = () => <LimitsFzPage asideLinks={asideLinks} />
const LimitsPrincipal = () => <LimitsPrincipalPage asideLinks={asideLinks} />
const LimitsBanks = () => <LimitsBanksPage asideLinks={asideLinks} />

const LimitsRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.LIMITS_FZ.path} element={<LimitsFz />} />
      <Route path={PATHS.LIMITS_PRINCIPALS.path} element={<LimitsPrincipal />} />
      <Route path={PATHS.LIMITS_BANKS.path} element={<LimitsBanks />} />
      <Route index element={<Navigate to={PATHS.LIMITS_BANKS.link} />} />
    </Routes>
  )
}

export default LimitsRoutes