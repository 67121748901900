/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useFormik } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import api from '../../api';
import OrderViews from '../../views/OrderViews';
import Preloader from '../../views/Preloader';
import { useModal } from '../../hooks'
import { integrationCheckSerializer, orderDataForCrypto } from './utils';
import { MODALS, ORDER_COMMENTS_PAGES, QUOTE_STATUSES_PULL, STATUSES_ID, STATUSES_STATE } from '../../const';
import { handleRequestError, isRoleJuniorUW } from '../../utils';

const OrderUnderwriting = () => {
  const [order, setOrder] = useState({});
  const [finalQuote, setFinalQuote] = useState({});
  const [underwriters, setUnderwriters] = useState([]);
  const [underwriter, setUnderwriter] = useState();
  const [specConditions, setSpecConditionsData] = useState([]);
  const [specialConditionValue, setSpecialConditionValue] = useState();
  const [limit, setLimit] = useState('');
  const [principal, setPrincipal] = useState({});
  const [scoring, setScoring] = useState('');
  const [documents, setDocuments] = useState([]);
  const [currentQuotationState, setCurrentQuotationState] = useState(false);
  const [initialValues, setInitialValues] = useState({
    special_condition: specialConditionValue,
  })
  const [isFetching, setIsFetching] = useState(false)

  const { currentUser } = useStoreon('currentUser');
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { closeModal, openModal } = useModal()

  const { getRating } = api.limitsApi;
  const { getUserUnderwriters } = api.usersApi;
  const {
    getOrderById,
    getPrincipalById,
    updateOrderById,
    getOrdersSpecialConditionList,
//    getOrderScoringTenders,
    getOrderScoringTendersBanksly,
    getOrdersDocumentsList,
    postOrderComment,
    getOrdersQuote,
    updateQuote,
  } = api.ordersApi;
  const {
    postStatusUWInProgress,
    updateState,
    postStatusUWRefusal,
    postStatusUWRequery,
    postStatusQuoteSent,
    postStatusGuarantyIssueRequested,
  } = api.statusChangeApi;
  const { QUOTE_AUTO, QUOTE_INDIVIDUAL, QUOTE_AUCTION, GUARANTEE_ISSUE_REQUESTED, QUOTE_AGREED, UNDERWRITING_A_NEW_APPLICATION, UNDERWRITING_IN_PROGRESS } = STATUSES_STATE

  const isSigningPage = () => order.state === STATUSES_STATE.DOCUMENTS_SIGNATURE || order.state === STATUSES_STATE.QUOTE_AGREED;
  const hasOrderValidDocuments = () => documents.some(doc => !doc.is_valid);
  const updateOrderIfStatusChanged = (res) => {
    if (res?.status) setOrder(res.data)
  }

  const rating = {
    letter_rating: limit?.letter,
    ball_rating: limit?.calculationRating,
  }

  const handleSubmitNewUW = (selectedUnderwriter) => {
    return updateOrderById(orderId, { underwriter: +selectedUnderwriter, uw_has_been_changed: true })
      .then(() => updateState({ order: orderId, state: STATUSES_STATE.UNDERWRITING_A_NEW_APPLICATION }))
      .then((res) => {
        updateOrderIfStatusChanged(res)
        closeModal()
      })
      .catch(err => handleRequestError(err, navigate))
  };

  const handleOpenSetUnderwriterModal = () => openModal(MODALS.setUnderwriterModal, {
    value: underwriter,
    underwriters,
    onAbort: closeModal,
    onSubmit: handleSubmitNewUW,
  })

  const onSuspendOrder = (comment) => {
    const params = { order: orderId, description: comment }
    return postStatusUWRequery(params)
      .then(res => {
        updateOrderIfStatusChanged(res)

        const commentParams = {
          order: orderId,
          page: ORDER_COMMENTS_PAGES.UNDERWRITING,
          content: comment
        }
        return postOrderComment(commentParams)
      })
      .catch(err => handleRequestError(err, navigate))
  }

  const onRejectOrder = (comment) => {
    const params = { order: orderId, description: comment }
    return postStatusUWRefusal(params)
      .then(res => {
        updateOrderIfStatusChanged(res)

        const commentParams = {
          order: orderId,
          page: ORDER_COMMENTS_PAGES.UNDERWRITING,
          content: comment
        }
        return postOrderComment(commentParams)
      })
      .catch(err => handleRequestError(err, navigate))
  }

  const handleChangeStatusToDocumentsSigned = () => {
    if (!hasOrderValidDocuments()) {
      return updateState({ order: orderId, state: STATUSES_STATE.DOCUMENTS_SIGNATURE })
        .then(updateOrderIfStatusChanged)
        .catch(err => handleRequestError(err, navigate))
    }
    return '';
  };

  const handleChangeStatusToQuoteSent = () => {
    return toast.promise(Promise.all([
      postStatusQuoteSent({ order: orderId }),
      getOrdersQuote({ order: orderId })
    ]), {
      success: 'Котировки успешно направлены',
      error: 'Не удалось направить котировки',
      pending: 'Направление...',
    })
      .then(([changedStatusOrderResponse, quotesData]) => {
        updateOrderIfStatusChanged(changedStatusOrderResponse)
        quotesData.forEach(({ quote }) => {
          updateQuote(quote.id, {
            status: quote.is_edited ? QUOTE_STATUSES_PULL.DIRECTED : QUOTE_STATUSES_PULL.NOT_DIRECTED
          })
        })
      })
      .catch(err => handleRequestError(err, navigate))
  }

  const changeOrderStatusToInProgress = () => {
    return postStatusUWInProgress({ order: orderId })
      .then(updateOrderIfStatusChanged)
      .catch(err => handleRequestError(err, navigate))
  }

  const handleCloseIntegrationCheck = () => {
    closeModal()
    changeOrderStatusToInProgress();
  }

  const handleIntegrationCheck = () => {
    openModal(MODALS.integrationCheckModal, {
      isInPending: true
    })

    getOrderById(orderId).then(orderData => {
      openModal(MODALS.integrationCheckModal, {
        data: integrationCheckSerializer(orderData)
      });
    })
  }

  const orderStatusesThatDisablesForm = [QUOTE_AUTO, QUOTE_INDIVIDUAL, QUOTE_AUCTION, QUOTE_AGREED]
  const isSubmitBbtnShouldDisabled = () => !orderStatusesThatDisablesForm.includes(order.state) || (order.state === QUOTE_AGREED && !hasOrderValidDocuments())

  const onSubmit = (data) => {
    if (!isSubmitBbtnShouldDisabled()) return Promise.reject()

    const dataToSign = JSON.stringify(orderDataForCrypto(order))
    return updateOrderById(orderId, { ...data, data_to_sign_txt_box: dataToSign })
      .then(() => {
        const commentParams = {
          order: orderId,
          content: data.comment,
          page: ORDER_COMMENTS_PAGES.UNDERWRITING
        }
        return postOrderComment(commentParams)
      })
      .then(() => postStatusGuarantyIssueRequested({ order: orderId }))
      .then(updateOrderIfStatusChanged)
      .catch(err => handleRequestError(err, navigate))
  }

  const formik = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
  })

  const handleChangeSpecCondition = ({ target }) => {
    updateOrderById(orderId, { special_condition: [+target.value] })
    formik.setFieldValue('special_condition', +target.value)
  }

  const quotationFormLabel = () => {
    if (isSigningPage()) return 'Направить в Банк'
    return 'Одобрить и направить на котировку'
  }

  const convertToSelectList = (data) => {
    return data.map(({ id, title }) => ({
      id,
      value: id,
      name: title,
    }))
  }

  const isNewOrderWithoutUnderwriter = (orderData) => !orderData.underwriter && orderData.state === STATUSES_STATE.UNDERWRITING_A_NEW_APPLICATION
  const isSuspendBtnDisabled = !formik.values.comment || STATUSES_ID[order.state] !== STATUSES_ID.UNDERWRITING_IN_PROGRESS
  const isRejectBtnDisabled = !formik.values.comment || !(STATUSES_ID[order.state] === STATUSES_ID.UNDERWRITING_IN_PROGRESS || STATUSES_ID[order.state] === STATUSES_ID.UNDERWRITING_REQUERY)
  const statusesThatDisablesChangeUWBtn = [UNDERWRITING_A_NEW_APPLICATION, UNDERWRITING_IN_PROGRESS]
  const isChangeUWBtnDisabled = () => !statusesThatDisablesChangeUWBtn.includes(order.state) || isRoleJuniorUW(currentUser) || order?.uw_has_been_changed
  const isSpecialConditionsSelectDisabled = () => order.doc_type === 'Guarantee'

  useEffect(() => {
    setIsFetching(true)
    Promise.all([
      getOrderById(orderId),
      getUserUnderwriters(),
      getOrdersSpecialConditionList(),
      getOrdersDocumentsList({ order: orderId }),
    ])
      .then(([orderData, underwritersData, specConditionsData, docsData]) => {
        const convertedConditionsList = convertToSelectList(specConditionsData)
        setSpecConditionsData(convertedConditionsList)
        setOrder(orderData)
        setUnderwriters(underwritersData)
        setDocuments(docsData)

        const isNewOrder = () => orderData.state === STATUSES_STATE.UNDERWRITING_A_NEW_APPLICATION
        if (isNewOrder()) {
          openModal(MODALS.integrationCheckModal, {
            data: integrationCheckSerializer(orderData),
            handleChangeStatus: handleCloseIntegrationCheck,
            onHide: handleCloseIntegrationCheck
          })
        }

        if (orderData.special_condition.length) {
          formik.setFieldValue('special_condition', orderData.special_condition[0]?.id)
          setSpecialConditionValue(orderData.special_condition[0]?.id)
          setInitialValues(state => ({ ...state, special_condition: orderData.special_condition[0].id }))
        }

        if (isNewOrderWithoutUnderwriter(orderData)) {
          updateOrderById(orderId, { underwriter: currentUser.id })
        }

        if (orderData.final_quote) {
          updateQuote(orderData.final_quote, { status: QUOTE_STATUSES_PULL.AGREED })
            .then(() => {
              return getOrdersQuote({ order: orderId, quote: orderData.final_quote })
                .then(finalQuoteData => {
                  setFinalQuote(finalQuoteData[0])
                })
            })
            .catch(err => handleRequestError(err, navigate))
        }

//        return Promise.all([
//          getRating({ principal: orderData.principal?.id }),
//          getPrincipalById(orderData.principal?.id),
//          getOrderScoringTenders({
//            supplier: orderData.principal?.id,
//            customer: orderData.beneficiary?.id,
//          }),
//        ])
//      })
//      .then(([limitData, principalData, scoringData]) => {
//        setLimit(limitData)
//        setPrincipal(principalData)
//        setScoring(scoringData[0]?.pd_ttc)
//      })
        return Promise.all([
          getRating({ principal: orderData.principal?.id }),
          getPrincipalById(orderData.principal?.id),
          getOrderScoringTendersBanksly({
//            beneficiar: orderData.beneficiary?.id,
//            principal: orderData.principal?.id,
            order: orderData.id
          }),
        ])
      })
      .then(([limitData, principalData, scoringData]) => {
        setLimit(limitData)
        setPrincipal(principalData)
        setScoring(scoringData[0]?.probability_ratio*100)
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  useEffect(() => {
    if (order?.underwriter) {
      setUnderwriter(order.underwriter.id);
    }

    const orderStatusesToShowCurrentQuotation = [GUARANTEE_ISSUE_REQUESTED]
    const showCurrentQuotation = () => orderStatusesToShowCurrentQuotation.includes(order.state);
    if (showCurrentQuotation()) {
      setCurrentQuotationState(true);
    } else {
      setCurrentQuotationState(false);
    }
  }, [order])

  return (
    <>
      <OrderViews.Header
        orderId={order.id}
        sum={order.sum}
        principal={order.principal}
        fz={order.contest?.fz?.fz}
        status={order.state}
        handleIntegrationCheck={handleIntegrationCheck}
        bankGuaranteeNumber={order.bank_guarantee_number}
      />
      {isFetching ?
        <Preloader />
        :
        <>
          <OrderViews.Summery
            principal={order.principal}
            beneficiary={order.beneficiary}
            sum={order.sum}
            term={order.term}
            contest={order.contest}
          />
          <OrderViews.QueryIndicators
            {...order}
            principalTotalLimit={principal?.total_limit}
            principalAvailableLimit={principal?.free_balance}
            rating={rating}
            scoring={scoring}
          />
          {currentQuotationState ?
            <OrderViews.CurrentQuotation
              handleUserNounClick={handleOpenSetUnderwriterModal}
              isChangeUWBtnDisabled={isChangeUWBtnDisabled}
              quoteData={finalQuote}
            />
            :
            <OrderViews.QuotationForm
              formik={formik}
              handleUserNounClick={handleOpenSetUnderwriterModal}
              specialConditionsList={specConditions}
              submitLabel={quotationFormLabel()}
              orderDataForCrypto={isSigningPage() ? orderDataForCrypto(order) : null}
              disabled={hasOrderValidDocuments()}
              handleChangeStatusToDocumentsSigned={handleChangeStatusToDocumentsSigned}
              handleSuspend={onSuspendOrder}
              handleReject={onRejectOrder}
              handleChangeStatusToQuoteSent={handleChangeStatusToQuoteSent}
              isChangeUWBtnDisabled={isChangeUWBtnDisabled}
              setSelectedSpecConditionsData={handleChangeSpecCondition}
              isSubmitBbtnShouldDisabled={isSubmitBbtnShouldDisabled}
              isSpecialConditionsSelectDisabled={isSpecialConditionsSelectDisabled}
              isSuspendBtnDisabled={isSuspendBtnDisabled}
              isRejectBtnDisabled={isRejectBtnDisabled}
            />
          }
        </>
      }
    </>
  )
}

export default OrderUnderwriting;
