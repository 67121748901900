import React from 'react';
import classNames from 'classnames';
import { GxForm } from '@garpix/garpix-web-components-react';
import Input from '../../Input';
import Textarea from '../../Textarea';
import Button from '../../Button';
import Select from '../../Select';
import ButtonIcon from '../../ButtonIcon';
import CryptoProButton from '../../../components/CryptoPro';
import { userNoun } from '../../../images';
import style from './styles/index.module.scss';

const QuotationForm = ({
  formik,
  handleSuspend,
  handleReject,
  handleUserNounClick,
  specialConditionsList = [],
  orderDataForCrypto = {},
  submitLabel = '',
  handleChangeStatusToQuoteSent,
  isChangeUWBtnDisabled,
  disabled = false,
  handleChangeStatusToDocumentsSigned,
  setSelectedSpecConditionsData,
  isSubmitBbtnShouldDisabled,
  isSpecialConditionsSelectDisabled,
  isSuspendBtnDisabled,
  isRejectBtnDisabled,
  className,
  ...props
}) => {
  const handleCryptoDataChange = (data) => {
    formik.setFieldValue('signature', data.signature)
    formik.setFieldValue('signature_author', data.certificate.subjectName)
    formik.submitForm()
  }
  const onReject = () => handleReject(formik.values.comment)
  const onSuspend = () => handleSuspend(formik.values.comment)

  return (
    <section
      className={classNames({
        [style.orderQuotationContainer]: true,
        [className]: !!className
      })}
      {...props}
    >
      <GxForm onGx-submit={formik.handleSubmit} className={style.orderQuotationForm}>
        <div className={style.orderQuotationForm__leftSide}>
          <div>
            <p className={style.orderQuotationForm__label}>Сумма гарантии</p>
            <Input
              onGx-input={formik.handleChange}
              value={formik.values.guaranty}
              name='guaranty'
              className={style.orderQuotationForm__input}
            />
          </div>
          <div>
            <p className={style.orderQuotationForm__label}>Специальные условия</p>
            {
              specialConditionsList.length === 0 || <Select
                name='special_condition'
                list={specialConditionsList}
                value={formik.values.special_condition}
                defaultValue={formik.values.special_condition}
                onGx-change={setSelectedSpecConditionsData}
                className={style.orderQuotationForm__input}
                disabled={isSpecialConditionsSelectDisabled()}
              />
            }
          </div>
        </div>
        <div className={style.orderQuotationForm__rightSide}>
          <p className={style.orderQuotationForm__label}>Комментарий</p>
          <Textarea
            onGx-input={formik.handleChange}
            value={formik.values.comment}
            name='comment'
            className={style.orderQuotationForm__textarea}
          />
          <div className={style['orderQuotationForm__button-group']}>
            {orderDataForCrypto
              ? <CryptoProButton
                buttonProps={{
                  className: style.orderQuotationForm__button,
                  disabled,
                }}
                handleChangeStatus={handleChangeStatusToDocumentsSigned}
                formData={orderDataForCrypto}
                signatureCallback={handleCryptoDataChange}
              >
                {submitLabel}
              </CryptoProButton>
              : <Button
                className={style.orderQuotationForm__button}
                onClick={handleChangeStatusToQuoteSent}
                disabled={isSubmitBbtnShouldDisabled()}
              >
                {submitLabel}
              </Button>
            }

            <Button
              className={style.orderQuotationForm__button}
              onClick={onReject}
              projectVariant='button--transparent'
              disabled={isRejectBtnDisabled}
            >
              Отказать
            </Button>
            <Button
              className={style.orderQuotationForm__button}
              onClick={onSuspend} projectVariant='button--transparent'
              disabled={isSuspendBtnDisabled}
            >
              Приостановить
            </Button>
            <ButtonIcon
              ariaLabel='user noun'
              iconSrc={userNoun}
              onClick={handleUserNounClick}
              disabled={isChangeUWBtnDisabled()}
            />
          </div>
        </div>
      </GxForm>
    </section >
  )
}

export default QuotationForm
