import { formatNumberToSend } from '../../../utils'

const formatTotalLimit = (data) => {
  return {
    total_limit: formatNumberToSend(data.total_limit).replace(',', '.')
  }
}

const formatLimit = (data) => {
  return {
    total_limit: formatNumberToSend(data.total_limit).replace(',', '.')
  }
}

export default {
  formatTotalLimit,
  formatLimit,
}