import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Title from '../../Title';
import style from './styles/index.module.scss';

const RequestStatus = ({
  requestStatus,
  className,
  ...props
}) => {
  // eslint-disable-next-line consistent-return
  const getInfo = () => {
    // eslint-disable-next-line default-case
    switch (requestStatus) {
      case 401: return 'Пройдите авторизацию';
      case 403: return 'Нет прав доступа';
      case 404: return 'Ресурс не найден';
      case 500: return 'Внутренняя ошибка сервера';
    }
  }

  return (
    <main
      className={classNames(
        [style.container],
        { [className]: !!className }
      )}
      {...props}
    >
      <div className={style.block}>
        <Title
          className={style.title}
          level='1'
          projectVariant='title_section'
        >
          Статус {requestStatus}:
        </Title>
        <span className={style.descr}>{getInfo(requestStatus)}</span>
        <Link
          className={style.link}
          to='/'
        >
          На главную
        </Link>
      </div>
    </main>
  )
}

export default RequestStatus;