// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_title__25Ovv {\n  margin-bottom: 20px;\n}\n\n.styles_list__1BNsQ {\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-gap: 20px;\n}", "",{"version":3,"sources":["webpack://src/views/PrincipalViews/Coowners/styles/index.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,0BAAA;EACA,cAAA;AACF","sourcesContent":[".title {\n  margin-bottom: 20px;\n}\n\n.list {\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "styles_title__25Ovv",
	"list": "styles_list__1BNsQ"
};
export default ___CSS_LOADER_EXPORT___;
