import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { handleRequestError } from '../../utils';
import api from '../../api';
import utils from './utils';
import BankViews from '../../views/BankViews';
import SimpleTable from '../../views/SimpleTable';
import style from './index.module.scss';
import Preloader from '../../views/Preloader';

const BankLimits = () => {
  const [bank, setBank] = useState({});
  const [limit, setLimit] = useState(null);
  const { getBankById, getBankLimitsById } = api.bankApi;
  const [isFetching, setIsFetching] = useState(false)
  const { bankId } = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    setIsFetching(true)
    Promise.all([getBankById(bankId), getBankLimitsById(bankId)])
      .then(([dataBank, dataLimit]) => {
        setBank(dataBank)
        setLimit(utils.convertPrincipalLimitToTable(dataLimit))
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  return (
    <>
      <BankViews.Header bank={bank} />
      <section>
        {isFetching ?
          <Preloader />
          :
          <SimpleTable className={style.table} tableBody={limit} />
        }
      </section>
    </>
  )
}

export default BankLimits;