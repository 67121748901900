import React from 'react'
import InputRange from 'react-input-range';
import style from './styles/index.module.scss'

const Range = (props) => {
  const {
    inputMin,
    inputMax,
    min: minValue,
    max: maxValue,
    inputMinName,
    inputMaxName,
    setFieldValue,
  } = props;

  const values = { min: inputMin, max: inputMax };

  const handleChange = ({ min, max }) => {
    setFieldValue(inputMinName, min)
    setFieldValue(inputMaxName, max)
  };

  return (
    <InputRange
      maxValue={maxValue}
      minValue={minValue}
      value={values}
      onChange={handleChange}
      name='double-range'
      classNames={{
        inputRange: style.inputRange,
        track: style.track,
        activeTrack: style.activeTrack,
        labelContainer: style.label,
        slider: style.slider,
      }}
    />
  )
}

export default Range