import React from 'react';
import OrderLimits from '../components/OrderLimits';
import Layout from '../views';

const OrderLimitsPage = (props) => {
  return (
    <Layout {...props}>
      <OrderLimits />
    </Layout>
  )
}

export default OrderLimitsPage;