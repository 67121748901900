// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Underwriters_section_header__2JRmS {\n  display: flex;\n  justify-content: space-between;\n  padding: 3px 3px 10px 0;\n}", "",{"version":3,"sources":["webpack://src/components/Underwriters/index.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,uBAAA;AACJ","sourcesContent":[".section_header{\n    display: flex;\n    justify-content: space-between;\n    padding: 3px 3px 10px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section_header": "Underwriters_section_header__2JRmS"
};
export default ___CSS_LOADER_EXPORT___;
