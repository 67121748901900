import React from 'react';
import Layout from '../views';
import PrincipalCoowners from '../components/PrincipalCoowners';

const PrincipalCoownersPage = (props) => {
  return (
    <Layout {...props}>
      <PrincipalCoowners />
    </Layout>
  )
}

export default PrincipalCoownersPage;