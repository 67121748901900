import React from 'react';
import Title from '../../Title';
import style from './styles/index.module.scss';

const Header = ({ bank }) => (
  <section className={style.header}>
    <div className={style.header__main}>
      <div className={style.header__logoWrapper}>
        <img
          className={style.header__logo}
          src={bank.logo}
          alt={bank.title}
        />
      </div>
      <Title
        level='1'
        projectVariant='title_section'
      >
        {bank.title}
      </Title>
    </div>
  </section>
);

export default Header;
