import React from 'react';
import classNames from 'classnames';
import Title from '../../Title';
import Checkbox from '../../Checkbox';
import style from './styles/index.module.scss';

const ProfileNotifications = ({
  className,
  ...props
}) => {
  return (
    <section className={classNames(
      [style.section],
      { [className]: !!className }
    )}>
      <fieldset
        className={classNames(
          [style.fieldset],
          [style.fieldset_notificationEmail]
        )}
        {...props}
      >
        <Title
          className={style.fieldset__title}
          projectVariant='title_section'
          level='2'
        >
          Уведомления по E-mail
        </Title>
        <div className={style.fieldset__wrapper}>
          <Checkbox className={style.fieldset__checkbox}>
            Уведомления системы
          </Checkbox>
          <Checkbox className={style.fieldset__checkbox}>
            Новое сообщение в чате
          </Checkbox>
        </div>
      </fieldset>
      <fieldset
        className={classNames(
          [style.fieldset],
          [style.fieldset_notificationDesktop]
        )}
        {...props}
      >
        <Title
          className={style.fieldset__title}
          projectVariant='title_section'
          level='2'
        >
          Уведомления на рабочем столе
        </Title>
        <div className={style.fieldset__wrapper}>
          <Checkbox className={style.fieldset__checkbox}>
            Уведомления системы
          </Checkbox>
          <Checkbox className={style.fieldset__checkbox}>
            Новое сообщение в чате
          </Checkbox>
        </div>
      </fieldset>
    </section>
  )
}

export default ProfileNotifications;