const stopFactorsSerializer = (res) => {
  const data = []
  res.forEach(item => {
    data.push({ id: item.id, descr: item.title, state: item.value });
  })

  return data;
}

export default stopFactorsSerializer;
