import React from 'react';
import Layout from '../views';
import OrderPurchase from '../components/OrderPurchase';

const OrderPurchasePage = (props) => {
  return (
    <Layout {...props}>
      <OrderPurchase />
    </Layout>
  )
}

export default OrderPurchasePage;