import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { STATUSES_PULL } from '../../../const';
import style from './styles/index.module.scss';

const Progressbar = ({
  status,
  isDisplay,
  className,
  ...props
}) => {
  const progressbar = useRef(null);
  const setPhase = () => {
    STATUSES_PULL.forEach((phase, phaseIndex) => {
      progressbar.current?.childNodes[phaseIndex].setAttribute('data-pass', false);
      progressbar.current?.childNodes[phaseIndex].setAttribute('data-current', false);
    })
    for (let i = STATUSES_PULL.length - 1, isFound = false; i >= 0; i -= 1) {
      if (!isFound) {
        STATUSES_PULL[i].forEach(statusName => {
          if (statusName === status) {
            progressbar.current?.childNodes[i].setAttribute('data-pass', true);
            progressbar.current?.childNodes[i].setAttribute('data-current', true);
            isFound = true;
          }
        })
      } else {
        progressbar.current?.childNodes[i].setAttribute('data-pass', true);
      }
    }
  }

  useEffect(() => {
    setPhase()
  }, [status]);
  return (
    <div
      className={classNames({
        [style.orderHeaderProgressbar]: true,
        [style.opened]: isDisplay,
        [className]: !!className
      })}
      {...props}
    >
      <ul
        className={style.orderHeaderProgressbarList}
        ref={progressbar}
      >
        <li className={style.orderHeaderProgressbarList__item}>
          <span className={style.orderHeaderProgressbarList__doneLine} />
          <span className={style.orderHeaderProgressbarList__point} />
        </li>
        <li className={style.orderHeaderProgressbarList__item}>
          <span className={style.orderHeaderProgressbarList__doneLine} />
          <span className={style.orderHeaderProgressbarList__point} />
        </li>
        <li className={style.orderHeaderProgressbarList__item}>
          <span className={style.orderHeaderProgressbarList__doneLine} />
          <span className={style.orderHeaderProgressbarList__point} />
        </li>
        <li className={style.orderHeaderProgressbarList__item}>
          <span className={style.orderHeaderProgressbarList__doneLine} />
          <span className={style.orderHeaderProgressbarList__point} />
        </li>
        <li className={style.orderHeaderProgressbarList__item}>
          <span className={style.orderHeaderProgressbarList__doneLine} />
          <span className={style.orderHeaderProgressbarList__point} />
        </li>
      </ul>
    </div>
  )
}

export default Progressbar;