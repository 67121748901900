import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api';
import PrincipalViews from '../../views/PrincipalViews';
import Title from '../../views/Title';
import Preloader from '../../views/Preloader'

const PrincipalCoowners = () => {
  const [principal, setPrincipal] = useState({});
  const [isFetching, setIsFetching] = useState(false)
  const { getPrincipalById } = api.entityApi;
  const { principalId } = useParams();

  useEffect(() => {
    setIsFetching(true)
    getPrincipalById(principalId)
      .then(dataPrincipal => {
        setPrincipal(dataPrincipal);
      })
      .finally(() => setIsFetching(false))
  }, [])

  return (
    <>
      <Title level='1'>Совладельцы</Title>
      <PrincipalViews.Header principal={principal} />
      {isFetching ?
        <Preloader />
        :
        <PrincipalViews.Coowners />
      }
    </>
  )
}

export default PrincipalCoowners;