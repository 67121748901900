// eslint-disable import/no-cycle
import BaseApi from '@garpix/base-api';
import { setCookie, getCookie, removeCookie } from '../../utils'

class AbstractBaseApi extends BaseApi {
  constructor(MAIN_URL) {
    super(MAIN_URL);
    this.AUTH_TOKEN_KEY = 'bg_token';
    this.USER_ID_TOKEN_KEY = 'bg_token_userId';
  }

  axiosOverride = (axios) => {
    const token = this.getAuthToken();
    // eslint-disable-next-line no-param-reassign
    axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : '';
    return axios;
  };

  getCurrentUserId = () => {
    return getCookie(this.USER_ID_TOKEN_KEY)
  }

  getAuthToken = () => {
    return getCookie(this.AUTH_TOKEN_KEY);
  };

  setAuthToken = (token, userId, days) => {
    setCookie(this.AUTH_TOKEN_KEY, token, days);
    setCookie(this.USER_ID_TOKEN_KEY, userId, days);
    return true;
  };

  removeAuthToken = () => {
    try {
      removeCookie(this.AUTH_TOKEN_KEY);
      removeCookie(this.USER_ID_TOKEN_KEY);
      return true;
    } catch (e) {
      return false; // no key
    }
  };
}

export default AbstractBaseApi;
