import React from 'react';
import Layout from '../views';
import HandbookPresignals from '../components/HandbookPresignals';

const HandbookPresignalsPage = (props) => {
  return (
    <Layout {...props}>
      <HandbookPresignals />
    </Layout>
  )
}

export default HandbookPresignalsPage;