import React from 'react';
import Layout from '../views';
import Chat from '../components/Chat';

const ChatPage = ({ ...props }) => {
  return (
    <Layout {...props}>
      <Chat />
    </Layout>
  )
}

export default ChatPage;