import React from 'react';
import ParamCard from '../../ParamCard';
import style from './styles/index.module.scss';

const Summery = ({
  dataContacts,
  dataRequisites,
  className,
  ...props
}) => {
  return (
    <section
      className={className}
      {...props}
    >
      <ul className={style.list}>
        <li>
          <ParamCard
            title='Контактная информация'
            titleLevel='2'
            dataInput={dataContacts}
          />
        </li>
        <li>
          <ParamCard
            title='Реквизиты'
            titleLevel='2'
            dataInput={dataRequisites}
          />
        </li>
      </ul>
    </section>
  )
}

export default Summery;