import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import limitUtils from './utils'
import api from '../../api';
import PrincipalViews from '../../views/PrincipalViews';
import SimpleTable from '../../views/SimpleTable';
import { handleRequestError } from '../../utils';
import style from './index.module.scss';
import Preloader from '../../views/Preloader';

const PrincipalLimits = () => {
  const [principal, setPrincipal] = useState([])
  const [convertedPrincipal, setСonvertedPrincipal] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const { getPrincipalById } = api.entityApi
  const { getPrincipalList } = api.limitsApi
  const { principalId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    setIsFetching(true)
    getPrincipalById(principalId)
      .then(data => {
        setPrincipal(data)
        return getPrincipalList({ principal: data?.id })
      })
      .then((principalData) => {
        const rawPrincipal = limitUtils.convertPrincipalLimitToTable(principalData[0])
        setСonvertedPrincipal(rawPrincipal)
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  return (
    <>
      <PrincipalViews.Header principal={principal} />
      {isFetching ?
        <Preloader />
        :
        <>
          {principal ?
            <section>
              <SimpleTable className={style.table} tableBody={convertedPrincipal} />
            </section>
            :
            <p>Нет данных</p>
          }
        </>
      }
    </>
  )
}

export default PrincipalLimits