import AbstractBaseApi from '../AbstractBaseApi';
import {
  banksListSerializer,
  limitsListSerializer,
  limitsBanksListSerializer,
  limitsFzListSerializer,
  ratingSerializer,
  formatDataToSend
} from './serializers'

export default class LimitsApi extends AbstractBaseApi {
  getRating = async (params = {}) => {
    const res = await this.get('limit/limit/', params)
    const serializedRating = ratingSerializer.serializeRatingData(res.data[0])
    return serializedRating
  }

  updateRating = async (id, params = {}) => {
    const res = await this.patch(`limit/limit/${id}/`, params)
    const serializedRating = ratingSerializer.serializeRatingData(res.data)
    return serializedRating
  }

  getLimits = async (params = {}) => {
    const res = await this.get('bank/bank_limits/', params)
    return res.data
  }

  getFzLimits = async (params = {}) => {
    const res = await this.get('limit/fz_limits/', params)
    return limitsFzListSerializer(res.data)
  }

  getPrincipalList = async (params = {}) => {
    const res = await this.get('limit/limit_principal/', params)
    return res.data
  }

  updatePrincipalLimit = async (id, data) => {
    const formattedData = formatDataToSend.formatLimit(data)
    const res = await this.patch(`limit/limit_principal/${id}/`, formattedData)
    return res.data
  }

  getBanksList = async (params = {}) => {
    const res = await this.get('bank/bank_limits/', params)
    return banksListSerializer(res.data);
  }

  getLimitsBank = async (id) => {
    const res = await this.get(`bank/bank_limits/${id}/`)
    return banksListSerializer(res.data)
  }

  getLimitFzList = async (params = {}) => {
    const res = await this.get('limit/limit_fz', params)
    return limitsListSerializer(res.data)
  }

  getBankLimits = async (params = {}) => {
    const res = await this.get('bank/bank_limits/', params)
    return limitsBanksListSerializer(res.data)
  }

  getFzLimitById = async (params = {}) => {
    const res = await this.get('limit/fz_limits/', params)
    return res.data
  }

  getBankLimitById = async (id) => {
    const res = await this.get(`bank/bank_limits/${id}/`)
    return res.data
  }

  updateLimitsFz = async (id, data) => {
    const formattedData = formatDataToSend.formatLimit(data)
    const res = await this.patch(`limit/limit_fz/${id}/`, formattedData)
    return res.data
  }

  updateLimitsBank = async (id, data) => {
    const formattedData = formatDataToSend.formatTotalLimit(data)
    const res = await this.patch(`bank/bank_limits/${id}/`, formattedData)
    return res.data
  }
}