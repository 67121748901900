/* eslint-disable camelcase */
import { convertStatusName, initialsName, numberWithSpaces } from '../../../utils'

const ordersListSerializer = (data) => data.map((item) => {
    const { id, principal, sum, contest, state, underwriter, start_date } = item

    return {
        id,
        cells: [
            {
                id: 1,
                data: id || '-',
                isLink: true
            },
            {
                id: 2,
                data: principal?.title || '-',
                isLink: true
            },
            {
                id: 3,
                data: numberWithSpaces(sum, true) || '-',
                isLink: true
            },
            {
                id: 4,
                data: contest?.fz?.fz || '-',
                isLink: true
            },
            {
                id: 5,
                data: convertStatusName(state),
                isStatus: true
            },
            {
                id: 6,
                data: initialsName(underwriter?.first_name, underwriter?.last_name, underwriter?.patronymic) || '-',
                isLink: true
            },
            {
                id: 7,
                data: start_date ? new Date(start_date).toLocaleDateString() : '-',
                isLink: true
            }
        ]
    }
})

export default ordersListSerializer