import React from 'react';
import Layout from '../views';
import Underwriters from '../components/Underwriters';

const UnderwritersPage = () => {
  return (
    <Layout>
      <Underwriters />
    </Layout>
  )
}

export default UnderwritersPage;
