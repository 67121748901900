import React from 'react';
import Layout from '../views';
import BankDocuments from '../components/BankDocuments';

const BankDocumentsPage = (props) => {
  return (
    <Layout {...props}>
      <BankDocuments />
    </Layout>
  )
}

export default BankDocumentsPage;