import React from 'react';
import MiscViews from '../views/MiscViews';

const Error500 = () => {
  return (
    <MiscViews.RequestStatus requestStatus={500} />
  )
}

export default Error500;
