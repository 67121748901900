import React from 'react'
import { Route, Routes } from 'react-router-dom'
import uuid4 from 'uuid/dist/v4'
import {
  OrderFinreportingPage,
  OrderUnderwritingPage,
  OrderRatingPage,
  OrderPurchasePage,
  OrderLimitsPage,
  OrderStopFactorsPage,
  OrderScoringPage,
  OrderDocumentsPage,
  OrderQuotationPage,
  OrderHistoryPage
} from '../../pages'
import { alert, tablet, rated, history, purse, creditScore, auctionHammer, сhart, graph, documentIcon } from '../../images'
import { PATHS } from '../../const'

const asideLinks = [
  {
    id: uuid4(),
    path: PATHS.ORDER_UNDERWRITING.link,
    icon: tablet,
    name: 'Андеррайтинг',
  },
  {
    id: uuid4(),
    path: PATHS.ORDER_PURCHASE.link,
    icon: auctionHammer,
    name: 'Закупка',
  },
  {
    id: uuid4(),
    path: PATHS.ORDER_FINREPORTING.link,
    icon: purse,
    name: 'Финансы',
  },
  {
    id: uuid4(),
    path: PATHS.ORDER_STOPFACTORS.link,
    icon: alert,
    name: 'Стоп-факторы и предсигналы',
  },
  {
    id: uuid4(),
    path: PATHS.ORDER_RATING.link,
    icon: rated,
    name: 'Рейтинг',
  },
  {
    id: uuid4(),
    path: PATHS.ORDER_LIMITS.link,
    icon: creditScore,
    name: 'Лимиты',
  },
  {
    id: uuid4(),
    path: PATHS.ORDER_SCORING.link,
    icon: graph,
    name: 'Скоринг',
  },
  {
    id: uuid4(),
    path: PATHS.ORDER_QUOTATION.link,
    icon: сhart,
    name: 'Котировка',
  },
  {
    id: uuid4(),
    path: PATHS.ORDER_DOCUMENTS.link,
    icon: documentIcon,
    name: 'Документы',
  },
  {
    id: uuid4(),
    path: PATHS.ORDER_HISTORY.link,
    icon: history,
    name: 'История',
  },
]

const OrderUnderwriting = () => <OrderUnderwritingPage asideLinks={asideLinks} />
const OrderPurchase = () => <OrderPurchasePage asideLinks={asideLinks} />
const OrderFinreporting = () => <OrderFinreportingPage asideLinks={asideLinks} />
const OrderRating = () => <OrderRatingPage asideLinks={asideLinks} />
const OrderLimits = () => <OrderLimitsPage asideLinks={asideLinks} />
const OrderStopFactors = () => <OrderStopFactorsPage asideLinks={asideLinks} />
const OrderScoring = () => <OrderScoringPage asideLinks={asideLinks} />
const OrderDocuments = () => <OrderDocumentsPage asideLinks={asideLinks} />
const OrderQuotation = () => <OrderQuotationPage asideLinks={asideLinks} />
const OrderHistory = () => <OrderHistoryPage asideLinks={asideLinks} />

const OrdersRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.ORDER_UNDERWRITING.pathWithParam} element={<OrderUnderwriting />} />
      <Route path={PATHS.ORDER_PURCHASE.pathWithParam} element={<OrderPurchase />} />
      <Route path={PATHS.ORDER_FINREPORTING.pathWithParam} element={<OrderFinreporting />} />
      <Route path={PATHS.ORDER_RATING.pathWithParam} element={<OrderRating />} />
      <Route path={PATHS.ORDER_LIMITS.pathWithParam} element={<OrderLimits />} />
      <Route path={PATHS.ORDER_STOPFACTORS.pathWithParam} element={<OrderStopFactors />} />
      <Route path={PATHS.ORDER_SCORING.pathWithParam} element={<OrderScoring />} />
      <Route path={PATHS.ORDER_DOCUMENTS.pathWithParam} element={<OrderDocuments />} />
      <Route path={PATHS.ORDER_QUOTATION.pathWithParam} element={<OrderQuotation />} />
      <Route path={PATHS.ORDER_HISTORY.pathWithParam} element={<OrderHistory />} />
    </Routes>
  )
}

export default OrdersRoutes
