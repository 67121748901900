import React from 'react';
import Layout from '../views';
import Profile from '../components/Profile'

const ProfilePage = () => {
  return (
    <Layout>
      <Profile />
    </Layout>
  )
}

export default ProfilePage;