const serializeRatingData = (rating) => {
  return {
    id: rating?.id,
    calculationRating: rating?.ball_rating,
    factors: rating?.additional_factors,
    totalRating: rating?.ball_rating_total,
    letter: rating?.letter_rating,
    delta: rating?.delta,
    financialIndicators: rating?.financial_indicators,
    workExp: rating?.work_exp,
    scoreBigContracts: rating?.score_big_contracts,
    scoreAllContracts: rating?.score_all_contracts,
    scoreYear: rating?.score_year,
    ownershipStructure: rating?.ownership_structure,
    additionalFactors: rating?.additional_factors,
    additionalFactor1: rating?.add_factor_1,
    additionalFactor2: rating?.add_factor_2,
    additionalFactor3: rating?.add_factor_3,
    additionalFactor4: rating?.add_factor_4,
    additionalFactor5: rating?.add_factor_5,
    ball_rating_update: rating?.ball_rating_update
  }
}

export default { serializeRatingData }