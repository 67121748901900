import React from 'react'
import classNames from 'classnames'
import { GxForm } from '@garpix/garpix-web-components-react'
import Input from '../../Input'
import Button from '../../Button'
import { RATINGS } from '../../../const'
import style from './styles/index.module.scss'

const Rating = ({
  values,
  handleChange,
  calculationRating,
  factors,
  totalRating,
  letter,
  handleSubmit,
  checkedRating,
  handleChangeCheckRating,
  resetForm,
  isInputReadOnly,
  className,
  ...props
}) => {
  const customRadioClassName = (rating) => {
    return classNames(style.ratingRadio, {
      [style.ratingRadio_checked]: +checkedRating === +rating
    })
  }

  return (
    <div
      className={classNames({
        [style.order]: true,
        [className]: !!className
      })}
      {...props}
    >
      <div className={style.firstFloor}>
        <div className={style.orderRatingItem}>
          <span className={style.orderRatingItem__title}>Расчетный рейтинг</span>
          <span className={style.orderRatingItem__value}>{calculationRating}</span>
        </div>
        <div className={style.orderRatingItem}>
          <span className={style.orderRatingItem__title}>Доп. факторы</span>
          <span className={style.orderRatingItem__value}>{factors}</span>
        </div>
        <div className={style.orderCheckboxes}>
          {
            RATINGS.map(item => (
              <label className={customRadioClassName(item)} htmlFor={`rating-btn${item}`} key={`rating${item}`}>
                <input
                  type='radio'
                  className={style.ratingRadio__input}
                  id={`rating-btn${item}`}
                  value={item}
                  onClick={handleChangeCheckRating}
                  disabled={isInputReadOnly()}
                />
                {item}
              </label>
            ))
          }
        </div>
        <div className={style.orderRatingItem}>
          <span className={style.orderRatingItem__title}>Итоговый рейтинг</span>
          <span className={style.orderRatingItem__value}>{totalRating}</span>
        </div>
        <div className={style.orderRatingItem}>
          <span className={style.orderRatingItem__title}>Буква</span>
          <span className={style.orderRatingItem__value}>{letter}</span>
        </div>
      </div>
      <GxForm onGx-submit={handleSubmit} className={style.secondFloor}>
        <Input
          name='content'
          value={values.content}
          onGx-input={handleChange}
          placeholder='Комментарий'
          readonly={isInputReadOnly()}
        />
        <Button
          className={style.button}
          onClick={resetForm}
          disabled={isInputReadOnly() || values.content === ''}
          type='submit'
        >
          Подтвердить
        </Button>
      </GxForm>
    </div>
  )
}

export default Rating
