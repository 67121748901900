import React from 'react';
import classNames from 'classnames';
import { GxTextarea } from '@garpix/garpix-web-components-react';
import style from './styles/index.module.scss';

const Textarea = ({
  className,
  children,
  helpText,
  ...props
}) => {
  return (
    <GxTextarea
      className={classNames({
        [style.textarea]: true,
        [className]: !!className
      })}
      {...props}
    >
      {helpText}
      {children}
    </GxTextarea>
  )
}

export default Textarea;