import React from 'react'
import { ToastContainer } from 'react-toastify'
import uuid4 from 'uuid/dist/v4'
import Header from '../components/Header'
import Aside from './Aside/index'
import { PATHS } from '../const'

export default ({
  children,
  asideLinks = [],
  paramsName,
  ...props
}) => {
  const headerLinks = [
    {
      id: uuid4(),
      path: PATHS.HOME.link,
      name: 'Заявки',
      exact: true,
    },
    {
      id: uuid4(),
      path: PATHS.UNDERWRITERS.link,
      name: 'Андеррайтеры',
    },
    {
      id: uuid4(),
      path: PATHS.BANKS.link,
      name: 'Банки',
    },
    {
      id: uuid4(),
      path: PATHS.LIMITS.link,
      name: 'Лимиты',
    },
    {
      id: uuid4(),
      path: PATHS.PORTFOLIO.link,
      name: 'Портфель',
    },
    {
      id: uuid4(),
      path: PATHS.HANDBOOKS.link,
      name: 'Справочники',
    },
  ]

  return (
    <>
      <Header links={headerLinks} />
      <Aside
        links={asideLinks}
        paramsName={paramsName}
      />
      <main className='main' {...props}>
        {children}
      </main>
      <ToastContainer />
    </>
  )
}