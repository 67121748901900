/* eslint-disable camelcase */
import { numberWithSpaces, getUniqArray } from '../../../utils'

const ordersFinReportSerializer = (rawData) => {
  if (rawData.length > 0) {
    const MAX_YEARS_TO_VIEW = 4

    const sortedByYearData = rawData.sort((a, b) => a.year - b.year)
    const getLast4YearReports = () => {
      const indexToSlice = Math.max(sortedByYearData.length - MAX_YEARS_TO_VIEW, 0)
      const slicedReports = sortedByYearData.slice(indexToSlice)
      return slicedReports
    }
    const lastYearsReports = getLast4YearReports()
    const years = lastYearsReports.map(report => ({ label: report.year, key: report.year }))

    if (years.length < MAX_YEARS_TO_VIEW) {
      /* eslint-disable no-plusplus */
      for (let i = years.length; i < MAX_YEARS_TO_VIEW; i++) {
        const curYear = years[0].label
        years.unshift({ label: String(curYear - 1), key: String(curYear - 1) })
      }
    }

    const getUniqReportByCode = (array) => {
      const groupReports = array.reduce((acc, item) => {
        if (!acc[item.code]) acc[item.code] = []
        acc[item.code].push(item)
        return acc
      }, {})

      const result = []

      Object.keys(groupReports).forEach((key) => {
        let values = {}

        groupReports[key].forEach(group => {
          values = { ...values, ...Object.assign({}, group) }
        })

        years.forEach(year => {
          if (!Object.prototype.hasOwnProperty.call(values, year.key)) {
            values[year.key] = '-'
          }
        })

        const childCodes = ['2411', '2412']
        if (childCodes.includes(values.code)) {
          values.isChild = true
        }

        result.push(values)
      })

      return result
    }

    const getFinReportsByYear = (year, minCode, maxCode) => {
      const currentYear = lastYearsReports.find(report => report.year === year) || lastYearsReports[0]

      const rawFields = currentYear?.detail_form?.filter(form =>
        (form.line_name && +form.code >= +minCode && +form.code <= +maxCode)
      )
      return rawFields.map(param => {
        const value = { title: param?.line_name, code: param?.code }
        value[year] = param?.end_value !== undefined ? numberWithSpaces(param?.end_value) : '-'
        return value
      })
    }

    const getReportsByCodes = (minCode, maxCode = minCode) => {
      const reportsByYear = years.map(year => getFinReportsByYear(year.key, minCode, maxCode)).flat()
      const uniqReports = getUniqArray(reportsByYear)
      const uniqReportsByCode = getUniqReportByCode(uniqReports)

      if (uniqReportsByCode.length === 1) return uniqReportsByCode[0]
      if (uniqReportsByCode.length === 0) return undefined

      const reportsWithoutTotal = uniqReportsByCode.filter(item => item.title !== 'Итого')
      return reportsWithoutTotal
    }

    const getCurrentReport = (year, code) => {
      const currentYear = lastYearsReports.find(report => report.year === year) || lastYearsReports[0]

      const currentReport = currentYear.detail_form.find(form => form.code === code)
      return currentReport ? numberWithSpaces(currentReport.end_value) : '-'
    }

    const getReportOfYear = (code, percentOf) => years.map(year => {
      const value = {}
      const currentYear = year.key
      const report = code ? getCurrentReport(currentYear, code) : '-'
      if (percentOf && report) {
        const percentOfReport = +report.replaceAll(' ', '') * percentOf / 100
        value[currentYear] = numberWithSpaces(percentOfReport)
      } else {
        value[currentYear] = report
      }
      return value
    })

    return {
      balance: [
        {
          key: 'active',
          cols: [
            { label: 'Актив', key: 'title' },
            { label: 'Код', key: 'code' },
            ...years,
          ],
          rows: [
            {
              header: [
                { title: 'I. ВНЕОБОРОТНЫЕ АКТИВЫ', code: '' },
                { ...getReportsByCodes('1100'), title: 'Итого по разделу I' }
              ],
              body: [
                { title: 'I. ВНЕОБОРОТНЫЕ АКТИВЫ', code: '' },
                ...getReportsByCodes('1099', '1199'),
                { ...getReportsByCodes('1100'), title: 'Итого по разделу I' }
              ],
            },
            {
              header: [
                { title: 'II. ОБОРОТНЫЕ АКТИВЫ', code: '' },
                { ...getReportsByCodes('1200'), title: 'Итого по разделу II' },
                { ...getReportsByCodes('1600'), title: 'БАЛАНС' },
              ],
              body: [
                { title: 'II. ОБОРОТНЫЕ АКТИВЫ', code: '' },
                ...getReportsByCodes('1199', '1299'),
                { ...getReportsByCodes('1200'), title: 'Итого по разделу II' },
                { ...getReportsByCodes('1600'), title: 'БАЛАНС' },
              ],
            }
          ]
        },
        {
          key: 'passive',
          cols: [
            { label: 'Пассив', key: 'title' },
            { label: 'Код', key: 'code' },
            ...years,
          ],
          rows: [
            {
              header: [
                { title: 'III. КАПИТАЛ И РЕЗЕРВЫ', code: '' },
                { ...getReportsByCodes('1300'), title: 'Итого по разделу III' },
              ],
              body: [
                { title: 'III. КАПИТАЛ И РЕЗЕРВЫ', code: '1360' },
                { ...getReportsByCodes('1310'), title: 'Уставный капитал' },
                { ...getReportsByCodes('1320'), title: 'Собственные акции, выкупленные у акционеров' },
                { ...getReportsByCodes('1340'), title: 'Переоценка внеоборотных активов' },
                { ...getReportsByCodes('1350'), title: 'Добавочный капитал (без переоценки)' },
                { ...getReportsByCodes('1360'), title: 'Резервный капитал' },
                { ...getReportsByCodes('1370'), title: 'Нераспределенная прибыль (непокрытый убыток)' },
                { ...getReportsByCodes('1300'), title: 'Итого по разделу III' },
              ],
            },
            {
              header: [
                { title: 'IV. ДОЛГОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА', code: '' },
                { ...getReportsByCodes('1400'), title: 'Итого по разделу IV' },
              ],
              body: [
                { title: 'IV. ДОЛГОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА', code: '' },
                ...getReportsByCodes('1399', '1499'),
                { ...getReportsByCodes('1400'), title: 'Итого по разделу IV' },
              ],
            },
            {
              header: [
                { title: 'V. КРАТКОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА', code: '' },
                { ...getReportsByCodes('1500'), title: 'Итого по разделу V' },
                { ...getReportsByCodes('1600'), title: 'БАЛАНС' },
              ],
              body: [
                { title: 'V. КРАТКОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА', code: '' },
                ...getReportsByCodes('1500', '1599'),
                { ...getReportsByCodes('1500'), title: 'Итого по разделу V' },
                { ...getReportsByCodes('1600'), title: 'БАЛАНС' },
              ],
            }
          ],
        }
      ],
      opu: [
        {
          key: 'opu',
          cols: [
            { label: '', key: 'title' },
            { label: 'Код', key: 'code' },
            ...years,
          ],
          rows: [
            {
              header: [
                getReportsByCodes('2110'),
                getReportsByCodes('2400'),
              ],
              body: [
                // getReportsByCodes('2099', '2499')
                getReportsByCodes('2110'),
                getReportsByCodes('2120'),
                getReportsByCodes('2100'),
                getReportsByCodes('2210'),
                getReportsByCodes('2220'),
                getReportsByCodes('2200'),
                getReportsByCodes('2310'),
                getReportsByCodes('2320'),
                getReportsByCodes('2330'),
                getReportsByCodes('2340'),
                getReportsByCodes('2350'),
                getReportsByCodes('2300'),
                getReportsByCodes('2410'),
                getReportsByCodes('2411'),
                getReportsByCodes('2412'),
                getReportsByCodes('2460'),
                getReportsByCodes('2400'),
              ],
            },
            [
              Object.assign(
                {
                  title: 'Амортизация',
                  code: '',
                },
                ...getReportOfYear('1150', 8)
              )
            ]
          ],
        },
      ],
      odds: [
        {
          key: 'odds',
          cols: [
            { label: '', key: 'title' },
            { label: '', key: '' },
            ...years,
          ],
          rows: [
            [
              Object.assign(
                { title: 'Капитал / Активы', code: '', type: 'capital_assets' },
                ...getReportOfYear('')
              )
            ],
            [
              Object.assign(
                { title: 'Динамика выручки к предыдущему периоду', code: '', type: 'dynamics_of_revenue_year_to_year' },
                ...getReportOfYear('')
              )
            ],
            [
              Object.assign(
                {
                  title: 'EBITDA / (Краткосрочный долг - Денежные средства и процентные платежи)',
                  code: '',
                  type: 'EBITDA_short_term_debt_cash_and_interest_payments'
                },
                ...getReportOfYear('')
              )
            ],
            [
              Object.assign(
                { title: 'EBITDA / Общие обязательства', code: '', type: 'EBITDA_total_liabilities' },
                ...getReportOfYear('')
              )
            ],
            [
              Object.assign(
                { title: 'Мгновенная ликвидность', code: '', type: 'instant_liquidity' },
                ...getReportOfYear('')
              )
            ],
            [
              Object.assign(
                { title: 'Чистая прибыль / Себестоимость продаж', code: '', type: 'net_profit_cost_of_sales' },
                ...getReportOfYear('')
              )
            ],
            [
              Object.assign(
                { title: 'Выручка / Текущие активы', code: '', type: 'revenues_current_assets' },
                ...getReportOfYear('')
              )
            ],
            [
              Object.assign(
                {
                  title: 'Общие обязательства (текущий период) / Общие обязательства (предыдущий период)',
                  code: '',
                  type: 'total_liabilities_this_year_total_liabilities_previous_year'
                },
                ...getReportOfYear('')
              )
            ],

            [Object.assign({ title: 'Обеспеченность текущих активов СОС', code: '' }, ...getReportOfYear('security_of_current_assets'))],
            [Object.assign({ title: 'Финансовый леверидж', code: '' }, ...getReportOfYear('financial_leverage'))],
            [Object.assign({ title: 'Текущая ликвидность', code: '' }, ...getReportOfYear('current_liquidity'))],
            [Object.assign({ title: 'Рентабельность ebitda', code: '' }, ...getReportOfYear('profitability_ebitda'))],
            [Object.assign({ title: 'Долговая нагрузка', code: '' }, ...getReportOfYear('debt_burden'))],
            [Object.assign({ title: 'Обслуживание процентов', code: '' }, ...getReportOfYear('interest_service'))],
            [Object.assign({ title: 'Годовая выручка к НМЦК', code: '' }, ...getReportOfYear('annual_revenue_to_ncc'))],
          ],
        },
      ],
    }
  }
  return rawData
}

export default ordersFinReportSerializer
