import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import api from '../../api';
import { handleRequestError, isRoleJuniorUW } from '../../utils';
import HandbookViews from '../../views/HandbookViews';
import Preloader from '../../views/Preloader';
import Title from '../../views/Title';

const HandbookPresignals = () => {
  const [presignalsList, setPresignalsList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const { currentUser } = useStoreon('currentUser');
  const { getPresignalsList, patchPresignalsList } = api.handbooksApi;
  const patchRequest = (params, cardId) => {
    patchPresignalsList(params, cardId)
      .then(() => {
        toast.success('Изменения сохранены');
      })
      .catch(err => {
        toast.error(err.response.data.detail);
      })
  }
  const navigate = useNavigate();

  useEffect(() => {
    setIsFetching(true)
    getPresignalsList({
      catalog: 'FORCED',
      ordering: 'title'
    })
      .then(data => setPresignalsList(data))
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  return (
    <>
      <Title
        level='1'
        projectVariant='title_page'
      >
        Предупреждающие сигналы
      </Title>
      {isFetching ?
        <Preloader />
        :
        <HandbookViews.SFPSList
          data={presignalsList}
          patchRequest={patchRequest}
          contentType='ps'
          setReadOnly={isRoleJuniorUW(currentUser)}
        />
      }
    </>
  )
}

export default HandbookPresignals;
