import React from 'react';
import classNames from 'classnames';
import {
  GxSelect,
  GxMenuItem
} from '@garpix/garpix-web-components-react';
import style from './styles/index.module.scss';

const Select = ({
  list = [],
  projectVariant = 'select_default',
  placeholder,
  className,
  helpText,
  ...props
}) => {
  return (
    <GxSelect
      className={classNames({
        [style.select]: true,
        [style[projectVariant]]: !!projectVariant,
        [className]: !!className,
      })}
      placeholder={placeholder}
      {...props}
    >
      {
        list.map(item => (
          <GxMenuItem
            key={item.id}
            value={item.value}
          >
            {item.name}
          </GxMenuItem>
        ))
      }
      {helpText}
    </GxSelect>
  )
}

export default Select;
