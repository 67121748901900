import { Formik } from 'formik';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import api from '../../api';
import { MS_BEFORE_REDIRECT, PATHS } from '../../const';
import { handleRequestError } from '../../utils';
import { forgotPassSchema } from '../../utils/SchemasFormik';
import ForgotPasswordView from '../../views/ForgotPassword'

const ForgotPassword = () => {
  const { currentUser } = useStoreon('currentUser')
  const [message, setMessage] = useState('')
  const navigate = useNavigate()

  const errorsMessenge = {
    'no user': 'Пользователя с таким Email не существует'
  };

  const successMessenge = {
    'reset password link sended': 'На вашу почту была отправлена ссылка для восстановления. Вы будете перенаправлены на страницу входа'
  }

  const initialValues = { email: '' }

  const onSubmit = (data, { setFieldError }) => {
    return api.usersApi.getRecoverPasswordLink(data)
      .then(res => {
        if (res.message) setMessage(successMessenge[res.message])
        setTimeout(() => navigate(PATHS.AUTHORIZATION.path), MS_BEFORE_REDIRECT)
      })
      .catch((err) => {
        handleRequestError(err, navigate)
        if (err.response) {
          setFieldError('serverError', errorsMessenge[err.response.data.error]);
        }
      });
  }

  if (currentUser) navigate(PATHS.HOME.link)
  return (
    <Formik
      validationSchema={forgotPassSchema()}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur
    >
      {
        ({ handleSubmit, errors, handleBlur, handleChange, values, touched, isSubmitting }) => <ForgotPasswordView
          handleSubmit={handleSubmit}
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
          successMessage={message}
          isSubmitting={isSubmitting}
        />
      }
    </Formik>
  )

}

export default ForgotPassword
