import uuid4 from 'uuid/dist/v4';
import { numberWithSpaces } from '../../../utils';

const BankMatrixSerializer = (data) => {
  // Return correct sign in table body.
  const getSign = (index, cellData) => {
    if (cellData !== '') {
      if (index === 0) {
        const formatting = [];
        cellData.split(' - ').forEach(elem => { formatting.push(numberWithSpaces(elem)) });

        return {
          id: uuid4(),
          data: `${formatting[0]} - ${formatting[1]} руб.`
        }
      }
      return {
        id: uuid4(),
        data: `${cellData}%`
      }
    }
    return {
      id: uuid4(),
      data: '-'
    }
  }

  // Set table head.
  const tableHead = [
    {
      id: uuid4(),
      cells: []
    }
  ]
  data?.matrix?.header.forEach((cell, cellIndex) => {
    if (cellIndex !== 0) {
      tableHead[0].cells.push({
        id: uuid4(),
        data: `${cell} дней`
      })
    } else {
      tableHead[0].cells.push({
        id: uuid4(),
        data: 'Сумма гарантии (руб.)'
      })
    }
  })

  // Set table body.
  const tableBody = []
  data?.matrix?.body.forEach((rows, rowIndex) => {
    tableBody.push({
      id: uuid4(),
      cells: []
    })
    rows.forEach((cell, index) => {
      tableBody[rowIndex].cells.push(getSign(index, cell))
    })
  })

  const sortAlgo = /^\d+/;
  const sortFunc = (x, y) => {
    /* eslint-disable no-else-return */
    if (Number(x.cells[0].data.match(sortAlgo)) > Number(y.cells[0].data.match(sortAlgo))) {
      return 1;
    } else if (Number(x.cells[0].data.match(sortAlgo)) < Number(y.cells[0].data.match(sortAlgo))) {
      return -1;
    }
    return 0;
  }
  tableBody.sort(sortFunc)

  return {
    tableHead,
    tableBody
  }
}

export default BankMatrixSerializer;