import React from 'react';
import uuid4 from 'uuid/dist/v4'
import { checkCircle, cancel } from '../../../images'

const integrationCheckSerializer = (data = {}) => {
  const integrations = [
    {
      id: uuid4(),
      name: 'Расчет основного лимита',
      value: data.limit_principal_calculate
    },
    {
      id: uuid4(),
      name: 'Отчет с Olywer Wymans',
      value: data.olywer_wymans
    },
    {
      id: uuid4(),
      name: 'Обновление основного лимита',
      value: data.update_principal_limits
    },
    {
      id: uuid4(),
      name: 'Финансовые показатели контура',
      value: data.kontur_financial_indicators
    },
    {
      id: uuid4(),
      name: 'СФ/ПС',
      value: data.get_factors
    }
  ]

  return integrations.map(item => ({
    id: item?.id,
    cells: [
      {
        id: uuid4(),
        data: item?.name ?? '-'
      },
      {
        id: uuid4(),
        attributes: {
          'data-status': item ? 'good' : 'bad'
        },
        data: item.value
          ? <span>
            <img src={checkCircle} alt='' />
          </span>
          : <span>
            <img src={cancel} alt='' />
          </span>
      }
    ]
  }))
}

export default integrationCheckSerializer