import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { GxForm } from '@garpix/garpix-web-components-react';
import { logoLg } from '../../images'
import Title from '../Title/index';
import Input from '../Input/index';
import Button from '../Button/index';
import ErrorField from '../ErrorField';
import { PATHS } from '../../const';
import style from './styles/index.module.scss';

const RecoverPassword = ({
  handleSubmit,
  handleChange,
  handleBlur,
  errors,
  values,
  touched,
  message,
  isSubmitting,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames({
        [style.container]: true,
        [className]: !!className
      })}
      {...props}
    >
      <div className={style.logoBlock}>
        <img
          src={logoLg}
          alt='logo'
        />
      </div>
      <GxForm
        className={style.form}
        noValidate
        onGx-submit={handleSubmit}
      >
        <Title
          className={style.title}
          level='2'
          projectVariant='title_section'
        >
          Восстановить пароль
        </Title>
        <Input
          onGx-input={handleChange}
          onGx-blur={handleBlur}
          value={values.password}
          className={style.password}
          name='password'
          type='password'
          helpText={<ErrorField
            slot='help-text'
            message={touched.password && errors.password}
          />}
          label='Введите ваш пароль'
        />
        <ErrorField message={errors.serverError} />
        <p className={style.message}>{message}</p>
        <Button
          className={style.submit}
          type='submit'
          full
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Восстановление...' : 'Восстановить'}
        </Button>
        <NavLink
          className={style.link}
          to={PATHS.AUTHORIZATION.path}
        >
          Вернуться
        </NavLink>
      </GxForm>
      <p className={style.termsOfService}>
        <NavLink
          target='_blank'
          to={PATHS.TERMS_OF_SERVICE.path}
        >
          Политика конфиденциальности и обработки персональных данных
        </NavLink>
      </p>
    </div>
  );
}

export default RecoverPassword;