import React from 'react';
import classNames from 'classnames';
import { GxForm } from '@garpix/garpix-web-components-react';
import Input from '../../Input';
import ErrorField from '../../ErrorField'
import Textarea from '../../Textarea';
import Button from '../../Button';
import { numberWithSpaces } from '../../../utils'
import style from './styles/index.module.scss';

const EstimatedLimit = ({
  formik = {},
  values,
  calculatedLimit,
  isInputReadOnly,
  className,
  ...props
}) => {
  const formattedCalculatedLimit = numberWithSpaces(calculatedLimit)
  const totalLimit = formik.values?.total_limit ? formik.values?.total_limit : formattedCalculatedLimit
  return (
    <section
      className={classNames({
        [style.section]: true,
        [className]: !!className
      })}
      {...props}
    >
      <GxForm
        className={style.form}
        onGx-submit={formik.handleSubmit}
      >
        <fieldset>
          <span className={style.key}>Расчетный лимит</span>
          <p
            className={style.inputEstimated}
            title={formattedCalculatedLimit}
          >
            {formattedCalculatedLimit}
          </p>
        </fieldset>
        <Input
          name='total_limit'
          onGx-input={formik.handleChange}
          onGx-blur={formik.handleBlur}
          value={formik.values.total_limit}
          placeholder={formattedCalculatedLimit}
          readonly={isInputReadOnly()}
          helpText={<ErrorField
            slot='help-text'
            message={formik.touched.total_limit && formik.errors.total_limit}
          />}
        />
        <Textarea
          value={formik.values.content}
          onGx-input={formik.handleChange}
          onGx-blur={formik.handleBlur}
          name='content'
          className={style.textarea}
          rows='1'
          placeholder='Комментарий'
          readonly={isInputReadOnly()}
          helpText={<ErrorField
            slot='help-text'
            message={formik.touched.content && formik.errors.content}
          />}
        />
        <fieldset>
          <span className={style.key}>Итоговый лимит</span>
          <p
            className={style.inputTotal}
            title={totalLimit}
          >
            {totalLimit}
          </p>
        </fieldset>
        <Button
          className={style.submit}
          type='submit'
          disabled={isInputReadOnly() || formik.values.content === ''}
        >
          Подтвердить
        </Button>
      </GxForm>
    </section>
  )
}

export default EstimatedLimit;