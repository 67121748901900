import React from 'react';
import classNames from 'classnames';
import { GxIcon, GxButton } from '@garpix/garpix-web-components-react';
import { arrowDown } from '../../images';
import style from './styles/index.module.scss';

const Button = ({
  spoiler = false,
  spoilerState = false,
  projectVariant = 'button_default',
  onClick,
  className,
  children,
  ...props
}) => {
  return (
    <GxButton
      onClick={onClick}
      className={classNames({
        [style[projectVariant]]: true,
        [className]: !!className,
      })}
      {...props}
    >
      {children}
      {spoiler &&
        <GxIcon
          className={classNames({
            [style.spoilerIcon]: true,
            [style.spoilerOpened]: spoilerState
          })}
          slot='icon-right'
          src={arrowDown}
        />
      }
    </GxButton>
  )
}

export default Button