import React from 'react';
import uuid4 from 'uuid/dist/v4';
import { useModal } from '../../hooks';
import Title from '../Title';
import SimpleTable from '../SimpleTable';
import Button from '../Button';
import Modal from '../ModalCreator';
import Preloader from '../Preloader';
import style from './styles/integrationCheck.module.scss';

const IntegrationCheck = ({
  data = [],
  handleChangeStatus,
  isInPending = false,
  className,
  isOpen,
  ...props
}) => {
  const { closeModal } = useModal();
  const getOverlayDismiss = evt => {
    if (handleChangeStatus) {
      return evt.preventDefault();
    }
    return true;
  }
  const tableHead = [
    {
      id: uuid4(),
      cells: [
        {
          id: uuid4(),
          data: 'Название интеграции'
        },
        {
          id: uuid4(),
          data: 'Статус'
        },
      ]
    }
  ]

  return (
    <Modal
      isOpen={isOpen}
      projectVariant='modal_without-cross'
      onGx-overlay-dismiss={getOverlayDismiss}
      onHide={handleChangeStatus}
    >
      <div
        className={className}
        {...props}
      >
        <Title
          className={style.ttl}
          level='2'
          projectVariant='title_section'
        >
          Проверка интеграций
        </Title>
        <div className={style.tableWrapper}>
          {!isInPending ?
            <SimpleTable
              tableHead={tableHead}
              tableBody={data}
            />
            :
            <Preloader className={style.preloader} />
          }
        </div>
        {handleChangeStatus ?
          <Button
            onClick={handleChangeStatus}
            full
            disabled={isInPending}
          >
            Перейти к работе с заявкой
          </Button>
          :
          <Button
            onClick={closeModal}
            full
            disabled={isInPending}
          >
            Закрыть окно
          </Button>
        }
      </div>
    </Modal>
  )
}

export default IntegrationCheck;