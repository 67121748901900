import React from 'react';
import classNames from 'classnames';
import Button from '../../Button';
import style from './styles/index.module.scss';

const AsideChat = ({
  className,
  ...props
}) => {
  return (
    <aside
      className={classNames(
        [style.container],
        { [className]: !!className }
      )}
      {...props}
    >
      <div className={style.top}>
        <Button
          className={style.btn}
          projectVariant='button_wide'
          full
        >
          Заявка №???
        </Button>
        <Button
          className={style.btn}
          projectVariant='button_wide'
          full
        >
          Техподдержка
        </Button>
      </div>
      <Button
        className={style.btn}
        projectVariant='button_wide'
        full
      >
        + Новая ветка
      </Button>
    </aside>
  )
}

export default AsideChat;