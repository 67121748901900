import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../api';
import OrderViews from '../../views/OrderViews';
import CollapseTable from '../../views/CollapseTable';
import Preloader from '../../views/Preloader'
//  import { handleRequestError, numberWithSpaces } from '../../utils';
import { handleRequestError } from '../../utils';
import style from './index.module.scss'

const OrderScoring = () => {
  const [order, setOrder] = useState({})
  const [scoring, setScoring] = useState({});
  const [isFetching, setIsFetching] = useState(false)

  const { getOrderById, getOrderScoringTendersBanksly } = api.ordersApi
  const { orderId } = useParams()
  const navigate = useNavigate()

//  const getValue = { null: '-', true: 'Да', false: 'Нет' };

  const scoringToView = scoring?.probability_ratio ? Number(scoring.probability_ratio) * 100 : ''

  const tableData = {
    key: 'active',
    cols: [
      { label: '', key: 'title' },
      { label: '', key: 'value' },
    ],
//    rows: [
//      [
//        { title: 'Предупреждающие сигналы', value: '' },
//        { title: 'Наличие задолженности по уплате налогов', value: getValue[scoring?.signals_BS_1] },
//        { title: 'Отсутствие по юридическому адресу', value: getValue[scoring?.signals_BS_2] },
//        { title: 'Входит на данный момент в реестр недобросовестных поставщиков', value: getValue[scoring?.signals_BS_5] },
//        { title: 'Ранее входил в реестр недобросовестных поставщиков', value: getValue[scoring?.signals_BS_6] },
//        { title: 'Не предоставление налоговой отчетности более года', value: getValue[scoring?.signals_BS_7] },
//        { title: 'Есть активные дела по банкротству в отношении компании', value: getValue[scoring?.signals_BS_10] },
//        { title: 'Наличие исполнительного производства по заработной плате за последний год', value: getValue[scoring?.signals_BS_14] },
//        { title: 'Наличие исполнительного производства с арестом на сумму более 100 тыс. руб.', value: getValue[scoring?.signals_BS_15] },
//        { title: 'Наличие в реестре дисквалифицированных лиц', value: getValue[scoring?.signals_BS_16] },
//        { title: 'Наличие флага банкротства', value: getValue[scoring?.signals_BS_18] },
//        { title: 'Отношение суммы закупки к выручке больше 1.5', value: getValue[scoring?.signals_BS_22] },
//        { title: 'Ранее были раскрытые гарантии', value: getValue[scoring?.signals_BS_23] },
//        { title: 'Наличие активных судов против текущего заказчика в качестве ответчика', value: getValue[scoring?.signals_BS_111] },
//        { title: 'Выручка больше 1 млрд.', value: getValue[scoring?.signals_GS_0] },
//      ],
//      [
//        { title: 'Арбитражные суды', value: '' },
//        { title: 'Количество судебных дел в качестве ответчика за последние 2 года ', value: scoring?.courts_1 },
//        { title: 'Средняя длительность судебного дела за последние 2 года', value: scoring?.courts_2 },
//        { title: 'Количество судебных дел в качестве истца за последние 2 года', value: scoring?.courts_3 },
//        { title: 'Общая сумма судов в качестве ответчика за последний год', value: numberWithSpaces(scoring?.courts_4, true) },
//        { title: 'Общая сумма судов в качестве ответчика за последний год / Чистая прибыль', value: numberWithSpaces(scoring?.courts_5, true) },
//        { title: 'Общая сумма судов в качестве ответчика за последний год / EBITDA', value: numberWithSpaces(scoring?.courts_6, true) },
//      ],
//      [
//        { title: 'Исполнительные производства', value: '' },
//        { title: 'Количество исполнительных производств по значимым делам за последние 2 года', value: scoring?.executory_1 },
//        { title: 'Сумма исполнительных производств за последний год / Чистая прибыль', value: numberWithSpaces(scoring?.executory_2, true) },
//      ],
//      [
//        { title: 'Тендеры', value: '' },
//        { title: 'Количество тендеров за последний год', value: scoring?.tenders_1 },
//        { title: 'Сумма тендеров за последний год / Выручка', value: numberWithSpaces(scoring?.tenders_4, true) },
//        { title: 'Количество выигранных тендеров с данным заказчиком за последние 5 лет', value: scoring?.tenders_2 },
//      ],
//    ]
    rows: [
      [
      { title: 'Арбитражные суды', value: '' },
      { title: 'Число исков между принципалом (в роли ответчика) и бенефициаром (в роли истца) за последние 2 года',
        value: String(scoring?.principal_respondent_with_customer_2y_count) },
      { title: 'Общая сумма исков между принципалом (в роли ответчика) и бенефициаром (в роли истца) за последние 2 года',
        value: String(scoring?.principal_respondent_with_customer_2y_sum_rub) },
      { title: 'Число исков между принципалом (в роли истца) и бенефициаром (в роли ответчика) за последние 2 года',
        value: String(scoring?.principal_plaintiff_with_customer_2y_count) },
      { title: 'Сумма исков между принципалом (в роли истца) и бенефициаром (в роли ответчика) за последние 2 года',
        value: String(scoring?.principal_plaintiff_with_customer_2y_sum_rub) },
      { title: 'Число исков к принципалу (в роли ответчика) за скользящий год',
        value: String(scoring?.principal_respondent_1y_count) },
      { title: 'Отношение суммы исков к принципалу (в роли ответчика) за скользящий год к его выручке',
        value: String(scoring?.principal_respondent_sum_rub_to_revenue_1y_ratio) },
      { title: 'Число активных исков к принципалу (в роли ответчика)',
        value: String(scoring?.principal_respondent_active_count) },
      { title: 'Отношение суммы активных исков к принципалу (в роли ответчика) к его выручке',
        value: String(scoring?.principal_respondent_sum_rub_to_revenue_active_ratio) },
      ],
      [
        { title: 'Финансовые факторы', value: '' },
        { title: 'Отношение прибыли (убытка) к выручке',
        value: String(scoring?.net_profit_loss_to_revenue_ratio) },
        { title: 'Отношение краткосрочных обязательств к активам',
        value: String(scoring?.short_term_liabilities_to_assets_ratio) },
        { title: 'Отношение краткосрочных займов к активам',
        value: String(scoring?.short_borrowed_funds_to_assets_ratio) },
        { title: 'Отношение капитала к обязательствам',
        value: String(scoring?.capital_to_all_liabilities_ratio) },
        { title: 'Отношение краткосрочных обязательств к обязательствам',
        value: String(scoring?.short_term_liabilities_to_all_liabilities_ratio) },
        { title: 'Отношение выручки к выручке за предыдущий год',
        value: String(scoring?.revenue_to_revenue_prev_ratio) },
      ],
      [
        { title: 'Тендеры', value: '' },
        { title: 'Отношение цены закупки к выручке принципала',
        value: String(scoring?.purchase_sum_to_principal_revenue_ratio) },
        { title: 'Отношение цены закупки к сумме закупок принципала за скользящий год',
        value: String(scoring?.purchase_sum_to_principal_purchases_sum_1y_ratio) },
        { title: 'Отношение суммы закупок за скользящий год к выручке принципала',
        value: String(scoring?.principal_purchases_sum_1y_to_revenue_ratio) },
        { title: 'Отношение суммы закупок за скользящий год к предыдущему скользящему году у принципала',
        value: String(scoring?.principal_purchases_sum_to_purchases_sum_prev_ratio) },
        { title: 'Число закупок между принципалом и бенефициаром за последние 2 скользящих года',
        value: String(scoring?.principal_to_customer_purchases_2y_count) },
        { title: 'Сумма закупок между принципалом и бенефициаром за последние 2 скользящих',
        value: String(scoring?.principal_to_customer_purchases_2y_sum_rub) },
        { title: 'Число закупок бенефициара за скользящий год, число от 0 до 100 (где 100 может означать "100 и более")',
        value: String(scoring?.customer_purchases_1y_count) },
        { title: 'Флаг наличия принципала в реестре недобросовестных поставщиков',
        value: String(scoring?.is_principal_in_rnp) },
      ],
    ]
  }

  useEffect(() => {
    setIsFetching(true)
    getOrderById(orderId)
      .then(data => setOrder(data))
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  useEffect(() => {
    if (Object.keys(order).length !== 0) {
      const params = {
        order: orderId
      };
      getOrderScoringTendersBanksly(params)
        .then(data => {
          setScoring(data[0]);
        })
        .catch(err => handleRequestError(err, navigate));
    }
  }, [order])

  return (
    <>
      <OrderViews.Header
        orderId={order.id}
        sum={order.sum}
        principal={order.principal}
        fz={order.contest?.fz?.fz}
        status={order.state}
      />
      {isFetching ?
        <Preloader />
        :
        <>
          <OrderViews.ScoringOW scoring={scoringToView} />
          <OrderViews.Diagram scoring={scoring} />

          <CollapseTable customClassName={style.table} tableData={tableData} />
        </>
      }
    </>
  )
}

export default OrderScoring;