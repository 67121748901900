import React from 'react';
import classNames from 'classnames';
import { GxForm } from '@garpix/garpix-web-components-react';
import Title from '../Title';
import Input from '../Input';
import Button from '../Button';
import ErrorField from '../ErrorField';
import style from './styles/individualQuote.module.scss';
import Select from '../Select';
import Modal from '../ModalCreator';

const IndividualQuote = ({
  formik = {},
  handleClose,
  className,
  banks,
  isOpen,
  ...props
}) => {
  return (
    <Modal projectVariant='modal_without-cross' isOpen={isOpen} >
      <GxForm
        onGx-submit={formik.handleSubmit}
        className={classNames({
          [style.container]: true,
          [className]: !!className
        })}
        {...props}
      >
        <Title className={style.title} level='3' projectVariant='title_section'>Индивидуальная котировка</Title>
        <Select
          list={banks}
          placeholder='Банки'
          name='bank'
          onGx-change={formik.handleChange}
          value={formik.values?.bank}
          className={style.input}
          helpText={<ErrorField
            slot='help-text'
            message={formik.errors?.bank}
          />}
        />
        <Input
          name='individual_guarantee_rate'
          className={style.input}
          onGx-input={formik.handleChange}
          value={formik.values?.individual_guarantee_rate}
          placeholder='Введите значение, %'
          helpText={<ErrorField
            slot='help-text'
            message={formik.errors?.individual_guarantee_rate}
          />}
        />
        <div className={style.controls}>
          <Button
            className={style.submit}
            type='submit'
          >
            {formik.isSubmitting ? 'Отправка...' : 'Отправить'}
          </Button>
          <Button
            className={style.reset}
            projectVariant='button_transparent'
            type='reset'
            onClick={handleClose}
          >
            Отмена
          </Button>
        </div>
      </GxForm>
    </Modal >
  )
}

export default IndividualQuote;