import AbstractBaseApi from '../AbstractBaseApi';

export default class UsersApi extends AbstractBaseApi {
  login = async (params) => {
    const res = await this.post('login/', params)
    this.setAuthToken(res.data.access_token, res.data.id, 1);
    return res.data
  }

  logout = async (params) => {
    const res = await this.post('logout/', params)
    this.removeAuthToken()
    return res.data
  }

  recoverPassword = async (params) => {
    const res = await this.post('user/user/reset_password/', params)
    return res.data
  }

  getRecoverPasswordLink = async (params) => {
    const res = await this.post('user/user/get_reset_password_link/', params)
    return res.data
  }

  getUserUnderwriters = async () => {
    const res = await this.get('user/user/get_underwriter_list/')
    return res.data
  };

  getUserById = async (id) => {
    const res = await this.get(`user/user/${id}/`)
    return res.data
  }
}
