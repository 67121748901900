import React from 'react';
import classNames from 'classnames';
import { GxForm } from '@garpix/garpix-web-components-react';
import Title from '../Title';
import Input from '../Input';
import Select from '../Select';
import Button from '../Button';
import DoubleRange from '../DoubleRange';
import ErrorField from '../ErrorField'
import Modal from '../ModalCreator';
import style from './styles/orderListFilter.module.scss';

const OrderListFilter = ({
  formik = {},
  contestTypesList = [],
  statusesList = [],
  resetForm,
  minValueBetween,
  maxValue,
  isRendered,
  className,
  isOpen,
  ...props
}) => {
  return (
    <Modal isOpen={isOpen}>
      <div
        className={classNames(
          style.container,
          { [className]: !!className }
        )}
        {...props}
      >
        <Title
          className={style.ttl}
          level='2'
          projectVariant='title_section'
        >
          Фильтр
        </Title>
        <GxForm
          noValidate
          onGx-submit={formik.handleSubmit}
        >
          <div className={style.formInner}>
            <fieldset className={style.block}>
              <Title
                className={style.block__ttl}
                level='3'
                projectVariant='title'
              >
                Год
              </Title>
              <div className={style.block__range}>
                <Input
                  name='yearMin'
                  className={classNames([
                    style.block__ie,
                    style.block__ieYear,
                    style.block__ieYearFrom
                  ])}
                  type='number'
                  placeholder='С'
                  onGx-input={formik.handleChange}
                  value={formik.values.yearMin}
                  min='2000'
                  max={new Date().getFullYear()}
                  helpText={<ErrorField
                    slot='help-text'
                    message={formik.errors.yearMin}
                  />}
                />
                <Input
                  name='yearMax'
                  className={classNames([
                    style.block__ie,
                    style.block__ieYear,
                    style.block__ieYearUntil
                  ])}
                  type='number'
                  placeholder='По'
                  onGx-input={formik.handleChange}
                  value={formik.values.yearMax}
                  min='2000'
                  max={new Date().getFullYear()}
                  helpText={<ErrorField
                    slot='help-text'
                    message={formik.errors.yearMax}
                  />}
                />
              </div>
            </fieldset>
            <fieldset className={style.block}>
              <Title
                className={style.block__ttl}
                level='3'
                projectVariant='title'
              >
                Вид гарантии
              </Title>
              <Select
                name='contest__type'
                onGx-change={formik.handleChange}
                value={formik.values.contest__type}
                className={classNames([
                  style.block__ie,
                  style.block__ieType
                ])}
                list={contestTypesList}
                placeholder='Не выбрано'
              />
            </fieldset>
            <fieldset className={style.block}>
              <Title
                className={style.block__ttl}
                level='3'
                projectVariant='title'
              >
                Статус заявки
              </Title>
              <Select
                name='state'
                onGx-change={formik.handleChange}
                value={formik.values.state}
                className={classNames([
                  style.block__ie,
                  style.block__ieStatus
                ])}
                list={statusesList}
                placeholder='Не выбрано'
              />
            </fieldset>
            <fieldset className={style.block}>
              <Title
                className={style.block__ttl}
                level='3'
                projectVariant='title'
              >
                Число последних заявок
              </Title>
              <div className={style.block__ttl}>
                <Input
                  name='last_n'
                  type='number'
                  placeholder='10'
                  onGx-input={formik.handleChange}
                />
              </div>
            </fieldset>
            <fieldset className={style.block}>
              <Title
                className={style.block__ttl} level='3'
                projectVariant='title'
              >
                Сумма гарантии
              </Title>
              <DoubleRange
                isRendered={isRendered}
                setFieldValue={formik.setFieldValue}
                inputMin={formik.values.guarantySumMin}
                inputMax={formik.values.guarantySumMax}
                onInputMax={formik.handleChange}
                onInputMin={formik.handleChange}
                inputMinName='guarantySumMin'
                inputMaxName='guarantySumMax'
                max={maxValue}
                minValueBetween={minValueBetween}
              />
            </fieldset>
            <div className={classNames([
              style.block,
              style.controls
            ])}>
              <Button
                className={classNames([
                  style.block__control,
                  style.block__reset
                ])}
                projectVariant='button_transparent'
                type='reset'
                onClick={resetForm}
                full
              >
                Сбросить
              </Button>
              <Button
                className={classNames([
                  style.block__control,
                  style.block__submit
                ])}
                type='submit'
                full
              >
                Применить
              </Button>
            </div>
          </div>
        </GxForm>
      </div>
    </Modal>
  )
}

export default OrderListFilter;