import React from 'react';
import Layout from '../views';
import OrderHistory from '../components/OrderHistory';

const OrderHistoryPage = (props) => {
  return (
    <Layout {...props}>
      <OrderHistory />
    </Layout>
  )
}

export default OrderHistoryPage;