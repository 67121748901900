// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrderFinreporting_finReporting__2peY8 {\n  margin-top: 10px;\n  overflow-x: auto;\n}", "",{"version":3,"sources":["webpack://src/components/OrderFinreporting/index.module.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,gBAAA;AADF","sourcesContent":["@import \"../../styles/global/index.scss\";\n\n.finReporting {\n  margin-top: 10px;\n  overflow-x: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"finReporting": "OrderFinreporting_finReporting__2peY8"
};
export default ___CSS_LOADER_EXPORT___;
