import React from 'react'
import Layout from '../views'
import PrincipalPortfolio from '../components/PrincipalPortfolio'

const PrincipalPortfolioPage = ({ orderListHead, ...props }) => {
  return (
    <Layout {...props}>
      <PrincipalPortfolio orderListHead={orderListHead} />
    </Layout>
  )
}

export default PrincipalPortfolioPage
