import React from 'react';
import Layout from '../views';
import PrincipalFinreporting from '../components/PrincipalFinreporting'

const PrincipalFinreportingPage = (props) => {
  return (
    <Layout {...props}>
      <PrincipalFinreporting />
    </Layout>
  )
}

export default PrincipalFinreportingPage