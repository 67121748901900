import React from 'react'
import LimitsBanks from '../components/LimitsBanks'
import Layout from '../views'

const LimitsBanksPage = (props) => {
  return (
    <Layout {...props}>
      <LimitsBanks />
    </Layout>
  )
}

export default LimitsBanksPage