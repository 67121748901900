import React from 'react';
import classNames from 'classnames';
import UnderwritersCard from './UnderwritersCard';
import style from './styles/index.module.scss';

const UnderwritersList = ({
  data,
  className,
  ...props
}) => {
  return (
    <ul className={classNames({
      [style.list]: true,
      [className]: !!className
    })}>
      {data.map(item => (
        <li
          key={item.id}
          {...props}
        >
          <UnderwritersCard data={item} />
        </li>
      ))}
    </ul>
  )
}

export default UnderwritersList;
