import AbstractBaseApi from '../AbstractBaseApi';

export default class BankApi extends AbstractBaseApi {
  getBankList = async () => {
    const res = await this.get('bank/bank/');
    return res.data;
  }

  getBankById = async (id) => {
    const res = await this.get(`bank/bank/${id}/`)
    return res.data
  }

  getBankDocumentsById = async (id) => {
    const res = await this.get(`bank/document/${id}/`)
    return res.data
  }

  getBankSections = async () => {
    const res = await this.get('bank/section/');
    return res.data;
  }

  getBankSectionsById = async (id) => {
    const res = await this.get(`bank/section/${id}/`)
    return res.data
  }

  getBankLimitsById = async (id) => {
    const res = await this.get(`bank/bank_limits/${id}/`)
    return res.data
  }

  getBankMatrix = async (params) => {
    const res = await this.get('bank/matrix/', params)
    return res.data
  }
}