// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_icon__URhBu {\n  --icon-width: 20px;\n  --icon-height: 20px;\n  transform: rotate(0deg);\n  transition: 0.3s;\n}\n\n.styles_icon-rotate__r0mTe {\n  transform: rotate(180deg);\n}", "",{"version":3,"sources":["webpack://src/views/Icon/styles/index.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AADF;;AAIA;EACE,yBAAA;AADF","sourcesContent":["@import \"../../../styles/global/index.scss\";\n\n.icon {\n  --icon-width: 20px;\n  --icon-height: 20px;\n  transform: rotate(0deg);\n  transition: 0.3s;\n}\n\n.icon-rotate {\n  transform: rotate(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "styles_icon__URhBu",
	"icon-rotate": "styles_icon-rotate__r0mTe"
};
export default ___CSS_LOADER_EXPORT___;
