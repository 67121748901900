import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { PATHS } from '../../const'
import Login from '../../components/Login'
import RecoverPassword from '../../components/RecoverPassword'
import ForgotPassword from '../../components/ForgotPassword'
import {
  OrderListPage,
  BankListPage,
  UnderwritersPage,
  FAQPage,
  ChatPage,
  ProfilePage,
  Error404Page,
  Error500Page
} from '../../pages'

const orderListHead = [
  {
    id: 1,
    cells: [
      {
        id: 1,
        data: 'Номер',
        sortName: 'id',
      },
      {
        id: 2,
        data: 'Принципал',
        sortName: 'principal',
      },
      {
        id: 3,
        data: 'Сумма гарантии',
        sortName: 'sum',
      },
      {
        id: 4,
        data: 'Вид',
        sortName: 'contest__type',
      },
      {
        id: 5,
        data: 'Статус',
        sortName: 'state',
      },
      {
        id: 6,
        data: 'UW',
        sortName: 'underwriter',
      },
      {
        id: 7,
        data: 'Дата',
        sortName: 'start_date',
      },
    ]
  }
]

const OrderList = () => <OrderListPage orderListHead={orderListHead} />
const BankList = () => <BankListPage />
const Underwriters = () => <UnderwritersPage />
const FAQ = () => <FAQPage />
const Chat = () => <ChatPage />
const Profile = () => <ProfilePage />
const Error404 = () => <Error404Page />
const Error500 = () => <Error500Page />

const OtherRoutes = () => {
  return (
    <Routes>
      <Route index element={<OrderList />} />
      <Route path={PATHS.AUTHORIZATION.path} element={<Login />} />
      <Route path={PATHS.RECOVER_PASSWORD.path} element={<RecoverPassword />} />
      <Route path={PATHS.FORGOT_PASSWORD.path} element={<ForgotPassword />} />
      <Route path={PATHS.BANKS.path} element={<BankList />} exact={PATHS.BANKS.exact} />
      <Route path={PATHS.UNDERWRITERS.path} element={<Underwriters />} />
      <Route path={PATHS.FAQ.path} element={<FAQ />} />
      <Route path={PATHS.CHAT.path} element={<Chat />} />
      <Route path={PATHS.PROFILE.path} element={<Profile />} />
      <Route path={PATHS.ERROR500.path} element={<Error500 />} />
      <Route path={PATHS.ERROR404.path} element={<Error404 />} />
      <Route path='*' element={<Error404 />} />
    </Routes>
  )
}

export default OtherRoutes
