import React from 'react';
import Layout from '../views';
import OrderStopFactors from '../components/OrderStopFactors';

const OrderStopFactorsPage = (props) => {
  return (
    <Layout {...props}>
      <OrderStopFactors />
    </Layout>
  )
}

export default OrderStopFactorsPage;