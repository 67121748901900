import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreon } from 'storeon/react'
import api from '../../api';
import { PATHS } from '../../const';
import { handleRequestError } from '../../utils';
import Title from '../../views/Title';
import MiscViews from '../../views/MiscViews';

const mock = [
  {
    listName: 'Общие настройки',
    list: [
      {
        link: '#section_pers',
        name: 'Персональные данные'
      },
      {
        link: '#',
        name: 'Смена пароля'
      },
      {
        link: '#',
        name: 'Паспортные данные'
      },
      {
        link: '#',
        name: 'Реквизиты'
      }
    ]
  },
  {
    listName: 'Настройки уведомлений',
    list: [
      {
        link: '#',
        name: 'Уведомления по E-mail'
      },
      {
        link: '#',
        name: 'Уведомления на рабочем столе'
      }
    ]
  },
  {
    listName: 'Настройки системы',
    list: [
      {
        link: '#',
        name: 'Настройки системы'
      }
    ]
  }
]

const Profile = () => {
  const navigate = useNavigate();
  const { dispatch } = useStoreon();
  const { currentUser } = useStoreon('currentUser');

  const handleAccQuit = () => {
    api.usersApi.logout({ email: currentUser?.email })
      .then(() => {
        dispatch('user/save', null)
        navigate(PATHS.AUTHORIZATION.path)
      })
      .catch(err => handleRequestError(err, navigate))
  }

  return (
    <MiscViews.FullHeightPageWrapper>
      <Title
        level='1'
        projectVariant='title_section'
      >
        Профиль
      </Title>
      <MiscViews.Layout>
        <MiscViews.AsideLinks data={mock} />
        <MiscViews.Profile handleAccQuit={handleAccQuit} />
      </MiscViews.Layout>
    </MiscViews.FullHeightPageWrapper>
  )
}

export default Profile