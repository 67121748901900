import React from 'react';
import classNames from 'classnames';
import Button from '../../Button';
import style from './styles/ProfileButtons.module.scss';

const ProfileButtons = ({
  handleAccQuit,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        [style.container],
        { [className]: !!className }
      )}
      {...props}
    >
      <Button
        className={style.submit}
        type='submit'
      >
        Сохранить изменения
      </Button>
      <Button
        className={style.accQuit}
        projectVariant='button_transparent'
        type='button'
        onClick={handleAccQuit}
      >
        Выйти из системы
      </Button>
    </div>
  )
}

export default ProfileButtons;