/* eslint-disable camelcase */
import React from 'react';
import classNames from 'classnames';
import style from './styles/index.module.scss';
import { formatPercentages, numberWithSpaces } from '../../../utils';

const QueryIndicators = ({
  rating,
  scoring,
  stop_factors,
  pre_signals,
  principalTotalLimit,
  principalAvailableLimit,
  className,
  ...props
}) => {
  const getIndicatorColor = (value) => {
    switch (value) {
      case true:
        return style.queryIndicators__item_redBorder
      case false:
        return style.queryIndicators__item_greenBorder
      default:
        return style.queryIndicators__item_yellowBorder
    }
  }
  const customIndicatorItemClassName = (value) => classNames({
    [style.queryIndicators__item]: true,
    [getIndicatorColor(value)]: true,
  })

  return (
    <section
      className={classNames({
        [style.queryIndicators]: true,
        [className]: !!className
      })}
      {...props}
    >
      <div className={customIndicatorItemClassName(false)}>
        <p className={style.queryIndicators__title}>Рейтинг</p>
        <p className={style.queryIndicators__value}>{`${rating?.letter_rating || '-'} ; ${rating?.ball_rating || '-'}`}</p>
      </div>
      <div className={customIndicatorItemClassName(stop_factors)}>
        <p className={style.queryIndicators__title}>Стоп-факторы</p>
        <p className={style.queryIndicators__value}>{stop_factors ? 'Да' : 'Нет'}</p>
      </div>
      <div className={customIndicatorItemClassName()}>
        <p className={style.queryIndicators__title}>Лимит на принципала</p>
        <p className={style.queryIndicators__value}>{numberWithSpaces(principalTotalLimit, true) || '-'}</p>
      </div>
      <div className={customIndicatorItemClassName(!scoring)}>
        <p className={style.queryIndicators__title}>Скоринг Banksly</p>
        <p className={style.queryIndicators__value}>{scoring ? `${formatPercentages(scoring)}%` : '-'}</p>
      </div>
      <div className={customIndicatorItemClassName(pre_signals)}>
        <p className={style.queryIndicators__title}>Предсигналы</p>
        <p className={style.queryIndicators__value}>{pre_signals ? 'Да' : 'Нет'}</p>
      </div>
      <div className={customIndicatorItemClassName()}>
        <p className={style.queryIndicators__title}>Доступность лимита</p>
        <div className={style.queryIndicators__value}>{numberWithSpaces(principalAvailableLimit, true) || '-'}</div>
      </div>
    </section>
  )
}

export default QueryIndicators
