import React from 'react';
import classNames from 'classnames';
import style from './styles/index.module.scss';

const Title = ({
  className,
  projectVariant = 'title_page',
  level,
  children,
  ...props
}) => {
  const customClassName = classNames({
    [style[projectVariant]]: true,
    [className]: !!className
  });
  switch (level) {
    case '1':
      return <h1 className={customClassName} {...props}>{children}</h1>
    case '2':
      return <h2 className={customClassName} {...props}>{children}</h2>
    case '3':
      return <h3 className={customClassName} {...props}>{children}</h3>
    case '4':
      return <h4 className={customClassName} {...props}>{children}</h4>
    case '5':
      return <h5 className={customClassName} {...props}>{children}</h5>
    case '6':
      return <h6 className={customClassName} {...props}>{children}</h6>
    default:
      return <h1 className={customClassName} {...props}>{children}</h1>
  }
}

export default Title;