import React, { useState } from 'react';
import classNames from 'classnames';
import Title from '../Title/index';
import Button from '../Button/index';
import Select from '../Select/index';
import style from './styles/setUnderwriter.module.scss';
import Modal from '../ModalCreator';

const SetUnderwriter = ({
  onSubmit,
  onAbort,
  value,
  underwriters,
  className,
  isOpen,
  ...props
}) => {
  const [selectedUnderwriter, setSelectedUnderwriter] = useState(value);

  const listOfUnderwriters = underwriters.map(underwriter => ({
    id: underwriter.id,
    value: underwriter.id,
    name: `${underwriter.first_name} ${underwriter.patronymic} ${underwriter.last_name}`,
  }))

  return (
    <Modal isOpen={isOpen} projectVariant='modal_without-cross'>
      <div
        className={classNames({
          [style.container]: true,
          [className]: !!className
        })}
        {...props}
      >
        <Title
          className={style.title}
          level='2'
          projectVariant='title_section'
        >
          Назначить андеррайтера
        </Title>
        <Select
          className={style.select}
          placeholder='Список'
          list={listOfUnderwriters}
          value={selectedUnderwriter}
          onGx-change={evt => setSelectedUnderwriter(evt.target.value)}
        />
        <Button
          className={style.submit}
          onClick={() => onSubmit(selectedUnderwriter)}
        >
          Назначить
        </Button>
        <Button
          className={style.abort}
          onClick={onAbort}
          projectVariant='button_transparent'
        >
          Отмена
        </Button>
      </div>
    </Modal>
  )
}

export default SetUnderwriter;
