import React from 'react'
import Portfolio from '../components/Portfolio'
import Layout from '../views'

const PortfolioPage = ({ portfolioSection, orderListHead, pageTitle, ...props }) => {
  return (
    <Layout {...props}>
      <Portfolio orderListHead={orderListHead} portfolioSection={portfolioSection} pageTitle={pageTitle} />
    </Layout>
  )
}

export default PortfolioPage