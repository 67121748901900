// import { setErrorHandler } from '@garpix/garpix-web-components/dist/types/stencil-public-runtime'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Formik } from 'formik'
import { useParams, useNavigate } from 'react-router-dom' // useNavigate } from 'react-router-dom'
import OrderViews from '../../views/OrderViews'
import CollapseTable from '../../views/CollapseTable'
import api from '../../api'
import { handleRequestError, getFinReportByCode } from '../../utils'
import { ORDER_COMMENTS_PAGES } from '../../const'
import Preloader from '../../views/Preloader'

const OrderRating = () => {
  const [order, setOrder] = useState({})
  const [rating, setRating] = useState({})
  const [finReport, setFinReport] = useState({})
  const [checkedRating, setCheckedRating] = useState(0)
  const [comment, setComment] = useState('')
  const [isFetching, setIsFetching] = useState(false)

  const { orderId } = useParams()
  const navigate = useNavigate()
  const { getRating, updateRating } = api.limitsApi
  const { getOrderById, postOrderComment, getOrderRawAccountingForms, getComment } = api.ordersApi

  const initialValues = {
    content: comment,
  }

  const isInputReadOnly = () => comment !== '' && rating.ball_rating_update
  const handleChangeCheckRating = (e) => setCheckedRating(e.target.value)
  const onSubmit = ({ content }) => {
    const commentParams = { content, order: order.id, page: ORDER_COMMENTS_PAGES.RATING }
    postOrderComment(commentParams)

    const handleUpdateRating = () => updateRating(rating?.id, { delta: checkedRating, ball_rating_update: true })
    return toast.promise(handleUpdateRating, {
      success: 'Рейтинг успешн обновлен',
      pending: 'Обновление рейтинга...',
    })
      .then(ratingData => {
        setRating(ratingData)
        setComment(content)
      })
      .catch(err => handleRequestError(err))
  }
  const ratingData = {
    key: 'Rating',
    cols: [
      { label: 'Показатель', key: 'score' },
      { label: 'Веc', key: 'weight' },
      { label: 'Балл', key: 'rating' },
    ],
    rows: [
      [
        { score: 'Финансовые показатели', weight: '50', rating: rating?.financialIndicators ?? '-', },
        { score: 'Обеспеченность текущих активов СОС', weight: '16', rating: finReport?.securityOfCurrentAssets ?? '-' },
        { score: 'Финансовый леверидж', weight: '13', rating: finReport?.financialLeverage ?? '-' },
        { score: 'Текущая леквидность', weight: '20', rating: finReport?.currentLiquidity ?? '-' },
        { score: 'Рентабельность ebitda', weight: '15', rating: finReport?.profitabilityEbitda ?? '-' },
        { score: 'Долгавая нагрузка', weight: '17', rating: finReport?.debtBurden ?? '-' },
        { score: 'Обслуживание процентов', weight: '7', rating: finReport?.interestService ?? '-' },
        { score: 'Годовая выручка к НЦК', weight: '12', rating: finReport?.annualRevenueToNcc ?? '-' },
      ],
      [
        { score: 'Опыт работы', weight: '40', rating: rating.workExp, },
        { score: 'Количество исполненных за последние 3 года контрактов стоимостью более, либо равной контракту, по которому требуется гарантия', weight: '25', rating: rating?.scoreBigContracts ?? '-' },
        { score: 'Количество исполненных контрактов за последние 3 года', weight: '25', rating: rating?.scoreAllContracts ?? '-' },
        { score: 'Срок работы Принципала в основном направлении деятельности (лет)', weight: '50', rating: rating?.scoreYear ?? '-' },
      ],
      [
        { score: 'Структура владения', weight: '10', rating: rating?.ownershipStructure ?? '-' },
        { score: 'Изменение состава собственников Принципала с пакетом акций свыше 10% (последняя смена проводилась n месяцев назад)', weight: '100', rating: '-' },
      ],
      [
        { score: 'Дополнительные (корректирующие) факторы', weight: '', rating: rating?.additionalFactors },
        { score: 'Наличие задолженности перед федеральным бюджетом, бюджетами субъектов Российской Федерации, местными бюджетами и внебюджетными фондами, с учетом причин их возникновения', weight: '', rating: rating?.additionalFactor1 ?? '-' },
        { score: 'Наличие текущего убытка, при условии, что ЧА положительные, снижение ЧА менее 25% по сравнению с их значением за последний завершенный финансовый год, а также имеется накопленная прибыль', weight: '', rating: rating?.additionalFactor2 ?? '-' },
        { score: 'Текущий убыток, приведший к снижению (25% и более) ЧА по сравнению с их значением за последний завершенный финансовый год (при условии, что ЧА положительные)', weight: '', rating: rating?.additionalFactor3 ?? '-' },
        { score: 'Сущесвенное снижение ЧА (25% и более), не прогнозируемое Принципалом, по сравнению с его значением за последний завершенный финансовый год. При этом имеется накопленная и текущая прибыль. В случае распределения прибыли среди участников (акционеров) Принципала данный параметр не применяется.', weight: '', rating: rating?.additionalFactor4 ?? '-' },
        { score: 'Участие Принципала в судебном(ых) процессе(ах), общая сумма исков может сущесвенно повлиять на финансовое положение (более 25% ЧА)', weight: '', rating: rating?.additionalFactor5 ?? '-' },
      ],
    ]
  }

  useEffect(() => {
    setIsFetching(true)
    Promise.all([
      getOrderRawAccountingForms(),
      getOrderById(orderId),
      getComment({ order: orderId, page: ORDER_COMMENTS_PAGES.RATING, })
    ])
      .then(([finData, orderData, commentData]) => {
        setOrder(orderData)

        if (commentData.length) setComment(commentData[0].content)

        const currentYearForms = finData.find(item => item.year === '2020').detail_form;
        const rawData = {
          securityOfCurrentAssets: getFinReportByCode(currentYearForms, 'security_of_current_assets'),
          financialLeverage: getFinReportByCode(currentYearForms, 'financial_leverage'),
          currentLiquidity: getFinReportByCode(currentYearForms, 'current_liquidity'),
          profitabilityEbitda: getFinReportByCode(currentYearForms, 'profitability_ebitda'),
          debtBurden: getFinReportByCode(currentYearForms, 'debt_burden'),
          interestService: getFinReportByCode(currentYearForms, 'interest_service'),
          annualRevenueToNcc: getFinReportByCode(currentYearForms, 'annual_revenue_to_ncc'),
        };
        setFinReport(rawData);

        return getRating({ principal: orderData.principal?.id })
      })
      .then(limitData => {
        setRating(limitData)
        setCheckedRating(limitData?.delta)
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  return (
    <>
      <OrderViews.Header
        orderId={order.id}
        sum={order.sum}
        principal={order.principal}
        fz={order.contest?.fz?.fz}
        status={order.state}
      />
      {isFetching ?
        <Preloader />
        :
        <>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            enableReinitialize
          >
            {({ handleSubmit, handleChange, values, resetForm }) => <OrderViews.Rating
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              resetForm={resetForm}
              values={values}
              checkedRating={checkedRating}
              isInputReadOnly={isInputReadOnly}
              handleChangeCheckRating={handleChangeCheckRating}
              {...rating}
            />}
          </Formik>
          <CollapseTable tableData={ratingData} />
        </>
      }
    </>
  )
}

export default OrderRating
