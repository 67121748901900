import { numberWithSpaces } from '../../../utils'

const limitsListSerializer = (res) => {
  const data = []
  res.forEach(el => {
    data.push({
      data: {
        key: el.fz,
        value: numberWithSpaces(el.limit, true)
      },
      id: el.id,
    })
  })

  return data
}

export default limitsListSerializer