import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { handleRequestError } from '../../utils';
import Title from '../../views/Title';
import Preloader from '../../views/Preloader';
import BankViews from '../../views/BankViews';
import style from './index.module.scss';

const BankList = () => {
  const { getBankList } = api.bankApi;
  const [bankList, setBankList] = useState([]);
  const [isFetching, setIsFetching] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setIsFetching(true)
    getBankList()
      .then(data => setBankList(data))
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  return (
    <>
      <Title
        className={style.title}
        level='1'
        projectVariant='title_section'
      >
        Банки
      </Title>
      {isFetching ?
        <Preloader />
        :
        <BankViews.BankList data={bankList} />
      }
    </>
  )
}

export default BankList;