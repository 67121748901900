import React from 'react';
import { getLogAction, initialsName } from '../../../utils';
import style from './styles/index.module.scss';

const History = ({
  logs,
  className,
  ...props
}) => {
  return (
    <section
      className={className}
      {...props}
    >
      <ul className={style.list}>
        {logs?.map(log => {
          const userName = initialsName(log?.user?.first_name, log?.user?.last_name, log?.user?.patronymic);
          const date = `${new Date(log?.changed).toLocaleDateString()}
            ${new Date(log?.changed).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;

          return (
            <li className={style.listItem} key={log.id}>
              <div className={style.block}>
                <span className={style.key}>Номер заявки</span>
                <span className={style.value}>{log?.object_id || '-'}</span>
              </div>
              <span className={style.actionName}>{getLogAction(log?.action_on_model, log?.field)}</span>
              <div className={style.block}>
                <span className={style.key}>Кто совершил действие</span>
                <span className={style.value}>{userName.length > 2 ? userName : log?.user?.email}</span>
              </div>
              <div className={style.block}>
                <span className={style.key}>Дата и время</span>
                <span className={style.value}>{date}</span>
              </div>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default History;