import React, { useEffect } from 'react';
import classNames from 'classnames';
import style from './styles/index.module.scss';

const IndicatingCard = ({
  dataInputKey,
  dataInputValue,
  projectVariant = 'slim',
  color,
  className,
  ...props
}) => {
  const getIndicator = colorInput => {
    switch (colorInput) {
      case 'green':
        return style.green;
      case 'yellow':
        return style.yellow;
      case 'red':
        return style.red;
      default:
        return style.green;
    }
  }

  useEffect(() => {
    getIndicator(color);
  }, [color])

  return (
    <div
      className={classNames(
        style.container,
        style[projectVariant],
        { [getIndicator(color)]: !!getIndicator(color) }
      )}
      {...props}
    >
      <div className={classNames({
        [style.main]: true,
        [className]: !!className
      })}>
        <span className={style.key}>{dataInputKey}</span>
        <span className={style.value}>{dataInputValue}</span>
      </div>
    </div>
  )
}

export default IndicatingCard;