import React from 'react';
import BankList from '../components/BankList';
import Layout from '../views';

const BankListPage = () => {
  return (
    <Layout>
      <BankList />
    </Layout>
  )
}

export default BankListPage;