import React from 'react';
import Layout from '../views';
import Blacklist from '../components/Blacklist';

const BlacklistPage = (props) => {
  return (
    <Layout {...props}>
      <Blacklist />
    </Layout>
  )
}

export default BlacklistPage;