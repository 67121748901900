import React from 'react';
import Layout from '../views';
import OrderQuotation from '../components/OrderQuotation';

const OrderQuotationPage = (props) => {
  return (
    <Layout {...props}>
      <OrderQuotation />
    </Layout>
  )
}

export default OrderQuotationPage;