import React from 'react';
import Input from '../../Input';
import Textarea from '../../Textarea';
import Button from '../../Button';
import Group from './Group';
import style from './styles/index.module.scss';

const Relationship = ({
  className,
  ...props
}) => {
  return (
    <section
      className={className}
      {...props}
    >
      <div className={style.form}>
        <span className={style.form__prefix}>Связать с</span>
        <Input
          className={style.form__input}
          placeholder='Введине ИНН'
        />
        <Textarea
          className={style.form__textarea}
          placeholder='Комментарий'
          rows='1'
        />
        <Button
          className={style.form__submit}
          full
        >
          Связать
        </Button>
      </div>
      <ul className={style.list}>
        <li>
          <Group />
        </li>
        <li>
          <Group />
        </li>
      </ul>
    </section>
  )
}

export default Relationship;