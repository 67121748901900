import React, { useState } from 'react'
import { GxTooltip } from '@garpix/garpix-web-components-react'
import { NavLink, useParams } from 'react-router-dom'
import ButtonIcon from '../../ButtonIcon'
import Title from '../../Title'
import { list } from '../../../images'
import { PATHS } from '../../../const'
import style from './styles/index.module.scss'
import { numberWithSpaces } from '../../../utils'

const Summery = ({ principal, beneficiary, sum, term, contest }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const toggleTooltipOpen = () => setIsTooltipOpen(!isTooltipOpen);
  const { orderId } = useParams();
  const handleSaveOrder = () => {
    localStorage.setItem('orderId', orderId);
  };

  return (
    <section className={style.orderSummary}>
      <div className={style.orderSummary__objects}>
        <div>
          <p className={style.orderSummary__objectName}>Принципиал</p>
          <p className={style.orderSummary__objectValue}>
            {
              principal?.title
                ? <NavLink
                  to={PATHS.PRINCIPAL_INFO.link + principal.id}
                  onClick={handleSaveOrder}
                >
                  {principal?.title}
                </NavLink>
                : '-'
            }
          </p>
        </div>
        <div>
          <p className={style.orderSummary__objectName}>Бенефициар</p>
          <p className={style.orderSummary__objectValue}>{beneficiary?.title || '-'}</p>
        </div>
      </div>
      <div className={style.summaryBlock}>
        <Title level='3' projectVariant='title_section-subtitle'>Гарантия</Title>
        <div>
          <div className={style.summaryBlock__item}>
            <p className={style.summaryBlock__title}>Сумма</p>
            <p className={style.summaryBlock__value}>{numberWithSpaces(sum, true) || '-'}</p>
          </div>
          <div className={style.summaryBlock__item}>
            <p className={style.summaryBlock__title}>ФЗ</p>
            <p className={style.summaryBlock__value}>{contest?.fz?.fz || '-'}</p>
          </div>
          <div className={style.summaryBlock__item}>
            <p className={style.summaryBlock__title}>Срок</p>
            <p className={style.summaryBlock__value}>{term ? `${term} дн.` : '-'}</p>
          </div>
        </div>
      </div>
      <div className={style.summaryBlock}>
        <Title level='3' projectVariant='title_section-subtitle'>Контракт</Title>
        <div>
          <div className={style.summaryBlock__item}>
            <p className={style.summaryBlock__title}>Сумма</p>
            <p className={style.summaryBlock__value}>{numberWithSpaces(contest?.contract?.sum, true) || '-'}</p>
          </div>
          <div className={style.summaryBlock__item}>
            <p className={style.summaryBlock__title}>Предмет закупки</p>
            <div className={style.summaryBlock__value}>
              <p>{contest?.contract?.purchase?.purchase_object || '-'}</p>
              <GxTooltip
                className={style.tooltipWrapper}
                trigger='manual'
                content={contest?.contract?.purchase?.purchase_object}
                open={isTooltipOpen}
                placement='top-start'
              >
                <ButtonIcon
                  onClick={toggleTooltipOpen}
                  ariaLabel='Комментарий к объекту закупки'
                  iconSrc={list}
                  onBlur={() => setIsTooltipOpen(false)}
                />
              </GxTooltip>
            </div>
          </div>
          <div className={style.summaryBlock__item}>
            <p className={style.summaryBlock__title}>Срок</p>
            <p className={style.summaryBlock__value}>{contest?.contract?.term ? new Date(contest.contract.term).toLocaleDateString() : '-'}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Summery