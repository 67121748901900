import React from 'react';
import Title from '../../Title';
import Company from './Company';
import style from './styles/Group.module.scss';

const Group = ({
  className,
  ...props
}) => {
  return (
    <div
      className={className}
      {...props}
    >
      <Title
        className={style.title}
        level='2'
        projectVariant='title_section'
      >
        Группа 1
      </Title>
      <ul className={style.list}>
        <li>
          <Company />
        </li>
        <li>
          <Company />
        </li>
      </ul>
    </div>
  )
}

export default Group;