import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import CollapseTable from '../../views/CollapseTable'
import OrderViews from '../../views/OrderViews'
import Title from '../../views/Title'
import api from '../../api'
import { handleRequestError } from '../../utils'
import style from './index.module.scss'
import Preloader from '../../views/Preloader'

const OrderFinreporting = () => {
  const [order, setOrder] = useState({})
  const [report, setReport] = useState({})
  const [finReport, setFinReport] = useState({})
  const [finOW, setFinOW] = useState({})
  const [isFetching, setIsFetching] = useState(false)
  const { getOrderById, getOrderAccountingForms, getOrderOW } = api.ordersApi
  const { orderId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    setIsFetching(true)
    getOrderById(orderId)
      .then(orderData => {
        setOrder(orderData)
        return getOrderAccountingForms({ principal: orderData.principal.id })
      })
      .then(finReportData => setFinReport(finReportData))
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }, [])

  useEffect(() => {
    if (Object.keys(order).length !== 0) {
      const params = {
        supplier: order.principal.id,
        customer: order.beneficiary.id,
      };
      getOrderOW(params)
        .then(data => setFinOW(data[0]))
        .catch(err => handleRequestError(err, navigate))
    }
  }, [order])

  useEffect(() => {
    if (Object.keys(finReport).length > 0) {
      const rows = finReport.odds[0].rows.map(row => {
        if (row[0].type) {
          return [{
            ...row[0],
            '2020': finOW ? finOW[row[0].type] : '',
          }]
        }
        return row
      });
      setReport({
        ...finReport,
        odds: [{ ...finReport.odds[0], rows }],
      })
    }
  }, [finOW, finReport])

  return (
    <>
      <OrderViews.Header
        orderId={order.id}
        sum={order.sum}
        principal={order.principal}
        fz={order.contest?.fz?.fz}
        status={order.state}
      />
      {isFetching ?
        <Preloader />
        :
        report?.balance
        &&
        <section className={style.finReporting}>
          <Title projectVariant='title_section-finreporting' level='2'>
            Баланс
          </Title>
          {
            report?.balance?.map((elem, idx) => {
              const key = `collapse-balance-${idx}`
              return <CollapseTable key={key} tableData={elem} />
            })
          }

          <Title projectVariant='title_section-finreporting' level='2'>
            ОПУ
          </Title>
          {
            report?.opu?.map((elem, idx) => {
              const key = `collapse-opu-${idx}`
              return <CollapseTable key={key} tableData={elem} />
            })
          }

          <Title projectVariant='title_section-finreporting' level='2'>
            Коэффициенты
          </Title>
          {
            report?.odds?.map((elem, idx) => {
              const key = `collapse-odds-${idx}`
              return <CollapseTable key={key} tableData={elem} />
            })
          }
        </section>
      }
    </>
  )
}

export default OrderFinreporting
