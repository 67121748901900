import React from 'react'
import LimitsPrincipal from '../components/LimitsPrincipal'
import Layout from '../views'

const LimitsPrincipalPage = (props) => {
  return (
    <Layout {...props}>
      <LimitsPrincipal />
    </Layout>
  )
}

export default LimitsPrincipalPage