// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrderScoring_table__2LpWA {\n  overflow-x: hidden;\n}\n.OrderScoring_table__2LpWA div:not(:first-child) > div div div :last-child {\n  text-align: right;\n}\n.OrderScoring_table__2LpWA > div:not(:first-child) {\n  width: 100%;\n  min-width: 0;\n}\n.OrderScoring_table__2LpWA > div:not(:first-child) > div:first-child > a > div {\n  min-width: 0;\n}\n.OrderScoring_table__2LpWA > div:not(:first-child) > div > div > div {\n  min-width: 0;\n  max-width: 99%;\n}", "",{"version":3,"sources":["webpack://src/components/OrderScoring/index.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAOU;EACE,iBAAA;AALZ;AAYE;EACE,WAAA;EACA,YAAA;AAVJ;AAYI;EACE,YAAA;AAVN;AAaI;EACE,YAAA;EACA,cAAA;AAXN","sourcesContent":["@import \"../../styles/global/index.scss\";\n\n.table {\n  overflow-x: hidden;\n\n  & div:not(:first-child) {\n    & > div {\n      & div {\n        & div {\n          :last-child {\n            text-align: right;\n          }\n        }\n      }\n    }\n  }\n\n  & > div:not(:first-child) {\n    width: 100%;\n    min-width: 0;\n\n    & > div:first-child > a > div {\n      min-width: 0;\n    }\n\n    & > div > div > div {\n      min-width: 0;\n      max-width: 99%;\n    }\n\n    // & div > div {\n    //   width: 100%;\n    // }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "OrderScoring_table__2LpWA"
};
export default ___CSS_LOADER_EXPORT___;
