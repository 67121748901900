import { numberWithSpaces } from '../../../utils'

const banksListSerializer = (res) => {
  const data = []
  res.forEach(item => {
    data.push({
      data: {
        key: item.bank.title,
        value: numberWithSpaces(item.total_limit, true)
      },
      id: item.id,
    })
  })

  return data
}

export default banksListSerializer