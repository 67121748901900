import React from 'react'
import Layout from '../views'

const LimitsPage = (props) => {
  return (
    <Layout {...props}>
      Limits Page
    </Layout>
  )
}

export default LimitsPage