import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { GxIcon } from '@garpix/garpix-web-components-react';
import Button from '../Button';
import Table from './Table';
import { arrowDown } from '../../images';
import style from './index.module.scss';

const CollapseTable = ({ tableData = [], customClassName }) => {
  const [colsKeys, setColsKeys] = useState(null);
  const [insight, setInsight] = useState(false);
  let timer = false;
  const wrapperRef = useRef();
  const main = document.querySelector('main');
  const coordX = window.innerWidth - window.innerWidth / 3;
  const coordY = window.innerHeight / 2;
  const checkConditions = () => {
    if (document.elementsFromPoint(coordX, coordY).includes(wrapperRef.current)) {
      if (
        wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth
        &&
        wrapperRef.current.scrollHeight > window.innerHeight / 2
      ) {
        setInsight(true);
      } else {
        setInsight(false);
      }
    } else {
      setInsight(false);
    }
  }
  const checkConditionsWithDelay = () => {
    if (!timer) {
      timer = true;
      setTimeout(() => { checkConditions(); timer = false; }, 200)
    }
  }
  const scrollLeft = () => {
    wrapperRef.current.scrollLeft -= 100;
  }
  const scrollRight = () => {
    wrapperRef.current.scrollLeft += 100;
  }

  window.addEventListener('resize', checkConditionsWithDelay);

  useEffect(() => {
    checkConditions();
  }, [insight])

  useEffect(() => {
    if (main) {
      main.addEventListener('scroll', checkConditionsWithDelay);
    }
  }, [main])


  useEffect(() => {
    if (tableData.cols && !colsKeys) {
      const keysArr = []
      tableData.cols.forEach(el => keysArr.push(el.key))
      setColsKeys(keysArr)
    }
  }, [tableData])

  return (
    <div
      className={classNames(
        style.collapse,
        { [customClassName]: !!customClassName }
      )}
      ref={wrapperRef}
    >
      {tableData ? (
        <div className={style['collapse-head']}>
          <div className={style['collapse-row']}>
            {tableData?.cols.map(col => {
              return (
                <div key={`${tableData.key}-${col.key}`} className={style['collapse-col']}>{col.label}</div>
              )
            })}
          </div>
        </div>
      ) : null}
      {colsKeys && tableData.rows.map((row, idx) => {
        const key = `fragment-${idx}`;
        if (row instanceof Array) {
          return <Table
            key={key}
            row={row}
            tableData={tableData}
            colsKeys={colsKeys}
          />
        }
        return (
          <Table
            key={key}
            row={row.body}
            tableHeader={row.header}
            tableData={tableData}
            colsKeys={colsKeys}
          />
        )
      })}
      <div className={classNames(
        style.controls,
        { [style.insight]: insight }
      )}>
        <Button
          className={style.btnLeft}
          onClick={scrollLeft}
        >
          <GxIcon src={arrowDown} slot='icon-left' />
        </Button>
        <Button
          className={style.btnRight}
          onClick={scrollRight}
        >
          <GxIcon src={arrowDown} slot='icon-left' />
        </Button>
      </div>
    </div>
  )
}


export default CollapseTable
