import AbstractBaseApi from './AbstractBaseApi'
import LimitsApi from './LimitsApi'
import UsersApi from './UsersApi'
import OrdersApi from './OrdersApi'
import ChangelogApi from './ChangelogApi'
import StatusChangeApi from './StatusChangeApi'
import BankApi from './BankApi'
import EntityApi from './EntityApi'
import HandbooksApi from './HandbooksApi'
import UnderwritersApi from './UnderwritersApi'

// TODO: Удалить ErrorApi
import ErrorApi from './ErrorApi'

class Api extends AbstractBaseApi {
  constructor(MAIN_URL) {
    super(MAIN_URL)
    this.usersApi = new UsersApi(MAIN_URL)
    this.ordersApi = new OrdersApi(MAIN_URL)
    this.limitsApi = new LimitsApi(MAIN_URL)
    this.changelogApi = new ChangelogApi(MAIN_URL)
    this.statusChangeApi = new StatusChangeApi(MAIN_URL)
    this.bankApi = new BankApi(MAIN_URL)
    this.entityApi = new EntityApi(MAIN_URL)
    this.handbooksApi = new HandbooksApi(MAIN_URL)
    this.underwritersApi = new UnderwritersApi(MAIN_URL)

    this.errorApi = new ErrorApi(MAIN_URL)
  }
}
const API_URL = process.env.REACT_APP_API_URL
const api = new Api(API_URL)

export default api