import React from 'react';
import { Route, Routes } from 'react-router-dom';
import uuid4 from 'uuid/dist/v4';
import { PATHS } from '../../const';
import { documentIcon, matrix, creditScore } from '../../images';
import {
  BankDocumentsPage,
  BankListPage,
  BankLimitsPage,
  BankMatrixPage
} from '../../pages';

const asideLinks = [
  {
    id: uuid4(),
    path: PATHS.BANK_DOCUMENTS.link,
    icon: documentIcon,
    name: 'Образцы документов',
  },
  {
    id: uuid4(),
    path: PATHS.BANK_MATRIX.link,
    icon: matrix,
    name: 'Матрица тарифов',
  },
  {
    id: uuid4(),
    path: PATHS.BANK_LIMITS.link,
    icon: creditScore,
    name: 'Лимиты банка',
  }
];

const BankList = () => <BankListPage />
const BankDocuments = () => <BankDocumentsPage paramsName='bankId' asideLinks={asideLinks} />
const BankLimits = () => <BankLimitsPage paramsName='bankId' asideLinks={asideLinks} />
const BankMatrix = () => <BankMatrixPage paramsName='bankId' asideLinks={asideLinks} />

const BanksRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.BANK_DOCUMENTS.pathWithParam} element={<BankDocuments />} />
      <Route path={PATHS.BANK_LIMITS.pathWithParam} element={<BankLimits />} />
      <Route path={PATHS.BANK_MATRIX.pathWithParam} element={<BankMatrix />} />
      <Route index element={<BankList />} />
    </Routes>
  )
}

export default BanksRoutes;
