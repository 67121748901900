import React from 'react'
import OrderFinreporting from '../components/OrderFinreporting'
import Layout from '../views'

const OrderFinreportingPage = (props) => {
  return (
    <Layout {...props}>
      <OrderFinreporting />
    </Layout>
  )
}

export default OrderFinreportingPage
