import React, { useState } from 'react';
import classNames from 'classnames';
import HeaderNav from './HeaderNav';
import Masthead from './Masthead';
import Logo from '../Logo';
import Input from '../Input';
import styles from './styles/index.module.scss';

const Header = ({
  isBellActive,
  isChatActive,
  logoLabel,
  searchPlaceholder,
  searchValue,
  onSearchValueChange,
  onBellClick,
  links,
  className,
  ...props
}) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false)

  const onToggleVisible = () => {
    setIsSearchVisible(!isSearchVisible)
  }

  return (
    <header
      className={classNames({
        [styles.header]: true,
        [styles.header__search]: isSearchVisible,
        [className]: !!className
      })}
      {...props}
    >
      <div className={styles.header__logo}>
        <Logo label={logoLabel} />
      </div>
      <HeaderNav links={links} />
      <Input
        className={classNames({
          [styles.header__input]: true,
          [styles.header__input_show]: isSearchVisible
        })}
        placeholder={searchPlaceholder}
        projectVariant='input_dark'
        type='search'
        value={searchValue}
        onChange={onSearchValueChange}
      />
      <Masthead
        isBellActive={isBellActive}
        isChatActive={isChatActive}
        onBellClick={onBellClick}
        onToggleVisible={onToggleVisible}
      />
    </header>
  )
}

export default Header
