import React from 'react'
import LimitsFz from '../components/LimitsFz'
import Layout from '../views'

const LimitsFzPage = (props) => {
  return (
    <Layout {...props}>
      <LimitsFz />
    </Layout>
  )
}

export default LimitsFzPage