/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useStoreon } from 'storeon/react'
import api from '../../api'
import Title from '../../views/Title'
import ParamCard from '../../views/ParamCard'
import { handleRequestError, isRoleJuniorUW } from '../../utils';
import Preloader from '../../views/Preloader'
import style from './index.module.scss';

const LimitsFz = () => {
  const [dataFz, setDataFz] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const { getLimitFzList, updateLimitsFz } = api.limitsApi
  const navigate = useNavigate()
  const { currentUser } = useStoreon('currentUser')

  const reloadList = () => {
    setIsFetching(true)
    return getLimitFzList()
      .then(res => {
        if (isRoleJuniorUW(currentUser)) return setDataFz(res)
        const modifiedRes = res.map(item => {
          return {
            ...item,
            inputAttr: {
              name: 'limit',
              handleAccept: (name, value) => handleAccept(item.id, name, value)
            }
          }
        })
        return setDataFz(modifiedRes)
      })
      .catch(err => handleRequestError(err, navigate))
      .finally(() => setIsFetching(false))
  }

  const handleAccept = (id, name, value) => {
    const data = { [name]: value }

    const handleUpdateLimitsFz = () => updateLimitsFz(id, data)
    return toast.promise(handleUpdateLimitsFz, {
      pending: 'Обновление...',
      success: 'Лимит успешно обновлен',
    })
      .then(reloadList)
      .catch(err => handleRequestError(err, navigate))
  }

  useEffect(() => {
    reloadList()
  }, [])

  return (
    <section>
      <Title className={style.title} projectVariant='title_section' level='2'>
        Лимиты
      </Title>
      {isFetching ?
        <Preloader />
        :
        <ParamCard className={style.card} dataInput={dataFz} title='Вид' />
      }
    </section>
  )
}

export default LimitsFz