import React from 'react';
import Title from '../../Title';
import ButtonIcon from '../../ButtonIcon';
import { download } from '../../../images';
import style from './styles/index.module.scss';

const DocumentList = ({
  title,
  documents,
  className,
  ...props
}) => {
  return (
    <section
      className={className}
      {...props}
    >
      {title && <Title className={style.title} level='2' projectVariant='title_section'>{title}</Title>}
      <ul className={style.list}>
        {documents.map(document => {
          return (
            <li className={style.list__item} key={document.id}>
              <span className={style.list__name}>{document.title}</span>
              <span className={style.list__date}>{new Date(document.update_at).toLocaleDateString()}</span>
              <div className={style.list__controls}>
                <ButtonIcon
                  name={document.title}
                  href={document.document}
                  download={document.title}
                  iconSrc={download}
                />
              </div>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default DocumentList;