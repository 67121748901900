import React from 'react';
import { NavLink } from 'react-router-dom';
import style from './styles/index.module.scss';
import { PATHS } from '../../../const';

const BankList = ({
  data = [],
  className,
  ...props
}) => {
  return (
    <section className={className}>
      <ul
        className={style.list}
        {...props}
      >
        {data.map(bank => (
          <li key={bank.id}>
            <NavLink
              className={style.list__link}
              to={PATHS.BANK_DOCUMENTS.link + bank.id}
            >
              <div className={style.list__logoWrapper}>
                <img
                  className={style.list__logo}
                  src={bank.logo}
                  alt={bank.title}
                />
              </div>
              <span className={style.list__name}>{bank.title}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default BankList;