import React from 'react';
import classNames from 'classnames';
import Title from '../../Title';
import Input from '../../Input';
import Button from '../../Button';
import style from './styles/index.module.scss';

const ProfileCommon = ({
  className,
  ...props
}) => {
  return (
    <section
      className={classNames(
        style.section,
        { [className]: !!className }
      )}>
      <fieldset
        className={classNames(
          [style.fieldset],
          [style.fieldset_personal]
        )}
        {...props}
      >
        <Title
          className={style.fieldset__title}
          projectVariant='title_section'
          level='2'
        >
          Персональные данные
        </Title>
        <div className={style.fieldset__wrapper}>
          <Input
            className={style.fieldset__input}
            label='Фамилия'
            type='text'
            placeholder='Не указано'
          >
            input
          </Input>
          <Input
            className={style.fieldset__input}
            label='Имя'
            type='text'
            placeholder='Не указано'
          >
            input
          </Input>
          <Input
            className={style.fieldset__input}
            label='Отчество'
            type='text'
            placeholder='Не указано'
          >
            input
          </Input>
          <Input
            className={style.fieldset__input}
            label='Логин (адрес электронной почты)'
            type='text'
            placeholder='Не указано'
          >
            input
          </Input>
          <Input
            className={style.fieldset__input}
            label='Электронная почта'
            type='email'
            placeholder='Не указано'
          >
            input
          </Input>
          <Input
            className={style.fieldset__input}
            label='Телефон'
            type='tel'
            placeholder='Не указано'
          >
            input
          </Input>
        </div>
      </fieldset>
      <fieldset
        className={classNames(
          [style.fieldset],
          [style.fieldset_changePassword]
        )}
        {...props}>
        <Title
          className={style.fieldset__title}
          projectVariant='title_section'
          level='2'
        >
          Смена пароля
        </Title>
        <div className={style.fieldset__wrapper}>
          <Input
            className={style.fieldset__input}
            label='Старый пароль'
            type='text'
            placeholder='Не указано'
          >
            input
          </Input>
          <Input
            className={style.fieldset__input}
            label='Новый пароль'
            type='text'
            placeholder='Не указано'
          >
            input
          </Input>
          <Input
            className={style.fieldset__input}
            label='Подтверждение пароля'
            type='text'
            placeholder='Не указано'
          >
            input
          </Input>
          <Button
            className={style.fieldset__btn}
            type='button'
            full
          >
            Изменить пароль
          </Button>
        </div>
      </fieldset>
      <fieldset
        className={classNames(
          [style.fieldset],
          [style.fieldset_passport]
        )}
        {...props}>
        <Title
          className={style.fieldset__title}
          projectVariant='title_section'
          level='2'
        >
          Паспортные данные
        </Title>
        <div className={style.fieldset__wrapper}>
          <Input
            className={style.fieldset__input}
            label='Серия и номер паспорта'
            type='text'
            placeholder='Не указано'
          >
            input
          </Input>
          <Input
            className={style.fieldset__input}
            label='Адрес регистрации'
            type='text'
            placeholder='Не указано'
          >
            input
          </Input>
        </div>
      </fieldset>
      <fieldset
        className={classNames(
          [style.fieldset],
          [style.fieldset_requisites]
        )}
        {...props}>
        <Title
          className={style.fieldset__title}
          projectVariant='title_section'
          level='2'
        >
          Реквизиты
        </Title>
        <div className={style.fieldset__wrapper}>
          <Input
            className={style.fieldset__input}
            label='ИНН'
            type='text'
            placeholder='Не указано'
          >
            input
          </Input>
        </div>
      </fieldset>
    </section>
  )
}

export default ProfileCommon;