import React from 'react';
import Layout from '../views';
import FAQ from '../components/FAQ';

const FAQPage = () => {
  return (
    <Layout>
      <FAQ />
    </Layout>
  )
}

export default FAQPage;