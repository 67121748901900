import { numberWithSpaces } from '../../../utils'

const convertPurchaseData = (data, totalSumInputAttr) => {
  return {
    firstColumn: [
      {
        title: '',
        columnList: [
          {
            id: 1,
            data: {
              key: 'Сумма гарантии',
              value: numberWithSpaces(data?.sum, true) || '-'
            }
          },
          {
            id: 2,
            data: {
              key: 'Наличие аванса',
              value: data?.contest?.contract?.availability_payment ? 'Да' : 'Нет'
            }
          },
          {
            id: 3,
            data: {
              key: 'Дата начала действия гарантии',
              value: data?.start_date ? new Date(data?.start_date).toLocaleDateString() : '-'
            }
          },
          {
            id: 4,
            data: {
              key: 'Дата конца действия гарантии',
              value: data?.start_date ? new Date(data?.end_date).toLocaleDateString() : '-'
            }
          },
          {
            id: 5,
            data: {
              key: 'Срок гарантии',
              value: data?.term ? `${data?.term} дн.` : '-'
            }
          },
        ]
      },
      {
        title: '',
        columnList: [
          {
            id: 1,
            data: {
              key: 'Принципал',
              value: data?.principal?.title
            }
          },
          {
            id: 2,
            data: {
              key: 'ИНН',
              value: data?.principal?.legal_entity.inn
            }
          },
          {
            id: 3,
            data: {
              key: 'Регион',
              value: data?.principal?.legal_entity?.region || '-'
            }
          },
          {
            id: 4,
            data: {
              key: 'ОКВЭД (основные)',
              value: data?.principal?.legal_entity?.okved || '-'
            }
          },
        ]
      },
      {
        title: '',
        columnList: [
          {
            id: 1,
            data: {
              key: 'ОКПД 2',
              value: data?.contest?.okpd2 || '-'
            }
          },
          {
            id: 2,
            data: {
              key: 'НМЦК',
              value: data?.contest?.nmck || '-'
            }
          },
          {
            id: 3,
            data: {
              key: 'Окончательная цена',
              value: numberWithSpaces(data?.contest?.contract?.sum, true) || '-'
            },
            inputAttr: totalSumInputAttr
          },
          {
            id: 4,
            data: {
              key: 'Размер обеспечения',
              value: numberWithSpaces(data?.contest?.contract?.security_amount, true) || '-'
            }
          },
          {
            id: 5,
            data: {
              key: 'Срок контракта',
              value: data?.contest?.contract?.term ? new Date(data?.contest?.contract?.term).toLocaleDateString() : '-'
            }
          },
        ]
      },
    ],
    secondColumn: [
      {
        title: '',
        columnList: [
          {
            id: 1,
            data: {
              key: 'Демпинг',
              value: data?.dumping ? 'Да' : 'Нет'
            }
          },
          {
            id: 2,
            data: {
              key: 'Наличие права безакцептного списания',
              value: data?.availability_without_acceptance ? 'Да' : 'Нет'
            }
          },
          {
            id: 3,
            data: {
              key: 'Тип гарантии',
              value: data?.guarantee_type || '-'
            }
          },
        ]
      },
      {
        title: '',
        columnList: [
          {
            id: 1,
            data: {
              key: 'Бенефициар',
              value: data?.beneficiary?.title || '-'
            }
          },
          {
            id: 2,
            data: {
              key: 'ИНН',
              value: data?.beneficiary?.legal_entity?.inn || '-'
            }
          },
          {
            id: 3,
            data: {
              key: 'Регион',
              value: data?.beneficiary?.legal_entity?.region || '-'
            }
          },
          {
            id: 3,
            data: {
              key: 'ОКВЭД (основные)',
              value: data?.beneficiary?.legal_entity?.okved || '-'
            }
          },
        ]
      },
      {
        title: '',
        columnList: [
          {
            id: 1,
            data: {
              key: 'Аукцион еще не определен',
              value: data?.contest ? 'Нет' : 'Да'
            }
          },
          {
            id: 2,
            data: {
              key: 'Дата получения БГ',
              value: data?.take_date ? new Date(data?.take_date).toLocaleDateString() : '-'
            }
          },
          {
            id: 3,
            data: {
              key: 'Тип конкурса',
              value: data?.contest?.type?.title || '-'
            }
          },
          {
            id: 3,
            data: {
              key: 'Дата протокола',
              value: data?.protocol_date ? new Date(data?.protocol_date).toLocaleDateString() : '-'
            }
          },
        ]
      },
    ]
  }
}

export default {
  convertPurchaseData,
}