import React from 'react'
import uuid4 from 'uuid/dist/v4'
import { numberWithSpaces } from '../../../utils'
import Title from '../../../views/Title'

const principalLimitTitles = [
  'Установленный лимит',
  'Действующие БГ',
  'Заявки',
  'Свободный остаток',
]

const principalLimitTitlesMap = {
  'Установленный лимит': 'total_limit',
  'Действующие БГ': 'current_guaranties_sum',
  'Заявки': 'orders_sum',
  'Свободный остаток': 'free_balance'
}

const convertPrincipalLimitToTable = (data) => {
  return principalLimitTitles.map(item => ({
    id: uuid4(),
    cells: [
      {
        id: uuid4(),
        data: item
      },
      {

      },
      {
        id: uuid4(),
        data: data ? numberWithSpaces(data[principalLimitTitlesMap[item]], true) : '-'
      },
    ]
  }))
}


const getLimitsListHead = (title) => ([
  {
    id: uuid4(),
    cells: [
      {
        id: uuid4(),
        data: <Title level='2' projectVariant='title_section'>{title}</Title>
      },
      {
        id: uuid4(),
        data: 'Установленный лимит'
      },
      {
        id: uuid4(),
        data: 'Свободный остаток'
      }
    ]
  }
])

const getLimitsPrincipalListHead = () => ([
  {
    id: uuid4(),
    cells: [
      {
        id: uuid4(),
        data: <Title level='2' projectVariant='title_section'>Лимиты</Title>
      },
      {

      },
      {
        id: uuid4(),
        data: 'Принципал'
      }
    ]
  }
])

export default { convertPrincipalLimitToTable, getLimitsListHead, getLimitsPrincipalListHead }