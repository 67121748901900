import React from 'react';
import classNames from 'classnames';
import style from './styles/index.module.scss';

const Indicators = ({
  rating,
  stops,
  blacklist,
  className,
  ...props
}) => {
  const getIndicatorColor = (value) => {
    switch (value) {
      case true:
        return style.principialIndicators__item_redBorder
      case false:
        return style.principialIndicators__item_greenBorder
      default:
        return style.principialIndicators__item_yellowBorder
    }
  }
  const getIndicatorClassName = (value) => classNames([
    style.principialIndicators__item,
    getIndicatorColor(value)
  ]);

  return (
    <section
      className={classNames({
        [style.principialIndicators]: true,
        [className]: !!className
      })}
      {...props}
    >
      <div className={getIndicatorClassName(stops)}>
        <p className={style.principialIndicators__title}>Стоп-факторы</p>
        <p className={style.principialIndicators__value}>{stops ? 'Да' : 'Нет'}</p>
      </div>
      <div className={getIndicatorClassName()}>
        <p className={style.principialIndicators__title}>Рейтинг</p>
        <p className={style.principialIndicators__value}>{`${rating?.letter_rating || '-'} ; ${rating?.ball_rating || '-'}`}</p>
      </div>
      <div className={getIndicatorClassName(blacklist)}>
        <p className={style.principialIndicators__title}>Чёрный список</p>
        <p className={style.principialIndicators__value}>{blacklist ? 'Да' : 'Нет'}</p>
      </div>
    </section>
  )
}

export default Indicators;
