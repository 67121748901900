import React from 'react';
import classNames from 'classnames';
import { loader } from '../../images'
import style from './index.module.scss'

const Preloader = ({
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        style.preloader,
        { [className]: !!className }
      )}
      {...props}
    >
      <img src={loader} alt='loader' />
    </div>
  )
};

export default Preloader;
